/**
 * @generated SignedSource<<0c32f195031dc123b18644485ed7c5b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFulfillSemiFungibleOrders_asset$data = {
  readonly ownedQuantity: string | null;
  readonly relayId: string;
  readonly totalQuantity: string;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOffersButton_asset">;
  readonly " $fragmentType": "useFulfillSemiFungibleOrders_asset";
};
export type useFulfillSemiFungibleOrders_asset$key = {
  readonly " $data"?: useFulfillSemiFungibleOrders_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFulfillSemiFungibleOrders_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useFulfillSemiFungibleOrders_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "identity",
          "value": {}
        }
      ],
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": "ownedQuantity(identity:{})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptOffersButton_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "95821cba8dbf593f1c8c544cf5c48100";

export default node;
