import React from "react"
import {
  Separator,
  SpaceBetween,
  Text,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { ServiceFeeText } from "@/components/trade/ServiceFeeText"
import { EMPTY_PRICE_DISPLAY } from "@/constants"
import { Block } from "@/design-system/Block"
import { FormControl } from "@/design-system/FormControl"
import { Item } from "@/design-system/Item"
import { Modal } from "@/design-system/Modal"
import {
  RenderStep,
  useMultiStepFlowContext,
} from "@/design-system/Modal/MultiStepFlow.react"
import { CreatorFeeShowSupport } from "@/features/creator-fees/components/CreatorFeeShowSupport"
import { CreatorFeeToggleGroup } from "@/features/creator-fees/components/CreatorFeeToggleGroup"
import { readOptionalCreatorFees } from "@/features/orders/components/AcceptOfferModalContent/readOptionalCreatorFees"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { ItemOfferDetails } from "@/features/orders/components/OfferModal/components/ItemOfferDetails"
import { TotalPricePerSymbol } from "@/features/shopping-cart/hooks/useTotalPrice"
import { useForm } from "@/hooks/useForm"
import { useItemFees } from "@/hooks/useItemFees"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useTranslate } from "@/hooks/useTranslate"
import { trackOptionalCreatorFeeSubmitted } from "@/lib/analytics/events/feeEvents"
import { CreatorFeeInputModalContent_asset$key } from "@/lib/graphql/__generated__/CreatorFeeInputModalContent_asset.graphql"
import { CreatorFeeInputModalContent_orders$key } from "@/lib/graphql/__generated__/CreatorFeeInputModalContent_orders.graphql"
import {
  basisPointsToPercentage,
  BigNumber,
  displayFiat,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"
import { EN_DASH } from "@/lib/helpers/stringUtils"

type FormData = {
  totalCreatorFeePercentage: string
}

export type CreatorFeeInputModalContentProps = {
  orders: CreatorFeeInputModalContent_orders$key
  asset: CreatorFeeInputModalContent_asset$key
  quantity: BigNumber
  totalPrice: NonNullable<TotalPricePerSymbol[string]>
  minTotalPrice: TotalPricePerSymbol[string]
  renderNext: (data: {
    optionalCreatorFeeBasisPoints: number | undefined
  }) => RenderStep
}

export const CreatorFeeInputModalContent = ({
  orders: ordersDataKey,
  asset: assetDataKey,
  quantity,
  totalPrice,
  minTotalPrice,
  renderNext,
}: CreatorFeeInputModalContentProps) => {
  const t = useTranslate("orders")
  const { onNext } = useMultiStepFlowContext()

  const orders = useFragment(
    graphql`
      fragment CreatorFeeInputModalContent_orders on OrderV2Type
      @relay(plural: true) {
        ...readOrderFees_order
        ...ServiceFeeText_orders
      }
    `,
    ordersDataKey,
  )

  const asset = useFragment(
    graphql`
      fragment CreatorFeeInputModalContent_asset on AssetType {
        ...ItemOfferDetails_item
        ...readOptionalCreatorFees_item
        ...useItemFees_item
      }
    `,
    assetDataKey,
  )

  const { getShowOptionalCreatorFee, maxBasisPoints } =
    readOptionalCreatorFees(asset)

  const canApplyOptionalFees = orders.reduce((acc, order) => {
    const { getCanApplyOptionalFees } = readOrderFees(order)
    return getCanApplyOptionalFees(maxBasisPoints) && acc
  }, false)

  const shouldShowOptionalCreatorFee =
    getShowOptionalCreatorFee(canApplyOptionalFees)

  const { handleSubmit, watch, formState, getValues, control } =
    useForm<FormData>({
      mode: "onChange",
      defaultValues: {
        totalCreatorFeePercentage: basisPointsToPercentage(maxBasisPoints),
      },
    })

  const totalCreatorFeePercentage = watch("totalCreatorFeePercentage")

  const { mapQuantityAfterFees } = useItemFees(
    asset,
    quantity,
    percentageToBasisPoints(totalCreatorFeePercentage),
  )

  const onSubmit = handleSubmit(
    async ({ totalCreatorFeePercentage }: FormData) => {
      const optionalCreatorFeeBasisPoints =
        totalCreatorFeePercentage &&
        !Number.isNaN(Number(totalCreatorFeePercentage))
          ? percentageToBasisPoints(totalCreatorFeePercentage)
          : undefined

      if (
        shouldShowOptionalCreatorFee &&
        optionalCreatorFeeBasisPoints !== undefined
      ) {
        trackOptionalCreatorFeeSubmitted({
          source: "semi-fungible-accept-offer-modal",
          isMaxFees: optionalCreatorFeeBasisPoints === maxBasisPoints,
          optionalBasisPoints: optionalCreatorFeeBasisPoints,
          maxBasisPoints,
          percent: optionalCreatorFeeBasisPoints / maxBasisPoints,
        })
      }

      onNext(renderNext({ optionalCreatorFeeBasisPoints }))
    },
  )

  const isSubstitutionEnabled = minTotalPrice !== undefined
  const hasPriceRange =
    isSubstitutionEnabled && !minTotalPrice.price.isEqualTo(totalPrice.price)

  const creatorFeeInputProps = useOptionalCreatorFeeInput({ maxBasisPoints })
  const totalCreatorFeePercentageError =
    getValues("totalCreatorFeePercentage") &&
    formState.errors.totalCreatorFeePercentage?.message

  return (
    <Modal.Form onSubmit={onSubmit}>
      <Modal.Header>
        <Modal.Header.Title>
          {t("creatorFeeInputModal.header", "Accept offer")}
        </Modal.Header.Title>
      </Modal.Header>
      {/* This is essentially a specificity override */}
      <Modal.Body paddingY={{ _: "0", sm: "0" }}>
        <ItemOfferDetails
          className="p-0 pt-6"
          item={asset}
          quantity={Number(quantity)}
          renderPriceSide={() => (
            <Item.Side className="max-w-full">
              <Text.Body
                className="flex justify-end text-secondary"
                size="tiny"
              >
                {t("creatorFeeInputModal.priceLabel", "Offer value")}
              </Text.Body>
              <Text.Body weight="semibold">
                <Flex>
                  <TokenPrice
                    {...totalPrice.tokenPricePayment}
                    fontWeight={600}
                    price={
                      hasPriceRange ? minTotalPrice.price : totalPrice.price
                    }
                    symbol={
                      hasPriceRange ? "" : totalPrice.tokenPricePayment.symbol
                    }
                    symbolVariant="raw"
                  />
                  {hasPriceRange && (
                    <>
                      {EN_DASH}
                      <TokenPrice
                        {...totalPrice.tokenPricePayment}
                        fontWeight={600}
                        price={totalPrice.price}
                        symbol={totalPrice.tokenPricePayment.symbol}
                        symbolVariant="raw"
                      />
                    </>
                  )}
                </Flex>
              </Text.Body>
              <Text.Body
                className="flex justify-end text-secondary"
                size="tiny"
              >
                {displayFiat(
                  hasPriceRange ? minTotalPrice.price : totalPrice.usdPrice,
                )}
                {hasPriceRange && (
                  <>
                    {EN_DASH}
                    {displayFiat(totalPrice.usdPrice)}
                  </>
                )}
              </Text.Body>
            </Item.Side>
          )}
        />
        <Separator className="my-6" />
        <FlexColumn className="gap-2">
          <SpaceBetween>
            <Text.Body>
              {isSubstitutionEnabled
                ? t("maxOfferValue", "Max offer value")
                : t("totalOfferValue", "Total offer value")}
            </Text.Body>
            <Text.Body>
              <TokenPrice
                {...totalPrice.tokenPricePayment}
                fontWeight={600}
                price={totalPrice.price}
                symbol={totalPrice.tokenPricePayment.symbol}
                symbolVariant="raw"
              />
            </Text.Body>
          </SpaceBetween>
          <SpaceBetween>
            <Flex className="items-center">
              <Text.Body>{creatorFeeInputProps.label}</Text.Body>
              <InfoIcon
                overrides={{
                  Button: { style: { marginLeft: "4px" } },
                  Icon: { size: 14 },
                  Tooltip: { interactive: true },
                }}
                tooltipContent={creatorFeeInputProps.tooltipContent}
              />
            </Flex>

            <Text>
              {totalCreatorFeePercentage
                ? `${totalCreatorFeePercentage}%`
                : EMPTY_PRICE_DISPLAY}
            </Text>
          </SpaceBetween>

          <FormControl
            disabled={!shouldShowOptionalCreatorFee}
            error={totalCreatorFeePercentageError}
            hideLabel
            label={creatorFeeInputProps.label}
          >
            <CreatorFeeToggleGroup
              control={control}
              enforcedBasisPoints={
                orders.length === 1
                  ? readOrderFees(orders[0]).enforcedBasisPoints
                  : undefined
              }
              error={Boolean(totalCreatorFeePercentageError)}
              maxBasisPoints={maxBasisPoints}
              name="totalCreatorFeePercentage"
            />
          </FormControl>
          <Block marginTop="12px">
            <CreatorFeeShowSupport basisPoints={maxBasisPoints} />
          </Block>

          <SpaceBetween>
            <Text.Body>{t("openseaFee", "OpenSea fee")}</Text.Body>
            <ServiceFeeText orders={orders} />
          </SpaceBetween>
          <SpaceBetween>
            <Text weight="semibold">
              {isSubstitutionEnabled
                ? t("maxOfferEarnings", "Max offer earnings")
                : t("totalEarnings", "Total earnings")}
            </Text>
            <FlexColumn className="items-end">
              <Text.Body weight="semibold">
                <TokenPrice
                  {...totalPrice.tokenPricePayment}
                  fontWeight={600}
                  price={mapQuantityAfterFees(totalPrice.price)}
                  symbol={totalPrice.tokenPricePayment.symbol}
                  symbolVariant="raw"
                />
              </Text.Body>
              <Text.Body className="text-secondary" size="tiny">
                {displayFiat(mapQuantityAfterFees(totalPrice.usdPrice))}
              </Text.Body>
            </FlexColumn>
          </SpaceBetween>
        </FlexColumn>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button disabled={!formState.isValid} type="submit">
          {t("creatorFeeInputModal.acceptOffer", "Accept offer")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal.Form>
  )
}
