import React from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { ErrorFetchingData } from "@/components/common/ErrorFetchingData.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import {
  ItemPriceHistoryChartQuery,
  ItemPriceHistoryChartQuery$variables,
} from "@/lib/graphql/__generated__/ItemPriceHistoryChartQuery.graphql"
import {
  PriceHistoryChart,
  PriceHistoryChartProps,
  PriceHistoryChartSkeleton,
} from "./PriceHistoryChart.react"
import { usePriceHistoryChartAdjustedStartTime } from "./usePriceHistoryChartAdjustedStartTime"

type LazyItemPriceHistoryChartProps = Omit<
  ItemPriceHistoryChartQuery$variables,
  "startDate"
> &
  Omit<PriceHistoryChartProps, "data">

const LazyItemPriceHistoryChart = ({
  archetype,
  startDate,
  bucketSize = "DAY",
  ...restProps
}: LazyItemPriceHistoryChartProps) => {
  const adjustedStartDate = usePriceHistoryChartAdjustedStartTime({
    startDate,
    bucketSize,
  })

  const data = useLazyLoadQuery<ItemPriceHistoryChartQuery>(
    graphql`
      query ItemPriceHistoryChartQuery(
        $archetype: ArchetypeInputType!
        $startDate: DateTime
        $bucketSize: BucketSize = DAY
      ) {
        tradeHistory(
          minTime: $startDate
          archetype: $archetype
          bucketSize: $bucketSize
        ) {
          ...PriceHistoryChart_data
        }
      }
    `,
    { archetype, startDate: adjustedStartDate?.toISOString(), bucketSize },
  )

  return (
    <PriceHistoryChart
      bucketSize={bucketSize}
      data={data.tradeHistory}
      startDate={adjustedStartDate}
      {...restProps}
    />
  )
}

export const ItemPriceHistoryChart = (
  props: LazyItemPriceHistoryChartProps,
) => {
  return (
    <ErrorBoundary
      fallback={({ resetError }) => (
        <ErrorFetchingData
          height={props.height || undefined}
          resetError={resetError}
        />
      )}
    >
      <SsrSuspense fallback={<PriceHistoryChartSkeleton {...props} />}>
        <LazyItemPriceHistoryChart {...props} />
      </SsrSuspense>
    </ErrorBoundary>
  )
}
