import React, { createContext, useContext, forwardRef, useMemo } from "react"
import styled from "styled-components"
import { variant } from "styled-system"
import {
  Button,
  ButtonWithIconProps,
  ButtonWithCustomIconProps,
  ButtonSize,
  ButtonProps,
} from "../Button"
import { Flex, FlexProps } from "../Flex"

type ButtonGroupContext = {
  variant: ButtonProps["variant"]
  size: ButtonSize
  stacked?: boolean
}

export type ButtonGroupProps = FlexProps & Partial<ButtonGroupContext>

const ButtonGroupContext = createContext<ButtonGroupContext>({
  variant: "primary",
  size: "large",
  stacked: false,
})

const ButtonGroupBase = forwardRef<HTMLDivElement, ButtonGroupProps>(
  function ButtonGroupBase(
    {
      variant = "primary",
      size = "large",
      stacked = false,
      ...rest
    }: ButtonGroupProps,
    ref,
  ) {
    const value = useMemo(
      () => ({ variant, size, stacked }),
      [variant, size, stacked],
    )
    return (
      <ButtonGroupContext.Provider value={value}>
        <StyledButtonGroup {...rest} ref={ref} />
      </ButtonGroupContext.Provider>
    )
  },
)

export type GroupButtonProps = (
  | Omit<ButtonProps, keyof ButtonGroupContext>
  | ButtonWithIconProps
  | ButtonWithCustomIconProps
) & { $stacked?: boolean }

const StyledButton = styled(Button)<GroupButtonProps>`
  && {
    border-radius: 0;
  }

  ${props =>
    variant({
      prop: "$stacked",
      variants: {
        true: {
          "&&:not(:first-child)": {
            marginTop: "-2px", // Makes the border between buttons "overlap" so the border hover state can be present
          },
          ":first-child": {
            borderTopLeftRadius: props.theme.borderRadius.button,
            borderTopRightRadius: props.theme.borderRadius.button,
          },
          ":last-child": {
            borderBottomLeftRadius: props.theme.borderRadius.button,
            borderBottomRightRadius: props.theme.borderRadius.button,
          },
        },
        false: {
          "&&:not(:first-child)": {
            marginLeft: "-2px", // Makes the border between buttons "overlap" so the border hover state can be present
          },
          ":first-child": {
            borderTopLeftRadius: props.theme.borderRadius.button,
            borderBottomLeftRadius: props.theme.borderRadius.button,
          },
          ":last-child": {
            borderTopRightRadius: props.theme.borderRadius.button,
            borderBottomRightRadius: props.theme.borderRadius.button,
          },
        },
      },
    })}

  &&:hover,
  &&:focus {
    /* This fixes a case where the hover styles get hidden by the neighboring elements */
    z-index: 1;
  }
`

const GroupButton = forwardRef<HTMLButtonElement, GroupButtonProps>(
  function GroupButton(props, ref) {
    const { variant, size, stacked } = useContext(ButtonGroupContext)
    return (
      <StyledButton
        {...props}
        $stacked={stacked}
        ref={ref}
        size={size}
        variant={variant}
      />
    )
  },
)

const StyledButtonGroup = styled(Flex)`
  width: fit-content;
`

export const ButtonGroup = Object.assign(ButtonGroupBase, {
  Button: GroupButton,
})
