import React from "react"
import { CenterAligned, Spinner } from "@opensea/ui-kit"
import { range } from "lodash"
import { LoadMoreFn } from "react-relay"
import { OperationType } from "relay-runtime"
import { Block, BlockProps } from "@/design-system/Block"
import { List } from "@/design-system/List"
import {
  loadNextToLoadMore,
  ScrollingPaginator,
} from "@/design-system/ScrollingPaginator"
import { useCallbackRef } from "@/hooks/useCallbackRef"

type Props<TQuery extends OperationType> = {
  pageSize: number
  itemHeight: number
  pagination: {
    isLoadingNext: boolean
    hasNext: boolean
    loadNext: LoadMoreFn<TQuery>
  }
  overrides?: { Root?: { props: BlockProps } }
  children: React.ReactNode
}

function LazyLoadListBase<TQuery extends OperationType>({
  pageSize,
  itemHeight,
  overrides,
  pagination: { hasNext, isLoadingNext, loadNext },
  children,
}: Props<TQuery>) {
  const [contentRef, setContentRef] = useCallbackRef<HTMLDivElement>()
  return (
    <Block ref={setContentRef} {...overrides?.Root?.props}>
      <List variant="framed">{children}</List>
      <ScrollingPaginator
        intersectionOptions={{
          root: contentRef.current,
          rootMargin: `${itemHeight * 8}px`,
        }}
        page={{
          loadMore: count => loadNextToLoadMore({ loadNext, count }),
          isLoading: () => isLoadingNext,
          hasMore: () => hasNext,
        }}
        size={pageSize}
      >
        <CenterAligned style={{ height: `${itemHeight}px` }}>
          <Spinner />
        </CenterAligned>
      </ScrollingPaginator>
    </Block>
  )
}

type LazyLoadListSkeletonProps = {
  pageSize: number
  renderItem: (index: number) => React.ReactNode
  count?: number
  overrides?: { Root?: { props: BlockProps } }
}

const LazyLoadListSkeleton = ({
  count,
  pageSize,
  overrides,
  renderItem,
}: LazyLoadListSkeletonProps) => {
  return (
    <Block {...overrides?.Root?.props}>
      <List variant="framed">
        {range(Math.min(pageSize, count ?? pageSize)).map(index =>
          renderItem(index),
        )}
      </List>
    </Block>
  )
}

export const LazyLoadList = Object.assign(LazyLoadListBase, {
  Skeleton: LazyLoadListSkeleton,
})
