import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { Block } from "@/design-system/Block"
import { TradeStationOrderPrice_order$key } from "@/lib/graphql/__generated__/TradeStationOrderPrice_order.graphql"

type TradeStationOrderPriceProps = {
  order: TradeStationOrderPrice_order$key
}

export const TradeStationOrderPrice = ({
  order: orderDataKey,
}: TradeStationOrderPriceProps) => {
  const order = useFragment(
    graphql`
      fragment TradeStationOrderPrice_order on OrderV2Type {
        ...OrderPrice
        ...OrderUsdPrice
      }
    `,
    orderDataKey,
  )

  return (
    <Flex className="flex-wrap items-end gap-2">
      <Text.Heading size="large">
        <OrderPrice order={order} variant="perUnit" />
      </Text.Heading>
      <Block marginBottom="6px">
        <Text.Body className="text-secondary" size="small">
          <OrderUsdPrice order={order} secondary variant="perUnit" />
        </Text.Body>
      </Block>
    </Flex>
  )
}
