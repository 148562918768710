/**
 * @generated SignedSource<<0c7b21bd23df69b6c52526a21bc25fe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UnlockableContentModalQuery$variables = {
  assetId: string;
  isOwner: boolean;
};
export type UnlockableContentModalQuery$data = {
  readonly asset?: {
    readonly unlockableContent: string | null;
  };
};
export type UnlockableContentModalQuery = {
  response: UnlockableContentModalQuery$data;
  variables: UnlockableContentModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isOwner"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "assetId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unlockableContent",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlockableContentModalQuery",
    "selections": [
      {
        "condition": "isOwner",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnlockableContentModalQuery",
    "selections": [
      {
        "condition": "isOwner",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b910eac0ccc3782588d6ca5ec3b2e290",
    "id": null,
    "metadata": {},
    "name": "UnlockableContentModalQuery",
    "operationKind": "query",
    "text": "query UnlockableContentModalQuery(\n  $assetId: AssetRelayID!\n  $isOwner: Boolean!\n) {\n  asset(asset: $assetId) @include(if: $isOwner) {\n    unlockableContent\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4446ed308c67c56fa7866308e34255b";

export default node;
