/**
 * @generated SignedSource<<311759cd91f841db3557ac92576ca2b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Property_traitWithFloorPrice$data = {
  readonly floorPrice: {
    readonly symbol: string;
    readonly unit: string;
  } | null;
  readonly " $fragmentType": "Property_traitWithFloorPrice";
};
export type Property_traitWithFloorPrice$key = {
  readonly " $data"?: Property_traitWithFloorPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"Property_traitWithFloorPrice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Property_traitWithFloorPrice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TraitType",
  "abstractKey": null
};

(node as any).hash = "049ce71bd4a65d2d72f84c70a01694d3";

export default node;
