import React, { Suspense } from "react"
import {
  Icon,
  Text,
  UnstyledButton,
  VerticalAligned,
  Skeleton,
} from "@opensea/ui-kit"
import { useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Markdown } from "@/design-system/Markdown"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import {
  UnlockableContentModalQuery,
  UnlockableContentModalQuery$variables,
} from "@/lib/graphql/__generated__/UnlockableContentModalQuery.graphql"
import { graphql, GraphQLProps } from "@/lib/graphql/graphql"

type UnlockableContentProps = {
  onClose: () => void
  variables: UnlockableContentModalQuery$variables
}

const UnlockableContent = ({ onClose, variables }: UnlockableContentProps) => {
  const data = useLazyLoadQuery<UnlockableContentModalQuery>(
    graphql`
      query UnlockableContentModalQuery(
        $assetId: AssetRelayID!
        $isOwner: Boolean!
      ) {
        asset(asset: $assetId) @include(if: $isOwner) {
          unlockableContent
        }
      }
    `,
    variables,
  )
  const unlockableContent = data.asset?.unlockableContent
  const t = useTranslate("modals")

  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {t("unlockableContent.title", "Unlockable Content")}
        </Modal.Header.Title>
      </Modal.Header>

      <Modal.Body>
        <StyledUnlockableContent>
          {!unlockableContent ? (
            <UnlockableContentSkeleton />
          ) : (
            <Markdown>{unlockableContent}</Markdown>
          )}
        </StyledUnlockableContent>

        {!variables.isOwner ? (
          <Text asChild color="secondary" size="small" weight="semibold">
            <p>
              {t(
                "unlockableContent.warning",
                `This content can only be unlocked and revealed by the owner of this
            item.`,
              )}
            </p>
          </Text>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("unlockableContent.close", "Close")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}

const UnlockableContentSkeleton = () => (
  <Skeleton>
    <Skeleton.Line />
    <Skeleton.Line />
    <Skeleton.Line />
    <Skeleton.Line />
  </Skeleton>
)

const ConnectedUnlockableContent = (props: UnlockableContentProps) => {
  return (
    <Suspense fallback={<UnlockableContentSkeleton />}>
      <UnlockableContent {...props} />
    </Suspense>
  )
}

const StyledUnlockableContent = styled.div`
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-radius: ${props => props.theme.borderRadius.default};
  padding: 18px;
  overflow: auto;
`

type Props = Pick<GraphQLProps<UnlockableContentModalQuery>, "variables">

export const UnlockableContentModal = ({ variables }: Props) => {
  const t = useTranslate("modals")
  const { login } = useWallet()
  const { isOwner } = variables

  const handleOpen = (open: () => unknown) => async () => {
    if (isOwner) {
      await login()
    }
    open()
  }

  return (
    <Modal
      trigger={open => (
        <UnlockableContentModalButton onClick={handleOpen(open)}>
          <Icon
            className="text-accent-purple"
            value={isOwner ? "lock_open" : "lock"}
          />
          <VerticalAligned className="ml-2">
            {isOwner ? (
              <Text color="accent-purple" weight="semibold">
                {t(
                  "unlockableContent.reveal.body",
                  "Reveal unlockable content",
                )}
              </Text>
            ) : (
              <Text>
                {t("unlockableContent.includes.body", `Includes {{text}}`, {
                  text: (
                    <Text color="accent-purple" weight="semibold">
                      {t(
                        "unlockableContent.unlockable.body",
                        "unlockable content",
                      )}
                    </Text>
                  ),
                })}
              </Text>
            )}
          </VerticalAligned>
        </UnlockableContentModalButton>
      )}
    >
      {onClose => (
        <ConnectedUnlockableContent variables={variables} onClose={onClose} />
      )}
    </Modal>
  )
}

const UnlockableContentModalButton = styled(UnstyledButton)`
  display: flex;
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-radius: ${props => props.theme.borderRadius.default};
  padding: 16px;
  cursor: pointer;
  width: 100%;
`
