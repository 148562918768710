/**
 * @generated SignedSource<<4145b43f4121450d271b7361216cae10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatorFeeInputModalContent_asset$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemOfferDetails_item" | "readOptionalCreatorFees_item" | "useItemFees_item">;
  readonly " $fragmentType": "CreatorFeeInputModalContent_asset";
};
export type CreatorFeeInputModalContent_asset$key = {
  readonly " $data"?: CreatorFeeInputModalContent_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorFeeInputModalContent_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorFeeInputModalContent_asset",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemOfferDetails_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOptionalCreatorFees_item",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionType",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCreatorFeesEnforced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCreatorFeeBasisPoints",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "type": "ItemType",
          "abstractKey": "__isItemType"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useItemFees_item"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "32272e97a7b449a0a18ed2a954ad836b";

export default node;
