import { SemiFungibleBulkSaleParams } from "@/components/trade/SemiFungibleTradeStation/types"
import { getTrackingFn } from "@/lib/analytics"
import { getItemTrackingFnWithExtraParamsV2 } from "@/lib/analytics/events/itemEvents"

export const trackClickBuyNowAdd = getTrackingFn("click buy now add")
export const trackClickBuyNowRemove = getTrackingFn("click buy now remove")
export const trackClickBuyNow =
  getItemTrackingFnWithExtraParamsV2<SemiFungibleBulkSaleParams>(
    "click buy now",
  )
export const trackClickBuyNowSubstituteItems =
  getItemTrackingFnWithExtraParamsV2<SemiFungibleBulkSaleParams>(
    "click buy now substitute items",
  )
