/**
 * @generated SignedSource<<72674be3670e76b533848c2d9590ae86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SemiFungibleTradeStation_bestListings$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TradeStationBuyTab_bestListings">;
  readonly " $fragmentType": "SemiFungibleTradeStation_bestListings";
};
export type SemiFungibleTradeStation_bestListings$key = {
  readonly " $data"?: SemiFungibleTradeStation_bestListings$data;
  readonly " $fragmentSpreads": FragmentRefs<"SemiFungibleTradeStation_bestListings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractAddress"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tokenId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SemiFungibleTradeStation_bestListings",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "chain"
        },
        {
          "kind": "Variable",
          "name": "contractAddress",
          "variableName": "contractAddress"
        },
        {
          "kind": "Variable",
          "name": "tokenId",
          "variableName": "tokenId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "TradeStationBuyTab_bestListings"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8eb9ac09c7ec821e3070542b9e7a8d5a";

export default node;
