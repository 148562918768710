/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { fromUnixTime, intlFormat, isSameDay, startOfYear } from "date-fns"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Date_trait$key } from "../../lib/graphql/__generated__/Date_trait.graphql"
import { graphql } from "../../lib/graphql/graphql"
import { snakeCaseToCapitalCase } from "../../lib/helpers/stringUtils"

type Props = {
  className?: string
  trait: Date_trait$key
}

export const Date = ({ className, trait: traitDataKey }: Props) => {
  const { traitType, floatValue, intValue } = useFragment(
    graphql`
      fragment Date_trait on TraitType {
        traitType
        floatValue
        intValue
      }
    `,
    traitDataKey,
  )

  const value = floatValue === null ? intValue : floatValue
  if (value === null) {
    return null
  }
  const valueDate = fromUnixTime(+value)
  const humanDate = intlFormat(valueDate, {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  })

  const isYearValue = isSameDay(valueDate, startOfYear(valueDate))
  const trimmedDate = isYearValue
    ? intlFormat(valueDate, { year: "numeric" })
    : ""

  return (
    <DivContainer className={className}>
      <div className="Date--label">
        <div className="Date--type">{snakeCaseToCapitalCase(traitType)}</div>
        <div className="Date--value">
          {trimmedDate ? trimmedDate : humanDate}
        </div>
      </div>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  cursor: pointer;

  .Date--label {
    display: flex;
    font-weight: 500;
    justify-content: space-between;

    .Date--value {
      color: ${props => props.theme.colors.text.secondary};
      min-width: fit-content;
      padding-left: 4px;
    }
  }
`
