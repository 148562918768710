import React from "react"

export const CategoryIcon = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="24"
        id="mask0_1845_35583"
        maskUnits="userSpaceOnUse"
        width="24"
        x="0"
        y="0"
      >
        <rect fill="currentColor" height="24" width="24" />
      </mask>
      <g>
        <path
          d="M6.5 11L12 2L17.5 11H6.5ZM17.5 22C16.25 22 15.1873 21.5627 14.312 20.688C13.4373 19.8127 13 18.75 13 17.5C13 16.25 13.4373 15.1873 14.312 14.312C15.1873 13.4373 16.25 13 17.5 13C18.75 13 19.8127 13.4373 20.688 14.312C21.5627 15.1873 22 16.25 22 17.5C22 18.75 21.5627 19.8127 20.688 20.688C19.8127 21.5627 18.75 22 17.5 22ZM3 21.5V13.5H11V21.5H3ZM17.5 20C18.2 20 18.7917 19.7583 19.275 19.275C19.7583 18.7917 20 18.2 20 17.5C20 16.8 19.7583 16.2083 19.275 15.725C18.7917 15.2417 18.2 15 17.5 15C16.8 15 16.2083 15.2417 15.725 15.725C15.2417 16.2083 15 16.8 15 17.5C15 18.2 15.2417 18.7917 15.725 19.275C16.2083 19.7583 16.8 20 17.5 20ZM5 19.5H9V15.5H5V19.5ZM10.05 9H13.95L12 5.85L10.05 9Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
