import { useCallback } from "react"
import { assetsVisitorUpdateMutation } from "@/lib/graphql/__generated__/assetsVisitorUpdateMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useReportAssetVisitor = () => {
  const { mutate } = useGraphQL()

  return useCallback(
    (asset: string) => {
      return mutate<assetsVisitorUpdateMutation>(
        graphql`
          mutation assetsVisitorUpdateMutation($asset: AssetRelayID!) {
            assets {
              createAssetVisitor(asset: $asset)
            }
          }
        `,
        { asset },
      )
    },
    [mutate],
  )
}
