import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { useTranslate } from "@/hooks/useTranslate"
import { snakeCaseToCapitalCase } from "../../lib/helpers/stringUtils"

type Props = {
  className?: string
  value: string
  type: string
  count?: number
  percentage?: number
  disablePercentages?: boolean
  floorPrice?: JSX.Element | string
}

export const PropertyCell = ({
  className,
  value,
  type,
  count,
  percentage,
  disablePercentages,
  floorPrice,
}: Props) => {
  const t = useTranslate("components")

  return (
    <DivContainer className={className}>
      <Text
        asChild
        className="uppercase text-secondary"
        size="tiny"
        weight="semibold"
      >
        <div>
          <Overflow lines={1}>
            {/* TODO(i18n): localize instead of snakeCaseToCapitalCase() */}
            {snakeCaseToCapitalCase(type)}
          </Overflow>
        </div>
      </Text>
      <Flex className="items-center justify-center">
        <Text
          asChild
          className="max-w-[70%] py-1"
          data-testid="Property--value"
          size="small"
          weight="semibold"
        >
          <div>
            <StyledOverflow lines={1}>
              {snakeCaseToCapitalCase(value)}
            </StyledOverflow>
          </div>
        </Text>
        {!disablePercentages && (
          <>
            <Text className="flex-none pl-1 text-secondary" size="small">
              {percentage === undefined
                ? t("propertyCell.newTrait", "New")
                : percentage >= 1
                ? `${Math.round(percentage)}%`
                : percentage >= 0.01
                ? `${percentage.toFixed(2)}%`
                : t("propertyCell.rarityMetric.oneOf", "1 of {{count}}", {
                    count,
                  })}
            </Text>
          </>
        )}
      </Flex>
      {floorPrice && (
        <Text asChild className="text-secondary" size="small">
          <div>
            <Overflow lines={1}>
              {t("propertyCell.price", "Floor: {{floorPrice}}", {
                floorPrice,
              })}
            </Overflow>
          </div>
        </Text>
      )}
    </DivContainer>
  )
}

const DivContainer = styled.div`
  background: ${props => props.theme.colors.components.background.gray1};
  border-radius: 6px;
  padding: 10px;
  text-align: center;
`

const StyledOverflow = styled(Overflow)`
  word-wrap: break-word;
`
