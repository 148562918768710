import React from "react"
import { Flex, Text, UnstyledButton, VerticalAligned } from "@opensea/ui-kit"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { shortSymbolDisplay } from "@/lib/helpers/numberUtils"
import { FavoriteIcon } from "./FavoriteIcon.react"

type FavoriteItemProps = {
  isFavorite: boolean
  favoritesCount: number
  toggleIsFavorite: (event: React.MouseEvent<HTMLElement>) => Promise<void>
  iconSize?: number
  disabled?: boolean
}

export const FavoriteItem = React.forwardRef<HTMLDivElement, FavoriteItemProps>(
  function FavoriteItem(
    { isFavorite, favoritesCount, toggleIsFavorite, disabled, iconSize = 20 },
    ref,
  ) {
    const t = useTranslate("components")
    const countDisplay = (
      <VerticalAligned className="ml-1 text-primary">
        {shortSymbolDisplay(favoritesCount)}
      </VerticalAligned>
    )

    const favoriteIconTooltip = disabled
      ? t("favoriteItem.disabled", "Favoriting is currently disabled")
      : isFavorite
      ? t("favoriteItem.unfavorite", "Unfavorite")
      : t("favoriteItem.favorite", "Favorite")

    return (
      <Flex className="items-center" ref={ref}>
        <Text
          asChild
          className="pr-2"
          color="secondary"
          size="tiny"
          weight="semibold"
        >
          <p>{countDisplay}</p>
        </Text>
        <Tooltip content={favoriteIconTooltip}>
          <Flex>
            <UnstyledButton disabled={disabled} onClick={toggleIsFavorite}>
              <FavoriteIcon
                disabled={disabled}
                isFavorite={isFavorite}
                size={iconSize}
              />
            </UnstyledButton>
          </Flex>
        </Tooltip>
      </Flex>
    )
  },
)
