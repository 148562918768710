import React from "react"
import { graphql, useFragment } from "react-relay"
import { CreateListingButton } from "@/components/orders/CreateListingButton"
import { ButtonProps } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetListButton_asset$key } from "@/lib/graphql/__generated__/AssetListButton_asset.graphql"
import { NumberInput, bn, display } from "@/lib/helpers/numberUtils"

type AssetListButtonProps = Omit<ButtonProps, "href"> & {
  asset: AssetListButton_asset$key
  quantity?: NumberInput
}

export const AssetListButton = ({
  asset: assetDataKey,
  quantity: quantityProp = 1,
  children,
  ...restProps
}: AssetListButtonProps) => {
  const t = useTranslate("sell")
  const asset = useFragment(
    graphql`
      fragment AssetListButton_asset on AssetType {
        ...CreateListingButton_item
      }
    `,
    assetDataKey,
  )
  const quantity = bn(quantityProp)

  return (
    <CreateListingButton
      initialQuantity={quantity}
      item={asset}
      source="item page"
      {...restProps}
    >
      {children ??
        t(
          "listItems",
          {
            "0": "List {{displayCount}} items",
            one: "List item",
            other: "List {{displayCount}} items",
          },
          {
            count: quantity.toNumber(),
            displayCount: display(quantity),
          },
        )}
    </CreateListingButton>
  )
}
