import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { AssetProps } from "@/features/live-updates/components/AssetPageSubscriptions/AssetPageSubscriptions.react"
import { updateAssetStoreWithOfferEventData } from "@/features/live-updates/utils"
import { useAssetPageOfferEventSubscription } from "@/lib/graphql/__generated__/useAssetPageOfferEventSubscription.graphql"

export const useAssetPageOfferEvent = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  const { isActiveAccount } = useWallet()

  const config: GraphQLSubscriptionConfig<useAssetPageOfferEventSubscription> =
    useMemo(
      () => ({
        variables: { slug, relayId },
        subscription: graphql`
          subscription useAssetPageOfferEventSubscription(
            $slug: String!
            $relayId: String!
          ) {
            offerEvent(slug: $slug, itemRelayIds: [$relayId]) {
              itemRelayId
              orderRelayId
              order {
                relayId
                maker {
                  ...wallet_accountKey
                }
                item {
                  ...itemEvents_dataV2
                }
                perUnitPriceType {
                  unit
                  symbol
                  usd
                }
                ...Orders_orders
                  @arguments(
                    isBid: true
                    includeCriteriaTakerAsset: true
                    isSingleAsset: true
                  )
                ...AcceptOfferButton_order @arguments(skipOwnedQuantity: true)
                ...useFulfillSemiFungibleOrders_orders
                ...BuyNowButton_orders
                ...TradeStationOrderPrice_order
                ...TradeStation_bestBid
                relayId
                closedAt
                payment {
                  relayId
                }
              }
            }
          }
        `,
        updater: (store, data) => {
          const maker = data.offerEvent.order?.maker
          // Passing down isMaker so we don't overwrite accept
          //  highest offer button for user's own offers
          const isMaker = isActiveAccount(maker)
          updateAssetStoreWithOfferEventData(
            store,
            data,
            contractAddress,
            chain,
            tokenId,
            isMaker,
          )
        },
      }),
      [slug, relayId, contractAddress, chain, tokenId, isActiveAccount],
    )
  useSubscription<useAssetPageOfferEventSubscription>(config)
}
