import { useMemo } from "react"
import { graphql } from "react-relay"
import {
  useOrdersWithValidMakerOwnedQuantity_order$data,
  useOrdersWithValidMakerOwnedQuantity_order$key,
} from "@/lib/graphql/__generated__/useOrdersWithValidMakerOwnedQuantity_order.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"

type UseOrdersWithValidMakerOwnedQuantityProps<
  T extends useOrdersWithValidMakerOwnedQuantity_order$key,
> = {
  orders: T[]
}

const readValidateMakerOwnedQuantityOrderData =
  inlineFragmentize<useOrdersWithValidMakerOwnedQuantity_order$data>(
    graphql`
      fragment useOrdersWithValidMakerOwnedQuantity_order on OrderV2Type
      @inline {
        makerOwnedQuantity
        remainingQuantityType
        side
        perUnitPriceType {
          unit
        }
        maker {
          relayId
        }
      }
    `,
    data => data,
  )

export const useOrdersWithValidMakerOwnedQuantity = <
  T extends useOrdersWithValidMakerOwnedQuantity_order$key,
>({
  orders,
}: UseOrdersWithValidMakerOwnedQuantityProps<T>): T[] => {
  return useMemo(() => {
    const ordersWithValidMakerOwnedQuantity: T[] = []
    let quantitiesByMaker: Partial<Record<string, BigNumber>> = {}

    orders.forEach(order => {
      const {
        makerOwnedQuantity,
        remainingQuantityType,
        side,
        maker: { relayId: makerId },
        perUnitPriceType: { unit: unitPrice },
      } = readValidateMakerOwnedQuantityOrderData(order)

      const makerQuantitySoFar = quantitiesByMaker[makerId] ?? bn(0)
      const orderMakerAssetQuantity =
        side === "ASK"
          ? bn(remainingQuantityType)
          : bn(remainingQuantityType).times(unitPrice)

      const newMakerQuantity = makerQuantitySoFar.plus(orderMakerAssetQuantity)

      // This is the remaining quantity (left after excluding quantity from previous orders)
      // of order maker asset that the maker owns
      const makerRemainingAssetQuantity =
        bn(makerOwnedQuantity).minus(makerQuantitySoFar)

      // Add order if maker owns enough for order to be fully fulfilled
      if (newMakerQuantity.isLessThanOrEqualTo(makerOwnedQuantity)) {
        ordersWithValidMakerOwnedQuantity.push(order)

        quantitiesByMaker = {
          ...quantitiesByMaker,
          [makerId]: newMakerQuantity,
        }

        return
      }

      // This is the remaining quantity (left after excluding quantity from previous orders)
      // of order items that the maker can fulfill given the maker's remaining owned quantity
      const makerRemainingOrderQuantity =
        side === "ASK"
          ? makerRemainingAssetQuantity
          : makerRemainingAssetQuantity.dividedToIntegerBy(unitPrice)

      // Add order with faked remaining quantity that is valid
      if (makerRemainingOrderQuantity.isGreaterThanOrEqualTo(1)) {
        ordersWithValidMakerOwnedQuantity.push({
          ...order,
          remainingQuantityType: makerRemainingOrderQuantity.toString(),
        })

        quantitiesByMaker = {
          ...quantitiesByMaker,
          [makerId]: makerQuantitySoFar.plus(
            side === "ASK"
              ? makerRemainingOrderQuantity
              : makerRemainingOrderQuantity.times(unitPrice),
          ),
        }

        return
      }

      // Don't add if maker does not own enough
    })

    return ordersWithValidMakerOwnedQuantity
  }, [orders])
}
