/**
 * @generated SignedSource<<ad4083d44468494bcb6a23b40dc99ade>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SemiFungibleTradeStation_asset$data = {
  readonly ownedQuantity: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStationBuyTab_asset" | "TradeStationSellTab_asset">;
  readonly " $fragmentType": "SemiFungibleTradeStation_asset";
};
export type SemiFungibleTradeStation_asset$key = {
  readonly " $data"?: SemiFungibleTradeStation_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"SemiFungibleTradeStation_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SemiFungibleTradeStation_asset",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "identity",
          "value": {}
        }
      ],
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": "ownedQuantity(identity:{})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TradeStationBuyTab_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TradeStationSellTab_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "2d0cfb10e726bb6c532d7a0fca782578";

export default node;
