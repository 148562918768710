import React from "react"
import { FlexEnd, FlexProps, Media, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { IS_SERVER } from "@/constants/environment"
import { Button } from "@/design-system/Button"
import { ButtonGroup } from "@/design-system/ButtonGroup"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { Tooltip } from "@/design-system/Tooltip"
import { ReportModal } from "@/features/report/components/ReportModal"
import { trackSubmitCollectionReport } from "@/features/report/utils/analytics"
import { useVerifiedOwnershipTitle } from "@/hooks/useCopy"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { SocialBar_data$key } from "@/lib/graphql/__generated__/SocialBar_data.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { getSocialIcon } from "@/lib/helpers/icons"
import { getSocialAccountUrl } from "@/lib/helpers/socialUrls"

export type SocialBarProps = FlexProps & {
  data: SocialBar_data$key
}

export const SocialBar = ({ data: dataKey }: SocialBarProps) => {
  const t = useTranslate("components")
  const { theme } = useTheme()
  const {
    isOpen: reportModalOpen,
    close: closeReportModal,
    open: openReportModal,
  } = useIsOpen()
  const verifiedTwitterTitle = useVerifiedOwnershipTitle()
  const data = useFragment(
    graphql`
      fragment SocialBar_data on CollectionType {
        relayId
        discordUrl
        externalUrl
        mediumUsername
        slug
        telegramUrl
        twitterUsername
        connectedInstagramUsername
        connectedTwitterUsername
        assetContracts(first: 2) {
          edges {
            node {
              blockExplorerLink
              chainData {
                blockExplorer {
                  name
                  identifier
                }
              }
            }
          }
        }
      }
    `,
    dataKey,
  )

  const {
    slug,
    discordUrl,
    mediumUsername,
    twitterUsername,
    connectedInstagramUsername: instagramUsername,
    connectedTwitterUsername,
    telegramUrl,
    externalUrl,
    relayId,
  } = data

  const resolvedTwitterUsername = connectedTwitterUsername
    ? connectedTwitterUsername
    : twitterUsername

  const fill = theme === "light" ? "gray" : "fog"
  const values = [
    {
      tooltip: t("socialBar.website", "Website"),
      url: externalUrl,
      icon: getSocialIcon({ name: "website", fill }),
      title: t("socialBar.website", "Website"),
    },
    {
      tooltip: "Discord",
      url: discordUrl,
      icon: getSocialIcon({ name: "discord", fill }),
      title: "Discord",
    },
    {
      tooltip: "Medium",
      url: mediumUsername && getSocialAccountUrl("medium", mediumUsername),
      icon: getSocialIcon({ name: "medium", fill }),
      title: "Medium",
    },
    {
      tooltip: "Telegram",
      url: telegramUrl,
      icon: getSocialIcon({ name: "telegram", fill }),
      title: "Telegram",
    },
    {
      tooltip: "Instagram",
      url:
        instagramUsername &&
        getSocialAccountUrl("instagram", instagramUsername),
      icon: getSocialIcon({ name: "instagram", fill }),
      title: "Instagram",
    },
    {
      tooltip: () => (
        <>
          <div>Twitter</div>
          {connectedTwitterUsername && (
            <Text color="secondary" size="small" weight="semibold">
              {verifiedTwitterTitle}
            </Text>
          )}
        </>
      ),
      url:
        resolvedTwitterUsername &&
        getSocialAccountUrl("twitter", resolvedTwitterUsername),
      icon: getSocialIcon({ name: "twitter", fill }),
      title: "Twitter",
      description: connectedTwitterUsername ? verifiedTwitterTitle : "",
    },
  ]

  const assetContracts = getNodes(data.assetContracts)

  if (assetContracts.length === 1) {
    const contract = assetContracts[0]
    values.push({
      tooltip: contract.chainData.blockExplorer.name,
      url: contract.blockExplorerLink,
      icon: getSocialIcon({
        name: contract.chainData.blockExplorer.identifier,
        fill,
      }),
      title: contract.chainData.blockExplorer.name,
    })
  }

  return (
    <>
      <Media greaterThanOrEqual="lg">
        <FlexEnd>
          <ButtonGroup variant="secondary">
            {values.map(
              ({ tooltip, url, icon }) =>
                url && (
                  <Tooltip content={tooltip} key={url}>
                    <ButtonGroup.Button
                      // TODO(i18n): translate
                      aria-label={`${tooltip}-link`}
                      href={url}
                      icon={icon}
                      variant="secondary"
                    />
                  </Tooltip>
                ),
            )}

            <Dropdown
              appendTo={IS_SERVER ? undefined : document.body}
              content={({ List, Item, close }) => (
                <List>
                  <Item
                    onClick={() => {
                      openReportModal()
                      close()
                    }}
                  >
                    <Item.Avatar
                      color={fill === "gray" ? "gray-3" : "white"}
                      icon="flag"
                    />
                    <Item.Content>
                      <Item.Title>{t("socialBar.report", "Report")}</Item.Title>
                    </Item.Content>
                  </Item>
                </List>
              )}
            >
              <ButtonGroup.Button
                aria-label={t("socialBar.more", "Collection More")}
                icon="more_vert"
                variant="secondary"
              />
            </Dropdown>
          </ButtonGroup>
        </FlexEnd>
      </Media>

      <Media lessThan="lg">
        <Dropdown
          appendTo={IS_SERVER ? undefined : document.body}
          content={({ List, Item, close }) => (
            <List>
              {values.map(
                ({ url, title, icon, description }) =>
                  url && (
                    <Item href={url} key={url} onClick={close}>
                      <Item.Avatar>{icon}</Item.Avatar>
                      <Item.Content>
                        <Item.Title>{title}</Item.Title>
                        {description && (
                          <Item.Description>{description}</Item.Description>
                        )}
                      </Item.Content>
                    </Item>
                  ),
              )}
              <Item
                onClick={() => {
                  openReportModal()
                  close()
                }}
              >
                <Item.Avatar
                  color={fill === "gray" ? "gray-3" : "white"}
                  icon="flag"
                />
                <Item.Content>
                  <Item.Title>{t("socialBar.report", "Report")}</Item.Title>
                </Item.Content>
              </Item>
            </List>
          )}
          hideOnClick
        >
          <Button icon="more_vert" variant="secondary" />
        </Dropdown>
      </Media>
      <ReportModal
        isOpen={reportModalOpen}
        subject={{ collection: relayId }}
        onClose={closeReportModal}
        onSubmit={({ additionalComments, originalCreatorUrl, reason }) => {
          trackSubmitCollectionReport({
            slug,
            additionalComments,
            originalCreatorUrl,
            reason,
          })
        }}
      />
    </>
  )
}
