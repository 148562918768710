import React, { useMemo, useCallback } from "react"
import {
  Icon,
  UnstyledButton,
  Text,
  Label,
  Input,
  InputProps,
  CheckboxProps,
  Checkbox,
} from "@opensea/ui-kit"
import { merge } from "lodash"
import { Controller } from "react-hook-form"
import { useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { OrderToQuantity } from "@/components/trade/BulkPurchaseModal"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { FormControl, FormControlProps } from "@/design-system/FormControl"
import { Tooltip } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { ListingFeesSupportsCreator } from "@/features/creator-fees/components/ListingFeesSupportsCreator"
import { useTotalPrice } from "@/features/shopping-cart/hooks/useTotalPrice"
import { useForm } from "@/hooks/useForm"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { useFulfillSemiFungibleOrders_asset$key } from "@/lib/graphql/__generated__/useFulfillSemiFungibleOrders_asset.graphql"
import {
  useFulfillSemiFungibleOrders_orders$data,
  useFulfillSemiFungibleOrders_orders$key,
} from "@/lib/graphql/__generated__/useFulfillSemiFungibleOrders_orders.graphql"
import { BigNumber, bn, display } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"
import { AcceptOffersButton } from "../components/AcceptOffersButton/AcceptOffersButton.react"
import { BuyNowButton } from "../components/BuyNowButton/BuyNowButton.react"
import { SemiFungibleFulfillButtonProps } from "../types"
import { useOrdersWithValidMakerOwnedQuantity } from "./useOrdersWithValidMakerOwnedQuantity"

const MAX_ITEMS_TO_FILL_WITH_SUBSTITUTION = 100
const MAX_BACKUP_ITEMS_TO_FILL = 30

const getOrderToQuantity = (
  orders: useFulfillSemiFungibleOrders_orders$data,
  quantity: BigNumber | undefined,
) => {
  if (!quantity) {
    return {}
  }

  const orderToQuantity: OrderToQuantity = {}
  let quantityIncluded = bn(0)
  let listingIndex = 0

  while (
    quantityIncluded.isLessThan(quantity) &&
    listingIndex < orders.length
  ) {
    const listing = orders[listingIndex]

    const quantityFromListingToInclude = BigNumber.min(
      listing.remainingQuantityType,
      quantity.minus(quantityIncluded),
    )

    orderToQuantity[listing.relayId] = quantityFromListingToInclude.toNumber()
    quantityIncluded = quantityIncluded.plus(quantityFromListingToInclude)
    listingIndex++
  }

  return orderToQuantity
}

type SubstituteItemsProps = {
  overrides?: {
    Checkbox?: {
      props: CheckboxProps
    }
    Input?: {
      props: InputProps
    }
  }
}

type FulfillSemiFungibleOrdersFormData = {
  quantity: string
  isSubstitutionEnabled: boolean
  pricePerItemLimit: string
}

type PaginationProps = {
  hasMoreOrders: boolean
  isLoadingMoreOrders: boolean
  loadMoreOrders: () => void
}

type UseBuyNowProps = PaginationProps & {
  orders: useFulfillSemiFungibleOrders_orders$key | null
  asset: useFulfillSemiFungibleOrders_asset$key | null
  side: "BUY" | "SELL"
}

export const useFulfillSemiFungibleOrders = ({
  orders: ordersDataKey,
  asset: assetDataKey,
  side,
  hasMoreOrders,
  isLoadingMoreOrders,
  loadMoreOrders,
}: UseBuyNowProps) => {
  const t = useTranslate("components")

  const orders = useFragment(
    graphql`
      fragment useFulfillSemiFungibleOrders_orders on OrderV2Type
      @relay(plural: true) {
        relayId
        payment {
          symbol
        }
        perUnitPriceType {
          unit
        }
        remainingQuantityType
        ...useOrdersWithValidMakerOwnedQuantity_order
        ...useTotalPrice_orders
        ...BuyNowButton_orders
        ...AcceptOffersButton_orders
        ...ListingFeesSupportsCreator_orders
      }
    `,
    ordersDataKey ?? null,
  )

  const asset = useFragment(
    graphql`
      fragment useFulfillSemiFungibleOrders_asset on AssetType {
        relayId
        totalQuantity
        ownedQuantity(identity: {})
        ...AcceptOffersButton_asset
      }
    `,
    assetDataKey,
  )

  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors, touchedFields },
    setFocus,
    trigger,
  } = useForm<FulfillSemiFungibleOrdersFormData>({
    mode: "all",
    defaultValues: {
      quantity: "1",
      isSubstitutionEnabled: false,
      pricePerItemLimit: "",
    },
  })

  // Not using isValid from formState due to issue where it can be false when there are no errors
  // https://github.com/react-hook-form/react-hook-form/issues/2755
  // Can be fixed by upgrading the react-hook-form package
  const isValid = Object.keys(errors).length === 0

  const [quantityInputValue, isSubstitutionEnabled, pricePerItemLimit] = watch([
    "quantity",
    "isSubstitutionEnabled",
    "pricePerItemLimit",
  ])

  const getQuantityInputValueError = (value: string): string | undefined => {
    if (value === "") {
      return undefined
    }

    if (bn(value).isLessThan(1)) {
      return t(
        "trade.fulfillSemiFungibleOrders.quantity.errors.minimum",
        "Quantity must be 1 or more",
      )
    }

    if (bn(value).isNaN()) {
      return t(
        "trade.fulfillSemiFungibleOrders.quantity.errors.number",
        "Quantity must be a number",
      )
    }

    if (assetTotalQuantity && bn(value).isGreaterThan(assetTotalQuantity)) {
      return t(
        "trade.fulfillSemiFungibleOrders.quantity.errors.totalItemQuantity",
        "Quantity cannot exceed total item quantity",
      )
    }

    if (side === "SELL" && bn(value).isGreaterThan(ownedQuantity)) {
      return t(
        "trade.fulfillSemiFungibleOrders.quantity.errors.amountYouOwn",
        "Quantity cannot exceed amount you own",
      )
    }

    return undefined
  }

  const getMaxPricePerItemInputValueError = (
    value: string,
  ): string | undefined => {
    if (!isSubstitutionEnabled) {
      return undefined // do not validate when substitution is not enabled
    }

    if (typeof value === "string" && value.trim() === "") {
      return side === "BUY"
        ? t(
            "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.required.listings",
            "Max price per listing is required",
          )
        : t(
            "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.required.offers",
            "Min value per offer is required",
          )
    }

    if (bn(value).isNaN()) {
      return side === "BUY"
        ? t(
            "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.number.listings",
            "Max price per listing must be a number",
          )
        : t(
            "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.number.offers",
            "Min value per listing must be a number",
          )
    }

    if (bn(value).isLessThan(0)) {
      return t(
        "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.minimum",
        "Enter an amount greater than or equal to 0",
      )
    }

    if (
      !sameSymbolOrders.some(order =>
        side === "BUY"
          ? bn(order.perUnitPriceType.unit).isLessThanOrEqualTo(value)
          : bn(order.perUnitPriceType.unit).isGreaterThanOrEqualTo(value),
      )
    ) {
      return side === "BUY"
        ? t(
            "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.noEligibleOrders.listings",
            "No eligible listings for this max price",
          )
        : t(
            "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.errors.noEligibleOrders.offers",
            "No eligible offers for this min price",
          )
    }

    return undefined
  }

  // Use symbol of first order
  const symbol = orders?.[0] ? orders[0].payment.symbol : undefined

  const sameSymbolOrders = useMemo(
    () =>
      orders?.filter(order => {
        return order.payment.symbol === symbol
      }) ?? [],
    [orders, symbol],
  )

  const ordersWithValidMakerOwnedQuantity =
    useOrdersWithValidMakerOwnedQuantity({ orders: sameSymbolOrders })

  const eligibleOrders = useMemo(
    () =>
      ordersWithValidMakerOwnedQuantity.filter(
        order =>
          !isSubstitutionEnabled ||
          (side === "BUY"
            ? bn(order.perUnitPriceType.unit).isLessThanOrEqualTo(
                pricePerItemLimit,
              )
            : bn(order.perUnitPriceType.unit).isGreaterThanOrEqualTo(
                pricePerItemLimit,
              )),
      ),
    [
      isSubstitutionEnabled,
      pricePerItemLimit,
      ordersWithValidMakerOwnedQuantity,
      side,
    ],
  )

  const eligibleOrdersTotalQuantity = eligibleOrders.reduce(
    (acc, listing) => acc.plus(listing.remainingQuantityType),
    bn(0),
  )

  const assetTotalQuantity = asset ? bn(asset.totalQuantity) : undefined

  const ownedQuantity = asset?.ownedQuantity ? bn(asset.ownedQuantity) : bn(0)

  const maxQuantity = BigNumber.min(
    assetTotalQuantity ?? Number.POSITIVE_INFINITY,
    isSubstitutionEnabled
      ? MAX_ITEMS_TO_FILL_WITH_SUBSTITUTION
      : Number.POSITIVE_INFINITY,
    side === "SELL" ? ownedQuantity : Number.POSITIVE_INFINITY,
  )

  const isQuantityEmpty = quantityInputValue.trim() === ""

  const quantity = isQuantityEmpty
    ? bn(1) // Default to quantity of 1 if input is empty
    : !getQuantityInputValueError(quantityInputValue)
    ? bn(quantityInputValue)
    : undefined

  const maxFulfillQuantity = BigNumber.min(
    eligibleOrdersTotalQuantity, // cannot exceed total quantity of available offers
    side === "SELL"
      ? ownedQuantity // cannot exceed total quantity owned when selling
      : assetTotalQuantity ?? Number.POSITIVE_INFINITY, // cannot exceed total asset quantity when buying
  )
  const fulfillQuantity = quantity
    ? BigNumber.min(quantity.toNumber(), maxFulfillQuantity)
    : undefined
  const fulfillQuantityAsNumber = fulfillQuantity?.toNumber() ?? 1

  const getAddQuantityDisabledReason = (): string | undefined => {
    if (side === "SELL" && quantity?.isEqualTo(ownedQuantity)) {
      return t(
        "trade.fulfillSemiFungibleOrders.quantity.add.amountYouOwn",
        "Quantity cannot exceed amount you own",
      )
    }
    if (assetTotalQuantity && quantity?.isEqualTo(assetTotalQuantity)) {
      return t(
        "trade.fulfillSemiFungibleOrders.quantity.add.totalItemQuantity",
        "Quantity cannot exceed total item quantity",
      )
    }

    return undefined
  }
  const addQuantityDisabledReason = getAddQuantityDisabledReason()
  const isQuantityIncrementable =
    !bn(quantityInputValue).isNaN() || isQuantityEmpty
  const isQuantityDecrementable = !bn(quantityInputValue).isNaN()

  const getFulfillQuantityReason = (): string | undefined => {
    if (side === "SELL" && quantity?.isGreaterThan(ownedQuantity)) {
      return t(
        "trade.fulfillSemiFungibleOrders.fulfillQuantity.ownedQuantity",
        "You own {{displayCount}}",
        { displayCount: display(ownedQuantity) },
        { forceString: true },
      )
    }

    if (quantity?.isGreaterThan(eligibleOrdersTotalQuantity)) {
      return side === "BUY"
        ? t(
            "trade.fulfillSemiFungibleOrders.fulfillQuantity.noMoreOrders.listings",
            {
              "0": "There are no listings available",
              one: "There is only {{displayCount}} listing available",
              other: "There are only {{displayCount}} listings available",
            },
            {
              count: eligibleOrdersTotalQuantity.toNumber(),
              displayCount: display(eligibleOrdersTotalQuantity),
            },
            { forceString: true },
          )
        : t(
            "trade.fulfillSemiFungibleOrders.fulfillQuantity.noMoreOrders.offers",
            {
              "0": "There are no offers available",
              one: "There is only {{displayCount}} offer available",
              other: "There are only {{displayCount}} offers available",
            },
            {
              count: eligibleOrdersTotalQuantity.toNumber(),
              displayCount: display(eligibleOrdersTotalQuantity),
            },
            { forceString: true },
          )
    }

    return undefined
  }
  const fulfillQuantityReason = getFulfillQuantityReason()

  // sets isSubstitutionEnabled to true as a behavior to trigger the isSubstitutionEnabled watch without changing the worstUnitPrice recalculation
  // for the future we could refactor this to remove the isSubstitutionEnabled watch and recalculate worstUnitPrice when pricePerItemLimit changes
  useMountEffect(() => {
    setValue("isSubstitutionEnabled", true)
  })

  useUpdateEffect(() => {
    // set a default pricePerItemLimit based on the worst unit price (highest for listings,
    // lowest for offers) for selected quantity
    const worstUnitPrice = sameSymbolOrders[sameSymbolOrders.length - 1]
      ?.perUnitPriceType.unit as string | undefined
    if (worstUnitPrice !== undefined) {
      setValue("pricePerItemLimit", worstUnitPrice)
    }

    // set focus on pricePerItemLimit when substitution is toggled on
    setFocus("pricePerItemLimit")
    if (touchedFields.pricePerItemLimit) {
      trigger("pricePerItemLimit")
    }
    trigger("quantity")
  }, [isSubstitutionEnabled])

  useUpdateEffect(() => {
    if (eligibleOrders.length) {
      trigger("quantity")
    }
  }, [pricePerItemLimit])

  useUpdateEffect(() => {
    if (
      quantity?.isGreaterThan(eligibleOrdersTotalQuantity) &&
      hasMoreOrders &&
      !isLoadingMoreOrders
    ) {
      loadMoreOrders()
    }
  }, [quantity])

  const orderToQuantity = useMemo(() => {
    return getOrderToQuantity(eligibleOrders, fulfillQuantity)
  }, [eligibleOrders, fulfillQuantity])

  const totalEligibleOrderToQuantity: OrderToQuantity = useMemo(() => {
    return getOrderToQuantity(
      eligibleOrders,
      fulfillQuantity?.plus(MAX_BACKUP_ITEMS_TO_FILL),
    )
  }, [eligibleOrders, fulfillQuantity])

  const { totalPricePerSymbol } = useTotalPrice({
    orders: eligibleOrders,
    orderToQuantity,
  })

  const { totalPricePerSymbol: maxTotalPricePerSymbol } = useTotalPrice({
    orders: eligibleOrders,
    orderToQuantity: totalEligibleOrderToQuantity,
    maxOrdersToFill: isSubstitutionEnabled
      ? fulfillQuantity?.toNumber()
      : undefined,
    shouldSort: false,
  })

  const { totalPricePerSymbol: totalPricePerSymbolForAllEligibleOrders } =
    useTotalPrice({
      orders: eligibleOrders,
      orderToQuantity: totalEligibleOrderToQuantity,
    })

  const totalPrice =
    symbol && isValid && !isQuantityEmpty
      ? totalPricePerSymbol[symbol]
      : undefined

  const totalPriceLimit =
    symbol && isSubstitutionEnabled && !errors.pricePerItemLimit && isValid
      ? maxTotalPricePerSymbol[symbol]
      : undefined

  const totalPriceForAllEligibleOrders = symbol
    ? totalPricePerSymbolForAllEligibleOrders[symbol]
    : undefined

  const averagePriceWithoutSubstitution =
    totalPrice && fulfillQuantity
      ? totalPrice.price.div(fulfillQuantity)
      : undefined

  const averagePriceForAllEligibleOrders = totalPriceForAllEligibleOrders
    ? totalPriceForAllEligibleOrders.price.div(eligibleOrdersTotalQuantity)
    : undefined

  const averagePrice = isSubstitutionEnabled
    ? averagePriceForAllEligibleOrders
    : averagePriceWithoutSubstitution

  const renderQuantitySelector = ({
    onClickAdd,
    onClickRemove,
    overrides,
    ...restProps
  }: Omit<FormControlProps, "label" | "children" | "overrides"> & {
    overrides?: {
      Input?: {
        props: InputProps
      }
      FirstRowContainer?: {
        className?: string
      }
    }
    onClickAdd?: () => unknown
    onClickRemove?: () => unknown
  }) => {
    const quantityInputId = `fulfill-orders-${side}-quantity--${asset?.relayId}`

    return (
      <StyledFormControl
        error={errors.quantity?.message}
        hideLabel
        htmlFor={quantityInputId}
        label={t("trade.fulfillSemiFungibleOrders.quantity.label", "Quantity")}
        overrides={{
          FirstRowContainer: {
            ...overrides?.FirstRowContainer,
          },
        }}
        {...restProps}
      >
        <StyledInput
          endEnhancer={
            <UnstyledButton
              disabled={
                !isQuantityIncrementable ||
                bn(quantityInputValue).isGreaterThanOrEqualTo(maxQuantity)
              }
              onClick={() => {
                onClickAdd?.()
                setValue(
                  "quantity",
                  isQuantityEmpty
                    ? "1"
                    : bn(quantityInputValue).plus(1).toString(),
                  { shouldValidate: true },
                )
              }}
            >
              <Tooltip
                content={addQuantityDisabledReason}
                disabled={!addQuantityDisabledReason}
              >
                <StyledIcon value="add" />
              </Tooltip>
            </UnstyledButton>
          }
          height="60px"
          placeholder="1"
          startEnhancer={
            <UnstyledButton
              disabled={
                !isQuantityDecrementable ||
                bn(quantityInputValue).isLessThanOrEqualTo(1)
              }
              onClick={() => {
                onClickRemove?.()
                setValue(
                  "quantity",
                  bn(quantityInputValue).minus(1).toString(),
                  { shouldValidate: true },
                )
              }}
            >
              <StyledIcon value="remove" />
            </UnstyledButton>
          }
          {...register("quantity", {
            validate: getQuantityInputValueError,
          })}
          id={quantityInputId}
          {...overrides?.Input?.props}
          overrides={merge(overrides?.Input?.props.overrides, {
            Input: {
              style: { textAlign: "center" },
            },
          })}
        />
      </StyledFormControl>
    )
  }

  const renderSubstituteItems = ({ overrides }: SubstituteItemsProps = {}) => {
    const checkboxId = `fulfill-orders-${side}-substitute-items--${asset?.relayId}`
    const pricePerItemLimitRegisterResult = register("pricePerItemLimit", {
      validate: getMaxPricePerItemInputValueError,
    })

    return (
      <Block>
        <Flex
          alignItems="center"
          className="gap-2"
          marginBottom={isSubstitutionEnabled ? "12px" : "8px"}
        >
          <Controller
            control={control}
            name="isSubstitutionEnabled"
            render={({ field }) => (
              <Checkbox
                checked
                disabled
                id={checkboxId}
                name={field.name}
                {...overrides?.Checkbox?.props}
                onCheckedChange={checked => {
                  field.onChange(checked)
                  overrides?.Checkbox?.props.onCheckedChange?.(checked)
                }}
              />
            )}
          />
          <Label htmlFor={checkboxId}>
            <Flex
              alignItems="center"
              className="text-secondary"
              overflow="hidden"
            >
              <Text className="text-secondary" size="small">
                {side === "BUY"
                  ? t(
                      "trade.fulfillSemiFungibleOrders.substituteItems.label.listings",
                      "Substitute listings",
                    )
                  : t(
                      "trade.fulfillSemiFungibleOrders.substituteItems.label.offers",
                      "Substitute offers",
                    )}
              </Text>
              <InfoIcon
                overrides={{
                  Button: {
                    style: { marginLeft: "4px" },
                  },
                  Icon: {
                    size: 18,
                  },
                  Tooltip: {
                    interactive: true,
                  },
                }}
                tooltipContent={
                  side === "BUY"
                    ? t(
                        "trade.fulfillSemiFungibleOrders.moreInfoListings",
                        "Unavailable listings will be substituted with the next cheapest listings below your specified max price.",
                      )
                    : t(
                        "trade.fulfillSemiFungibleOrders.moreInfoOffers",
                        "Unavailable offers will be substituted with the next highest value offers above your specified min value.",
                      )
                }
              />
            </Flex>
          </Label>
        </Flex>
        {isSubstitutionEnabled && (
          <Flex alignItems="center" marginBottom="8px">
            <Label className="mr-3" htmlFor="pricePerItemLimit">
              <Text className="text-secondary" size="small">
                {side === "BUY"
                  ? t(
                      "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.label.listings",
                      "Max price per listing",
                    )
                  : t(
                      "trade.fulfillSemiFungibleOrders.pricePerItemLimitInput.label.offers",
                      "Min value per offer",
                    )}
              </Text>
            </Label>
            <Tooltip
              content={errors.pricePerItemLimit?.message}
              disabled={!errors.pricePerItemLimit?.message}
            >
              <Input
                endEnhancer={symbol}
                error={!!errors.pricePerItemLimit?.message}
                height="48px"
                width="132px"
                {...pricePerItemLimitRegisterResult}
                {...overrides?.Input?.props}
              />
            </Tooltip>
          </Flex>
        )}
      </Block>
    )
  }

  const renderFulfillButton = useCallback(
    ({
      overrides,
      onClick,
    }: Omit<
      SemiFungibleFulfillButtonProps,
      "orderToQuantity" | "orders"
    > = {}) => {
      return (
        <Tooltip
          content={fulfillQuantityReason}
          disabled={!fulfillQuantityReason}
        >
          <Flex {...overrides?.ButtonContainer?.props}>
            {side === "BUY" ? (
              <BuyNowButton
                disabled={!isValid}
                isLoading={isLoadingMoreOrders}
                maxOrdersToFill={
                  isSubstitutionEnabled ? fulfillQuantityAsNumber : undefined
                }
                orderToQuantity={
                  isSubstitutionEnabled
                    ? totalEligibleOrderToQuantity
                    : orderToQuantity
                }
                orders={eligibleOrders}
                overrides={overrides}
                quantity={bn(fulfillQuantityAsNumber)}
                onClick={onClick}
              />
            ) : (
              asset && (
                <AcceptOffersButton
                  asset={asset}
                  disabled={!isValid}
                  isLoading={isLoadingMoreOrders}
                  minTotalPrice={totalPriceLimit}
                  orderToQuantity={
                    isSubstitutionEnabled
                      ? totalEligibleOrderToQuantity
                      : orderToQuantity
                  }
                  orders={eligibleOrders}
                  overrides={overrides}
                  quantity={bn(fulfillQuantityAsNumber)}
                  totalPrice={totalPrice}
                  onClick={onClick}
                />
              )
            )}
          </Flex>
        </Tooltip>
      )
    },
    [
      fulfillQuantityReason,
      side,
      isValid,
      isLoadingMoreOrders,
      isSubstitutionEnabled,
      fulfillQuantityAsNumber,
      totalEligibleOrderToQuantity,
      orderToQuantity,
      eligibleOrders,
      asset,
      totalPriceLimit,
      totalPrice,
    ],
  )

  const renderListingFeesSupportsCreator = useCallback(() => {
    return (
      <ListingFeesSupportsCreator
        marginTop="8px"
        orders={eligibleOrders.filter(o =>
          Object.keys(orderToQuantity).includes(o.relayId),
        )}
      />
    )
  }, [eligibleOrders, orderToQuantity])

  return {
    renderFulfillButton,
    renderQuantitySelector,
    renderSubstituteItems,
    totalPrice,
    totalPriceLimit,
    averagePrice,
    quantity,
    fulfillQuantity,
    isSubstitutionEnabled,
    pricePerItemLimit,
    renderListingFeesSupportsCreator,
  }
}

const StyledIcon = styled(Icon)``

const StyledInput = styled(Input)`
  height: ${props => props.height};

  ${media({
    xs: css`
      width: 100%;
    `,
    lg: css`
      width: 150px;
    `,
  })}

  // TODO: Refers to UnstyledButton. Maybe add UnstyledButton class to DS component?
  button {
    :not(:disabled) {
      ${StyledIcon} {
        ${interactiveStylesPrimary}
      }
    }
    :disabled {
      ${StyledIcon} {
        opacity: 40%;
      }
    }
  }
`

const StyledFormControl = styled(FormControl)`
  align-items: flex-end;

  > ${Flex} {
    width: 100%;
  }
`
