import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { FiatPrice } from "@/components/assets/price/FiatPrice.react"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { Block } from "@/design-system/Block"
import { TotalPricePerSymbol } from "@/features/shopping-cart/hooks/useTotalPrice"

type SemiFungibleTradeStationPriceProps = {
  maxTotalPrice: TotalPricePerSymbol[string]
  totalPrice: NonNullable<TotalPricePerSymbol[string]>
}

export const TradeStationPrice = ({
  maxTotalPrice,
  totalPrice,
}: SemiFungibleTradeStationPriceProps) => {
  return (
    <Block
      className="gap-2"
      display={{ _: maxTotalPrice ? "block" : "flex", sm: "flex" }}
      flexWrap="wrap"
    >
      <Text.Display size="medium">
        <TokenPrice
          price={totalPrice.price}
          symbol={totalPrice.tokenPricePayment.symbol}
        />
      </Text.Display>
      <Flex asChild className="mb-1.5 mr-2 flex-wrap items-end">
        <span>
          <Text.Body className="text-secondary" size="small">
            <FiatPrice price={totalPrice.usdPrice} secondary />
          </Text.Body>
        </span>
      </Flex>
    </Block>
  )
}
