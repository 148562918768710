/* eslint-disable tailwindcss/no-custom-classname */
import React, {
  ComponentProps,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  Icon,
  breakpoints,
  Media,
  useIsLessThanSm,
  useIsLessThanLg,
  useIsLessThanXl,
  UnstyledButton,
  Text,
  Container,
  Spinner,
  SpaceBetween,
  CenterAligned,
  FlexColumn,
  AspectRatio,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import localforage from "localforage"
import _ from "lodash"
import dynamic from "next/dynamic"
import { useFragment, useLazyLoadQuery } from "react-relay"
import styled, { css } from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { getAppInitialProps } from "@/components/app/initialProps"
import { AssetDetails } from "@/components/assets/AssetDetails.react"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { LAST_CLICKED_BEST_ASK_DATA_KEY } from "@/components/assets/localstorage"
import { ItemPriceHistoryChart } from "@/components/assets/PriceHistoryChart/ItemPriceHistoryChart.react"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Count } from "@/components/common/Count.react"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import Loading from "@/components/common/Loading.react"
import { SensitiveContentScreen } from "@/components/common/SensitiveContentScreen"
import { SocialBar } from "@/components/common/SocialBar"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Toolbar } from "@/components/common/Toolbar.react"
import { AssetFavoritedByModal } from "@/components/favorites/AssetFavoritedByModal.react"
import { getMarkdownComponents } from "@/components/layout/AccountOrCollectionPage/components/CollectionHeroBannerV2/components/CollectionHeroBanner/components/CollectionHeroInfo/utils"
import { AccountOrCollectionPageContextProvider } from "@/components/layout/AccountOrCollectionPage/utils/AccountOrCollectionPageContextProvider.react"
import Frame, { FrameProvider } from "@/components/layout/Frame.react"
import { Panel } from "@/components/layout/Panel"
import { PrivateListingBanner } from "@/components/layout/PrivateListingBanner.react"
import { AssetSuccessModalContent } from "@/components/modals/AssetSuccessModalContent.react"
import { CampaignAnnouncementModal } from "@/components/modals/CampaignAnnouncementModal.react"
import { UnlockableContentModal } from "@/components/modals/UnlockableContentModal.react"
import { OrderManager } from "@/components/orders"
import { AssetSearchListOnClickExtra } from "@/components/search/assets/AssetSearchList.react"
import type { AssetSearchListPagination } from "@/components/search/assets/AssetSearchListPagination.react"
import { RarityIndicator, useIsRarityEnabled } from "@/components/search/rarity"
import { RedeemablesIcon } from "@/components/svgs/RedeemablesIcon.react"
import { SemiFungibleTradeStation } from "@/components/trade/SemiFungibleTradeStation/SemiFungibleTradeStation.react"
import { TradeStation } from "@/components/trade/TradeStation.react"
import { Boost } from "@/components/traits/Boost.react"
import { Date as DateComponent } from "@/components/traits/Date.react"
import { NumericTrait } from "@/components/traits/NumericTrait.react"
import { Property } from "@/components/traits/Property.react"
import { CATEGORY_SLUGS, SEO_MAX_DESC_LENGTH } from "@/constants/index"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { useLocationContext } from "@/context/location"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { ChartContextProvider } from "@/design-system/charts/ChartPanel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { Image } from "@/design-system/Image"
import { Lightbox } from "@/design-system/Lightbox"
import { Markdown } from "@/design-system/Markdown"
import { Modal } from "@/design-system/Modal"
import { Tooltip } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { ContentAuthenticity } from "@/features/adobe"
import { useCategoryNameGetter } from "@/features/categorization/collection-edit/categoryUtils"
import { CategoryIcon } from "@/features/categorization/shared/CategoryIcon.react"
import { CollectionInspiredBy } from "@/features/collections/components/CollectionPage/components/CollectionInspiredBy"
import {
  AssetPageListingSubscription,
  AssetPageListingUpdatedSubscription,
  AssetPageOfferSubscription,
  AssetPageOfferUpdatedSubscription,
} from "@/features/live-updates/components/AssetPageSubscriptions/AssetPageSubscriptions.react"
import { ItemOwnersModal } from "@/features/owners/components/ItemOwnersModal.react"
import { RedeemableItemCard } from "@/features/redeemables/components/RedeemableItemCard"
import { OpenSeaHead } from "@/features/seo"
import { TwitterMetadata } from "@/features/seo/components/Head"
import { PageLinkedDataJson } from "@/features/seo/components/Head/useLinkedDataJson"
import { useIsRedeemPageEnabled } from "@/hooks/useFlag"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { getTrackingFn } from "@/lib/analytics"
import {
  readAsset,
  trackNavigateToSimilarItems,
} from "@/lib/analytics/events/itemEvents"
import { ItemTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ItemTrackingContext.react"
import { PageTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/PageTrackingContext/PageTrackingContext.react"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import { AssetPage_data$key } from "@/lib/graphql/__generated__/AssetPage_data.graphql"
import { AssetPageCollectionDelistedQuery } from "@/lib/graphql/__generated__/AssetPageCollectionDelistedQuery.graphql"
import { AssetPageQuery as AssetPageQueryT } from "@/lib/graphql/__generated__/AssetPageQuery.graphql"
import { AssetPageRedeemablesSectionQuery } from "@/lib/graphql/__generated__/AssetPageRedeemablesSectionQuery.graphql"
import { AssetPageTraitFloorPricesQuery } from "@/lib/graphql/__generated__/AssetPageTraitFloorPricesQuery.graphql"
import { ItemCard_data$data } from "@/lib/graphql/__generated__/ItemCard_data.graphql"
import { clearCache } from "@/lib/graphql/environment/middlewares/cacheMiddleware"
import {
  fetch,
  getNodes,
  graphql,
  GraphQLInitialProps,
} from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import { getAssetDisplayName, getAssetUrl } from "@/lib/helpers/asset"
import { useReportAssetVisitor } from "@/lib/helpers/assets"
import { pollEnglishAuction } from "@/lib/helpers/auctions"
import { bn } from "@/lib/helpers/numberUtils"
import { largeFrozenImage } from "@/lib/helpers/urls"
import QP from "@/lib/qp/qp"
import { $nav_height, NAV_HEIGHT_PX } from "@/styles/variables"
import { AssetActivityPanel } from "./components/AssetActivityPanel/AssetActivityPanel.react"
import { AssetPageMediaHeader } from "./components/AssetPageMediaHeader"
import { ListingsPanel } from "./components/ListingsPanel"
import { OffersPanel } from "./components/OffersPanel"
import { ItemOrdersBaseProps } from "./components/types"

export const AssetPageFragment = graphql`
  fragment AssetPage_data on Query
  @argumentDefinitions(
    tokenId: { type: "String!" }
    contractAddress: { type: "AddressScalar!" }
    chain: { type: "ChainScalar!" }
  ) {
    nft(tokenId: $tokenId, contractAddress: $contractAddress, chain: $chain) {
      ...AssetPageMediaHeader_item
      ...AssetPageMediaHeader__accountInfo
        @arguments(identity: {}, showQuantity: false)
      ...asset_display_name
      ...ContentAuthenticity_data
      assetContract {
        address
        chain
        ...CollectionLink_assetContract
      }
      creator {
        address
        user {
          publicUsername
        }
        displayName
        ...AccountLink_data
      }
      animationUrl
      backgroundColor
      collection {
        description
        isSensitiveContent
        displayData {
          cardDisplayStyle
        }
        category {
          slug
        }
        hidden
        imageUrl
        name
        slug
        ...CollectionLink_collection
        ...Boost_collection
        ...Property_collection
        ...NumericTrait_collection
        ...SocialBar_data
        ...useIsLiveUpdatesEnabledForCollection_collection
        ...useIsRarityEnabled_collection
        ...CollectionInspiredBy_data
      }
      decimals
      description
      imageUrl
      name
      numVisitors
      isDelisted
      isListable
      isReportedSuspicious
      isSensitiveContent
      isUnderReview
      isCompromised
      isOwnershipDisputed
      isBiddingEnabled {
        value
        reason
      }
      relayId
      tokenId
      hasUnlockableContent
      favoritesCount
      tradeSummary {
        bestAsk {
          closedAt
          orderType
          priceType {
            usd
          }
          maker {
            ...wallet_accountKey
          }
          relayId
          ...PrivateListingBanner_data
        }
        bestBid {
          __typename
        }
        ...TradeStation_data
      }
      acceptHighestOffer: tradeSummary(excludeAccountAsMaker: true) {
        ...TradeStation_acceptHighestOffer
      }
      traits(first: 100) {
        edges {
          node {
            relayId
            displayType
            floatValue
            intValue
            traitType
            value
            ...Boost_trait
            ...Property_trait
            ...NumericTrait_trait
            ...Date_trait
          }
        }
      }
      defaultRarityData {
        ...RarityIndicator_data
      }
      ...AssetMedia_asset
      ...Toolbar_asset
      ...asset_url
      ...itemEvents_data
      ...AssetDetails_data
      # Will need a more appropriate limit and pagination.
      ownedQuantity(identity: {})
      numOwners
      largestOwner {
        quantity
        owner {
          ...AccountLink_data
        }
      }
      totalQuantity
      isCurrentlyFungible
      ...RedeemableItemCard_itemToBurn
      ...TradeStation_archetype
      ...OffersPanel_asset
      ...ListingsPanel_asset
      ...SemiFungibleTradeStation_asset
      ...OrderManager_item
      ...ItemTrackingContext_item
      activity(first: 11) {
        edges {
          node {
            __typename
          }
        }
      }
    }
    ...SemiFungibleTradeStation_bestListings
      @arguments(
        tokenId: $tokenId
        contractAddress: $contractAddress
        chain: $chain
      )
    ...SemiFungibleTradeStation_bestOffers
      @arguments(
        tokenId: $tokenId
        contractAddress: $contractAddress
        chain: $chain
      )
    ...CampaignAnnouncementModal_data
  }
`

const AsyncAssetSearchListPagination = dynamic<
  ComponentProps<typeof AssetSearchListPagination>
>(
  () =>
    import(
      "../../../../components/search/assets/AssetSearchListPagination.react"
    ).then(mod => mod.AssetSearchListPagination),
  {
    loading: () => (
      <CenterAligned className="h-[400px]">
        <Loading />
      </CenterAligned>
    ),
    ssr: false,
  },
)

const trackAssetSpotlightClicked = getTrackingFn<AssetPageQueryT["variables"]>(
  "asset spotlight clicked",
)

const trackAssetSpolightLoadFailed = getTrackingFn<
  AssetPageQueryT["variables"] & {
    imageSrc: string
  }
>("asset spotlight load failed")

export type AssetPageProps = {
  showCreatedModal?: boolean
}

export const AssetPage: GraphQLNextPage<AssetPageQueryT, AssetPageProps> = ({
  showCreatedModal,
  variables: { contractAddress, tokenId, chain },
  data: dataKey,
  refetch,
}) => {
  const reportAssetVisitor = useReportAssetVisitor()
  const data = useFragment<AssetPage_data$key>(AssetPageFragment, dataKey)
  const t = useTranslate("assets")
  const router = useRouter()
  const activeAccount = useActiveAccount()
  const listingsRef = useRef<HTMLDivElement>(null)
  const [isReviewingListings, setIsReviewingListings] = useState(false)
  const [isSensitivityFilterVisible, setIsSensitivityFilterVisible] =
    useState<boolean>(
      !!(
        data?.nft.collection.isSensitiveContent || data?.nft.isSensitiveContent
      ),
    )
  const isRarityDisplayed = useIsRarityEnabled(data?.nft.collection ?? null)
  const { origin } = useLocationContext()
  const isSmallScreen = useIsLessThanSm()
  const isLessThanLg = useIsLessThanLg()
  const isLessThanXl = useIsLessThanXl()
  const categoryNameGetter = useCategoryNameGetter()

  const asset = data?.nft ?? null
  const slug = asset?.collection.slug
  const isDelisted = asset?.isDelisted
  const isFungible = asset?.totalQuantity !== "1"
  const ownedQuantity = asset?.ownedQuantity
    ? bn(asset.ownedQuantity, asset.decimals)
    : bn(0)

  const isOwner = ownedQuantity.gt(0)

  const rarityData = asset?.defaultRarityData

  const hasOffers = Boolean(data?.nft.tradeSummary.bestBid)

  const scrollToListings = () => {
    setIsReviewingListings(true)
  }

  const openAssetModal = (open: () => void) => () => {
    trackAssetSpotlightClicked({
      contractAddress,
      tokenId,
      chain,
    })
    open()
  }

  const handleAssetLoadError = (
    event: React.SyntheticEvent<HTMLImageElement>,
  ) => {
    trackAssetSpolightLoadFailed({
      contractAddress,
      tokenId,
      chain,
      imageSrc: event.currentTarget.src,
    })
  }

  useEffect(() => {
    setIsSensitivityFilterVisible(
      !!data?.nft.isSensitiveContent ||
        !!data?.nft.collection.isSensitiveContent,
    )
  }, [data?.nft.isSensitiveContent, data?.nft.collection.isSensitiveContent])

  useEffect(() => {
    if (isReviewingListings) {
      const top =
        (listingsRef.current?.getBoundingClientRect().top || 0) -
        (NAV_HEIGHT_PX * 2 + 16) // Navbar height + order manager height + extra spacing
      window.scrollTo({ top, behavior: "smooth" })
    }
  }, [isReviewingListings])

  const toggleIsReviewingListings = () => {
    setIsReviewingListings(prev => !prev)
  }

  useEffect(() => {
    // Check if the asset is delisted because of ip rights violation
    const run = async () => {
      const query = graphql`
        query AssetPageCollectionDelistedQuery(
          $tokenId: String!
          $contractAddress: AddressScalar!
          $chain: ChainScalar!
        ) {
          nft(
            tokenId: $tokenId
            contractAddress: $contractAddress
            chain: $chain
          ) {
            collection {
              isIpRightsTakedownDelisted
            }
          }
        }
      `

      const [data] = await fetch<AssetPageCollectionDelistedQuery>(query, {
        contractAddress,
        tokenId,
        chain,
      })

      if (data.nft.collection.isIpRightsTakedownDelisted) {
        if (isOwner) {
          await router.replace({
            pathname: "/",
            query: {
              show_ip_rights_delisted_notice: "item-owner",
            },
          })
        } else {
          await router.replace({
            pathname: "/",
            query: {
              show_ip_rights_delisted_notice: "item",
            },
          })
        }
      } else {
        // Asset delisted for reason unrelated to ip rights
        await router.replace({
          pathname: "/",
          query: { show_delisted_notice: true },
        })
      }
    }

    if (isDelisted) {
      run()
    }

    // Don't re-run when route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelisted, chain, contractAddress, tokenId, isOwner])

  useEffect(() => {
    if (asset) {
      reportAssetVisitor(asset.relayId)
    }
  }, [asset, reportAssetVisitor])

  useEffect(() => {
    if (showCreatedModal) {
      router.updateQuery({ created: undefined })
    }
    // Don't re-run when route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCreatedModal])

  useMountEffect(() => {
    const bestAsk = data?.nft.tradeSummary.bestAsk
    if (bestAsk?.orderType === "ENGLISH" && bestAsk.closedAt) {
      pollEnglishAuction(bestAsk.closedAt, refetch)
    }

    return () => {
      localforage.removeItem(LAST_CLICKED_BEST_ASK_DATA_KEY)
    }
  })

  const [traitsWithFloorPrices] =
    useNoSuspenseLazyLoadQuery<AssetPageTraitFloorPricesQuery>(
      graphql`
        query AssetPageTraitFloorPricesQuery(
          $contractAddress: AddressScalar!
          $tokenId: String!
          $chain: ChainScalar!
        ) {
          nft(
            contractAddress: $contractAddress
            tokenId: $tokenId
            chain: $chain
          ) {
            traits(first: 100) {
              edges {
                node {
                  relayId
                  ...Property_traitWithFloorPrice
                }
              }
            }
          }
        }
      `,
      {
        contractAddress,
        tokenId,
        chain,
      },
    )

  const relayIdToTraitWithFloorPrices = useMemo(
    () =>
      _.keyBy(
        getNodes(traitsWithFloorPrices?.nft.traits),
        trait => trait.relayId,
      ),
    [traitsWithFloorPrices],
  )

  const traitNodes = useMemo(
    () =>
      _.sortBy(
        getNodes(asset?.traits),
        // TODO(marcel): remove this once we figure out why it's sometimes null
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        node => node.traitType?.toUpperCase(),
        node => (node.value ? node.value : undefined),
      ),
    [asset],
  )

  const renderImage = () => {
    // asset must exist
    if (!asset) {
      return null
    }

    return (
      <Frame as="article" className="item--frame item--media-frame">
        <AssetPageMediaHeader accountInfo={asset} item={asset} />
        <Lightbox
          overrides={
            asset.animationUrl
              ? {
                  Dialog: {
                    props: {
                      style: {
                        height: "min(100vw, 100vh)",
                        width: "min(100vw, 100vh)",
                      },
                    },
                  },
                }
              : isSmallScreen
              ? {
                  Dialog: {
                    props: {
                      style: { height: "100%", width: "100vw" },
                    },
                  },
                }
              : undefined
          }
          trigger={open => (
            <AspectRatio ratio={1 / 1}>
              <Block className="absolute inset-0">
                <Block
                  className="w-full"
                  height="100%"
                  onClick={openAssetModal(open)}
                >
                  <AssetMedia
                    asset={asset}
                    autoPlay
                    className="item--media"
                    isMuted
                    mediaStyles={{
                      // This should always be contain now due to us using a fixed
                      // square container
                      objectFit: "contain",
                      borderRadius: "initial",
                    }}
                    priority
                    rawImage
                    showControls
                    showModel
                    width={1000}
                  />
                </Block>
              </Block>
            </AspectRatio>
          )}
        >
          <AssetMedia
            asset={asset}
            autoPlay
            objectFit="contain"
            showControls
            showModel
            sizes="100%"
            width={2000}
            onError={handleAssetLoadError}
          />
        </Lightbox>
      </Frame>
    )
  }

  const renderSummary = () => {
    const asset = data?.nft
    if (!asset) {
      return null
    }
    const { collection, creator } = asset
    const numericTraitNodes = traitNodes.filter(
      t => t.floatValue !== null || t.intValue !== null,
    )
    const collectionCategory = collection.category

    const properties = traitNodes
      .filter(t => t.value)
      .map(trait => (
        <Grid.Item key={trait.relayId} lg={4} sm={6}>
          <Property
            className="item--property"
            collection={collection}
            disablePercentages={isFungible}
            key={trait.relayId}
            trait={trait}
            traitWithFloorPrice={relayIdToTraitWithFloorPrices[trait.relayId]}
          />
        </Grid.Item>
      ))

    const stats = numericTraitNodes
      .filter(t => t.displayType === "NUMBER")
      .map(trait => (
        <NumericTrait
          className="item--numeric-trait"
          collection={collection}
          key={trait.relayId}
          trait={trait}
        />
      ))

    const levels = numericTraitNodes
      .filter(t => !t.displayType)
      .map(trait => (
        <NumericTrait
          className="item--numeric-trait"
          collection={collection}
          key={trait.relayId}
          rankingMode
          trait={trait}
        />
      ))

    const boosts = numericTraitNodes
      .filter(t => t.displayType?.startsWith("BOOST"))
      .map(trait => (
        <Boost
          className="item--boost"
          collection={collection}
          key={trait.relayId}
          trait={trait}
        />
      ))

    const dates = numericTraitNodes
      .filter(t => t.displayType === "DATE")
      .map(trait => (
        <DateComponent
          className="item--numeric-trait"
          key={trait.relayId}
          trait={trait}
        />
      ))

    return (
      <Frame className="item--frame item--summary-frame">
        <FrameProvider>
          {asset.description || creator ? (
            <Panel
              bodyClassName="item--description"
              className="item--description-panel"
              icon="subject"
              id="assets-item-description"
              maxHeight={200}
              mode="always-open"
              title={t("description.title", "Description")}
            >
              {creator ? (
                <section className="item--creator">
                  <AccountLink
                    className="item--creator-account"
                    dataKey={creator}
                    iconSize={32}
                    tab="created"
                    tooltipPlacement="top-start"
                    variant="no-image"
                  />
                </section>
              ) : null}
              {asset.description ? (
                <FlexColumn className="item--description-text">
                  <Markdown components={getMarkdownComponents()}>
                    {asset.description}
                  </Markdown>
                </FlexColumn>
              ) : null}
            </Panel>
          ) : null}
          {properties.length ? (
            <Panel
              bodyClassName="item--properties"
              icon="label"
              id="assets-item-properties"
              mode="start-open"
              title={t("traits.title", "Traits")}
            >
              <StyledGrid className="w-full">{properties}</StyledGrid>
            </Panel>
          ) : null}
          {stats.length ? (
            <Panel
              bodyClassName="item--numeric-traits"
              icon="equalizer"
              id="assets-item-numeric-traits-1"
              title={t("stats.title", "Stats")}
            >
              {stats}
            </Panel>
          ) : null}
          {levels.length ? (
            <Panel
              bodyClassName="item--numeric-traits"
              icon="stars"
              id="assets-item-numeric-traits-2"
              title={t("levels.title", "Levels")}
            >
              {levels}
            </Panel>
          ) : null}
          {boosts.length ? (
            <Panel
              bodyClassName="item--boosts"
              icon="flash_on"
              id="assets-item-numeric-traits-3"
              title={t("boosts.title", "Boosts")}
            >
              {boosts}
            </Panel>
          ) : null}
          {dates.length ? (
            <Panel
              bodyClassName="item--numeric-traits"
              icon="calendar_today"
              id="assets-item-numeric-traits-4"
              title={t("dates.title", "Dates")}
            >
              {dates}
            </Panel>
          ) : null}

          <Panel
            icon="vertical_split"
            id="assets-item-about-collection"
            title={
              <Text.Body asChild size="medium" weight="semibold">
                <h3>
                  {t("about.title", "About {{name}}", {
                    name: collection.name,
                  })}
                </h3>
              </Text.Body>
            }
          >
            <div className="item--about-container">
              <Flex className="items-start">
                {collection.imageUrl && (
                  <ImageContainer>
                    <Link href={`/collection/${collection.slug}`}>
                      <StyledImage
                        alt={`${collection.name} collection image`}
                        height={80}
                        objectFit="cover"
                        src={collection.imageUrl}
                        width={80}
                      />
                    </Link>
                  </ImageContainer>
                )}
                <Flex className="flex-col">
                  <Markdown>
                    {collection.description ??
                      t(
                        "about.defaultDescriptionv2",
                        "This collection has no description yet.",
                      )}
                  </Markdown>
                  {collectionCategory?.slug && (
                    <Text.Body>
                      {t(
                        "collections.descriptionMetadata.category",
                        "Category {{category}}",
                        {
                          category: (
                            <Text.Body weight="semibold">
                              {categoryNameGetter(collectionCategory.slug)}
                            </Text.Body>
                          ),
                        },
                      )}
                    </Text.Body>
                  )}
                </Flex>
              </Flex>
            </div>
            <Flex className="mt-6">
              <SocialBar className="justify-start" data={collection} />
            </Flex>
          </Panel>

          <Panel
            icon="ballot"
            id="assets-item-asset-details"
            title={t("details.title", "Details")}
          >
            <AssetDetails data={asset} />
          </Panel>

          <ContentAuthenticity dataKey={asset} />
        </FrameProvider>
      </Frame>
    )
  }

  const renderHeader = () => {
    if (!asset) {
      return null
    }

    const { collection } = asset
    const assetDisplayName = getAssetDisplayName(asset)
    const characterCount = isSmallScreen
      ? 15
      : isLessThanLg
      ? 20
      : isLessThanXl
      ? 15
      : 20

    return (
      <section className="item--header">
        <div className="item--collection-info">
          <div className="item--collection-detail">
            <Flex className="flex-col">
              <CollectionLink
                assetContract={asset.assetContract}
                collection={collection}
                withTooltip
              />
              <CollectionInspiredBy
                alwaysTruncate
                dataKey={collection}
                maintainSize
                shouldUseDefaultLinkStyle
                truncateChars={characterCount}
              />
            </Flex>
          </div>
          <div className="item--collection-toolbar-wrapper">
            <Toolbar
              asset={asset}
              hideCreateDeal={!asset.isListable || isOwner}
              hideTransfer={!activeAccount || !asset.isListable || !isOwner}
            />
          </div>
        </div>
        <Flex className="items-center">
          <Block
            as="h1"
            className="item--title"
            marginRight="20px"
            title={assetDisplayName}
          >
            {assetDisplayName}
            {asset.isCompromised ? (
              <Tooltip
                content={t(
                  "asset.tooltip.compromised",
                  "This item can't be bought or sold due to suspicious activity.",
                )}
              >
                <Icon className="text-red-2 pl-2" fill={1} value="warning" />
              </Tooltip>
            ) : asset.isUnderReview ? (
              <Tooltip
                content={t(
                  "asset.tooltip.underReview",
                  "This item is under review for suspicious activity and can't be bought or sold right now.",
                )}
              >
                <Icon
                  className="text-yellow-1 pl-2"
                  fill={1}
                  title=""
                  value="warning"
                />
              </Tooltip>
            ) : asset.isOwnershipDisputed ? (
              <Tooltip
                content={t(
                  "asset.tooltip.disputed",
                  "The ownership of this item is disputed.",
                )}
              >
                <Icon className="text-yellow-2 pl-2" fill={1} value="warning" />
              </Tooltip>
            ) : null}
          </Block>
        </Flex>
        {isSinglyOwned && renderOwner()}
      </section>
    )
  }

  const renderRarityAndCounts = () => {
    const asset = data?.nft
    if (!asset) {
      return null
    }
    const decimals = asset.decimals ?? 0
    const totalCount = bn(asset.totalQuantity, decimals)
    const viewsCount = bn(asset.numVisitors)
    const ownershipCount = bn(asset.numOwners)
    const favoritesCount = bn(asset.favoritesCount)
    const category = asset.collection.category

    return (
      <section className="item--counts">
        {isRarityDisplayed && rarityData && (
          <Flex className="mr-6">
            <RarityIndicator dataKey={rarityData} />
          </Flex>
        )}

        {isFungible && (
          <>
            <ItemOwnersModal
              assetId={asset.relayId}
              numOwners={ownershipCount.toNumber()}
              trigger={open => (
                <Count
                  count={bn(ownershipCount)}
                  icon="group"
                  options={{
                    unit: "owner",
                    onClick: open,
                    "aria-label": "Owners",
                  }}
                />
              )}
            />
            <Count
              count={totalCount}
              icon="view_module"
              options={{
                unit: "items",
                pluralize: false,
              }}
            />
          </>
        )}

        <Count
          count={viewsCount}
          icon="visibility"
          options={{
            unit: "view",
          }}
        />

        <Modal
          trigger={open => (
            <Count
              count={favoritesCount}
              icon="favorite"
              options={{
                unit: "favorite",
                onClick: open,
                "aria-label": "Favorited by",
              }}
            />
          )}
        >
          <AssetFavoritedByModal
            assetId={asset.relayId}
            numFavorites={favoritesCount.toNumber()}
          />
        </Modal>

        {category && (
          <CategoryContainer
            onClick={
              CATEGORY_SLUGS.includes(category.slug)
                ? () => router.push(`/category/${category.slug}`)
                : undefined
            }
          >
            <CategoryIcon />
            <Text.Body size="small">
              {categoryNameGetter(category.slug)}
            </Text.Body>
          </CategoryContainer>
        )}
      </section>
    )
  }

  const renderOwner = () => {
    const asset = data?.nft
    if (!asset) {
      return null
    }

    const ownership = asset.largestOwner
    const decimals = asset.decimals ?? 0

    return ownership ? (
      <Block marginTop="4px">
        <AccountLink
          dataKey={ownership.owner}
          fontWeight={400}
          isOwner
          ownedQuantity={
            isFungible ? bn(ownership.quantity, decimals) : undefined
          }
          testId="ItemOwnerAccountLink"
          tooltipPlacement="top-start"
          variant="no-image"
        />
      </Block>
    ) : null
  }

  const renderPriceHistory = ({ isSmall }: { isSmall: boolean }) => {
    return (
      <div className="item--frame">
        <Panel
          icon="timeline"
          id="assets-item-timeline"
          mode={isSmall ? "start-closed" : "start-open"}
          title={t("priceHistory.title", "Price History")}
        >
          <Block paddingTop="16px">
            <ChartContextProvider>
              <ItemPriceHistoryChart
                archetype={{
                  tokenId,
                  chain,
                  assetContractAddress: contractAddress,
                }}
                bucketSize="DAY"
                height={145}
                interactive={false}
              />
            </ChartContextProvider>
          </Block>
        </Panel>
      </div>
    )
  }

  const renderOrders = () => {
    const asset = data?.nft
    if (!asset) {
      return null
    }

    const panelProps: ItemOrdersBaseProps = {
      assetContractAddress: contractAddress,
      chain,
      isFungible,
      tokenId,
    }

    return (
      <>
        <SourceTrackingContextProvider source="Listings">
          <ListingsPanel
            {...panelProps}
            asset={asset}
            isReviewing={isReviewingListings}
            ref={listingsRef}
            toggleReviewingStatus={toggleIsReviewingListings}
          />
        </SourceTrackingContextProvider>
        <SourceTrackingContextProvider source="Offers">
          <OffersPanel asset={asset} {...panelProps} hasOffers={hasOffers} />
        </SourceTrackingContextProvider>
      </>
    )
  }

  const renderTradeStation = useCallback(() => {
    if (!data) {
      return null
    }

    if (data.nft.isCurrentlyFungible) {
      return (
        <div className="item--frame">
          <SemiFungibleTradeStation
            asset={data.nft}
            bestListings={data}
            bestOffers={data}
          />
        </div>
      )
    }

    return (
      <SsrSuspense fallback={null}>
        <div className="item--frame">
          <TradeStation
            acceptHighestOffer={data.nft.acceptHighestOffer}
            archetypeData={data.nft}
            data={data.nft.tradeSummary}
            onOrdersChanged={() => {
              clearCache()
              refetch()
            }}
          />
        </div>
      </SsrSuspense>
    )
  }, [data, refetch])

  const renderRedeemables = () => {
    if (!asset) {
      return null
    }

    return (
      <ErrorBoundary fallback={() => <></>}>
        <Suspense fallback={null}>
          <AssetPageRedeemablesSection assetId={asset.relayId} />
        </Suspense>
      </ErrorBoundary>
    )
  }

  const renderEventHistory = () => {
    const assetEventCount = data?.nft.activity.edges.length ?? 0

    return (
      <div className="item--frame item--trading-history">
        <AssetActivityPanel
          chain={chain}
          contractAddress={contractAddress}
          defaultEventTypeFilters={
            assetEventCount > 10 ? ["AUCTION_SUCCESSFUL", "ASSET_TRANSFER"] : []
          }
          mode={isFungible ? "fungible" : "nonfungible"}
          tokenId={tokenId}
        />
      </div>
    )
  }

  const renderSimilarItems = () => {
    if (!data) {
      return null
    }
    const collection = data.nft.collection.slug
    const collections = collection ? [collection] : []
    const exclude = data.nft.relayId ? [data.nft.relayId] : undefined
    const onClick = (
      similarItem: ItemCard_data$data | null,
      { index }: AssetSearchListOnClickExtra,
    ) => {
      if (!similarItem) {
        return
      }
      trackNavigateToSimilarItems(data.nft, {
        similarItem: readAsset(similarItem),
        index,
      })
    }

    return (
      <div className="item--frame item--frame-similar-items">
        <Panel
          FooterButton={
            <CenterAligned className="p-2">
              <Button href={`/collection/${collection}`} variant="secondary">
                View collection
              </Button>
            </CenterAligned>
          }
          icon="view_module"
          id="assets-item-more-items"
          isContentPadded={false}
          mode="start-open"
          title={t("more.title", "More From This Collection")}
        >
          <Block paddingBottom="8px" paddingTop="8px">
            <AsyncAssetSearchListPagination
              exclude={exclude}
              showCollectionName={false}
              singlePage
              variables={{
                count: 10,
                collections,
                resultModel: "ASSETS",
              }}
              variant="horizontal"
              onClick={onClick}
            />
          </Block>
        </Panel>
      </div>
    )
  }

  const renderOrderManager = () => {
    if (!data || !asset || !isOwner) {
      return null
    }

    return asset.isListable ? (
      <OrderManager
        item={asset}
        onOrdersChanged={() => {
          clearCache()
          refetch()
        }}
        onReviewListings={scrollToListings}
      />
    ) : null
  }

  const renderCreatedModal = () => {
    const assetID = data?.nft.relayId

    return (
      <Modal initiallyOpen={showCreatedModal} trigger={() => <></>}>
        {assetID && (
          <AssetSuccessModalContent
            mode="created"
            variables={{ assetIDs: [assetID] }}
          />
        )}
      </Modal>
    )
  }

  const renderPrivateListingBanner = () => {
    const bestAsk = data?.nft.tradeSummary.bestAsk
    if (isFungible || !bestAsk) {
      return null
    }

    return <PrivateListingBanner dataKey={bestAsk} />
  }

  const renderSuspiciousItemAlert = () => {
    if (!data?.nft.isReportedSuspicious) {
      return null
    }
    return (
      <Alert className="mb-4 lg:mb-2 lg:mr-5 lg:mt-5">
        <SpaceBetween className="items-center p-1">
          {/* A fake div with the icon width to center the middle section on desktop. */}
          <Block width={{ lg: "24px" }} />
          <Flex className="items-center">
            <Icon className="text-red-2" fill={1} value="warning" />
            <Text className="mx-2" weight="semibold">
              {t(
                "suspiciousAlert.reported",
                "Reported for suspicious activity",
              )}
            </Text>
          </Flex>
          <InfoIcon
            overrides={{
              Icon: {
                className: "text-red-2",
                size: 24,
              },
            }}
            tooltipContent={t(
              "suspiciousAlert.transactingDisabled",
              "Buying and selling this item has been disabled on OpenSea.",
            )}
          />
        </SpaceBetween>
      </Alert>
    )
  }

  const renderTrading = () => {
    return <div>{renderOrders()}</div>
  }

  const renderUnlockableContent = () => {
    if (!data?.nft.hasUnlockableContent) {
      return null
    }

    return (
      <div className="item--frame">
        <UnlockableContentModal
          variables={{
            assetId: data.nft.relayId,
            isOwner,
          }}
        />
      </div>
    )
  }

  const imageUrl = asset?.imageUrl ?? asset?.collection.imageUrl
  const isSinglyOwned = asset?.numOwners === 1
  const bestAskType = data?.nft.tradeSummary.bestAsk?.orderType

  const twitterMetadata: TwitterMetadata = {
    title: `${asset?.name ?? asset?.tokenId} - ${asset?.collection.name}`,
    image: imageUrl ? largeFrozenImage(imageUrl) : "",
    imageAlt: asset?.name ?? "",
    author:
      asset?.creator?.user?.publicUsername ??
      asset?.creator?.displayName ??
      asset?.creator?.address ??
      "",
    url: asset ? `${origin}${getAssetUrl(asset)}` : "",
  }

  const linkedDataSchema: PageLinkedDataJson = {
    "@type": "Product",
    name: `${asset?.name ?? asset?.tokenId}`,
    image: imageUrl ? largeFrozenImage(imageUrl) : "",
    description: asset?.description ?? "",
    brand: {
      "@type": "Brand",
      name: `${asset?.collection.name ?? ""}`,
    },
    offers: asset?.tradeSummary.bestAsk?.priceType
      ? {
          "@type": "Offer",
          price: bn(asset.tradeSummary.bestAsk.priceType.usd, 0).toFixed(2),
          priceCurrency: "USD",
          priceValidUntil: asset.tradeSummary.bestAsk.closedAt ?? undefined,
          url: `${origin}${
            router.locale ? `/${router.locale}` : ""
          }${getAssetUrl(asset)}`,
        }
      : undefined,
  }

  return (
    <ItemTrackingContextProvider item={asset}>
      <PageTrackingContextProvider data={data} name="Asset">
        <AccountOrCollectionPageContextProvider>
          <OpenSeaPage
            announcementBannerProps={{ displayContext: "CHAIN", chain }}
          >
            {isSensitivityFilterVisible && (
              <SensitiveContentScreen
                handleOnClick={() => setIsSensitivityFilterVisible(false)}
              />
            )}
            {asset && !isDelisted && (
              <OpenSeaHead
                description={
                  asset.description?.substring(0, SEO_MAX_DESC_LENGTH) ||
                  asset.collection.description ||
                  t("pageDescription", "View item history and listings")
                }
                image={imageUrl ? largeFrozenImage(imageUrl) : undefined}
                linkedDataSchema={linkedDataSchema}
                title={`${asset.name || asset.tokenId} - ${
                  asset.collection.name
                } | OpenSea`}
                twitterMetadata={twitterMetadata}
              />
            )}

            {data && !isDelisted ? (
              <DivContainer
                $isSensitivityFilterVisible={isSensitivityFilterVisible}
              >
                {slug && (
                  <>
                    <AssetPageListingSubscription
                      chain={chain}
                      contractAddress={contractAddress}
                      relayId={asset.relayId}
                      slug={slug}
                      tokenId={tokenId}
                    />
                    <AssetPageListingUpdatedSubscription
                      chain={chain}
                      contractAddress={contractAddress}
                      relayId={asset.relayId}
                      slug={slug}
                      tokenId={tokenId}
                    />
                    <AssetPageOfferSubscription
                      chain={chain}
                      contractAddress={contractAddress}
                      relayId={asset.relayId}
                      slug={slug}
                      tokenId={tokenId}
                    />
                    <AssetPageOfferUpdatedSubscription
                      chain={chain}
                      contractAddress={contractAddress}
                      relayId={asset.relayId}
                      slug={slug}
                      tokenId={tokenId}
                    />
                  </>
                )}
                {renderPrivateListingBanner()}
                {renderOrderManager()}
                {renderCreatedModal()}
                <div className="item--container">
                  {renderSuspiciousItemAlert()}

                  <Media greaterThanOrEqual="lg">
                    <div
                      /* TODO: Find a better way of identifying divs that are only used for tests. */
                      className="item--large"
                    >
                      <div className="item--wrapper">
                        <div className="item--summary">
                          {renderImage()}
                          {renderSummary()}
                        </div>
                        <div className="item--main">
                          {renderHeader()}
                          {renderRarityAndCounts()}
                          {renderUnlockableContent()}
                          {renderTradeStation()}
                          {renderRedeemables()}
                          {bestAskType === "ENGLISH" ? (
                            <>
                              {renderTrading()}
                              {renderPriceHistory({ isSmall: false })}
                            </>
                          ) : (
                            <>
                              {renderPriceHistory({ isSmall: false })}
                              {renderTrading()}
                            </>
                          )}
                        </div>
                      </div>
                      {renderEventHistory()}
                      {renderSimilarItems()}
                    </div>
                  </Media>

                  <Media lessThan="lg">
                    <div className="item--small">
                      {renderHeader()}
                      {renderImage()}
                      {renderRarityAndCounts()}
                      {renderUnlockableContent()}
                      {renderTradeStation()}
                      {renderRedeemables()}
                      {bestAskType === "ENGLISH" ? (
                        <>
                          {renderOrders()}
                          {renderPriceHistory({ isSmall: true })}
                        </>
                      ) : (
                        <>
                          {renderPriceHistory({ isSmall: true })}
                          {renderOrders()}
                        </>
                      )}
                      {renderSummary()}
                      {renderEventHistory()}
                      {renderSimilarItems()}
                    </div>
                  </Media>
                </div>
                <CampaignAnnouncementModal dataKey={data} />
              </DivContainer>
            ) : (
              <DivContainer>
                <div className="item--container item--loading">
                  <div className="item--loader-wrapper">
                    <Spinner size="medium" />
                  </div>
                </div>
              </DivContainer>
            )}
          </OpenSeaPage>
        </AccountOrCollectionPageContextProvider>
      </PageTrackingContextProvider>
    </ItemTrackingContextProvider>
  )
}

export const AssetPageQuery = graphql`
  query AssetPageQuery(
    $tokenId: String!
    $contractAddress: AddressScalar!
    $chain: ChainScalar!
  ) {
    ...AssetPage_data
      @arguments(
        tokenId: $tokenId
        contractAddress: $contractAddress
        chain: $chain
      )
  }
`

AssetPage.getInitialProps = QP.nextParser(
  {
    assetContractAddress: QP.Address,
    tokenId: QP.string,
    chain: QP.ChainIdentifier,
    created: QP.Optional(QP.boolean),
  },
  async (
    { assetContractAddress, tokenId, chain, created },
    ctx,
  ): Promise<GraphQLInitialProps<AssetPageQueryT, AssetPageProps>> => {
    const variables = {
      contractAddress: assetContractAddress,
      tokenId,
      chain,
    }
    const appInitialProps = await getAppInitialProps(ctx, {
      query: AssetPageQuery,
      variables,
    })

    return {
      ...appInitialProps,
      showCreatedModal: created,
      variables,
    }
  },
)

const AssetPageRedeemablesSection = ({ assetId }: { assetId: string }) => {
  const isRedeemPageEnabled = useIsRedeemPageEnabled()
  const t = useTranslate("assets")

  const data = useLazyLoadQuery<AssetPageRedeemablesSectionQuery>(
    graphql`
      query AssetPageRedeemablesSectionQuery($assetId: AssetRelayID!) {
        asset(asset: $assetId) {
          redeemableCampaigns {
            relayId
            campaignId
            ...RedeemableItemCard_redeemableCampaign
          }
          ...RedeemableItemCard_itemToBurn
        }
      }
    `,
    { assetId },
  )

  const asset = data.asset

  return isRedeemPageEnabled && asset.redeemableCampaigns.length ? (
    <Panel
      className="item--frame"
      icon={<RedeemablesIcon />}
      id="assets-item-redeemables"
      maxHeight={400}
      mode="always-open"
      title={t("redeemables.title", "Redeemables")}
    >
      <FlexColumn className="gap-5">
        {asset.redeemableCampaigns.map(campaign => (
          <RedeemableItemCard
            itemToBurn={asset}
            key={campaign.relayId}
            redeemableCampaign={campaign}
          />
        ))}
      </FlexColumn>
    </Panel>
  ) : null
}

const StyledGrid = styled(Grid)`
  grid-row-gap: 10px;
  grid-gap: 10px;
`

const StyledImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.default};
`

const ImageContainer = styled(Flex)`
  float: left;
  margin-right: 10px;
  margin-top: 3px;
  height: 80px;
  width: 80px;
  border-radius: ${props => props.theme.borderRadius.default};
`

const CategoryContainer = styled(UnstyledButton)`
  &&& {
    display: flex;
    gap: 6px;
    margin: 0;
    align-items: center;
  }
  ${props => props.onClick && interactiveStylesPrimary}
`

const DivContainer = styled(Container)<{
  $isSensitivityFilterVisible?: boolean
}>`
  align-items: center;
  display: flex;
  flex-direction: column;

  .item--container {
    max-width: 100%;
    width: 100%;

    &.item--loading {
      min-height: calc(100vh - ${$nav_height});
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .item--loader-wrapper {
    text-align: center;
    margin-top: -${$nav_height};
  }

  .item--wrapper {
    display: flex;
    flex-direction: column;
  }

  .item--frame {
    margin: 4px 0;

    .item--description-panel {
      .Panel--panel {
        margin-top: 0;
      }
    }

    .item--description {
      padding: 30px;

      .item--description-text {
        * {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
    }
  }

  .item--summary {
    flex: 3 0;
    max-width: 50%;
    min-width: 40%;
    width: 0;
  }

  .item--main {
    flex: 4 0;
    margin-left: -20px;
    margin-right: -20px;
    width: 0;
  }

  .item--header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    .item--collection-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      max-width: 100%;

      .item--collection-detail {
        display: flex;
        align-items: center;
        max-width: 100%;
        width: 420px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (min-width: ${breakpoints.lg}px) {
          width: 600px;
        }

        @media (min-width: ${breakpoints.xl}px) {
          width: 740px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          width: 70%;
        }
      }
    }

    .item--collection-toolbar-wrapper {
      max-width: fit-content;
    }

    .item--collection-link {
      color: ${props => props.theme.colors.primary};
      font-size: 16px;
    }

    .item--title {
      font-size: 30px;
      font-weight: 600;
      max-width: 100%;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      word-break: break-word;
    }
  }

  .item--about-container {
    overflow: hidden;
    text-overflow: ellipsis;

    * {
      font-size: 14px;
    }
  }

  .item--counts {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    > div {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .item--creator {
    align-items: center;
    color: ${props => props.theme.colors.gray};
    display: flex;

    .item--creator-account {
      height: 32px;
    }
  }

  .item--media-frame {
    margin: 20px 0;
    position: relative;

    .item--media {
      cursor: pointer;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .item--properties {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;

    .item--property {
      width: 100%;
    }
  }

  .item--numeric-traits {
    padding-bottom: 0;
    padding-top: 0;

    .item--numeric-trait {
      padding: 15px 5px;
    }
  }

  .item--boosts {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;

    .item--boost {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      margin-right: 5px;
      min-width: 80px;
    }
  }

  .item--orders {
    flex: 1 0;

    .item--orders-footer {
      border-top: 1px solid
        ${props => props.theme.colors.components.border.level2};
      padding: 10px;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    .item--container {
      padding-left: 0;
      padding-right: 0;
    }

    .item--wrapper {
      flex-direction: row;
    }

    .item--frame {
      margin: 20px;
    }

    .item--header {
      margin: 20px 20px 15px;

      .item--collection-detail {
        width: 500px;
      }
      .item--title {
        -webkit-line-clamp: 3;
        line-clamp: 3;
        width: 100%;
      }
    }

    .item--counts {
      margin: 24px 20px;
    }

    .item--summary-frame {
      margin-left: 0;
    }

    .item--media-frame {
      margin-left: 0;
      margin-right: 20px;
    }

    .item--trading-history {
      margin: 0;
    }

    .item--frame-similar-items {
      margin-right: 0;
      margin-left: 0;
    }
  }

  ${props =>
    props.$isSensitivityFilterVisible &&
    css`
      overflow-y: hidden;
      filter: blur(60px);
    `}
`
