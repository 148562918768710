import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { Link, LinkProps } from "@/components/common/Link"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Tooltip } from "@/design-system/Tooltip"
import {
  readDisabledReasonDealAsset,
  useGetDealAssetDisabledReason,
} from "@/features/deals/hooks/useGetDealAssetDisabledReason/useGetDealAssetDisabledReason"
import { getCreateDealUrl } from "@/features/deals/urls"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetDealLink_asset$key } from "@/lib/graphql/__generated__/AssetDealLink_asset.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { addressesEqual, isNullAddress } from "@/lib/helpers/address"
import { getChainSlug } from "@/lib/helpers/chainUtils"

type Props = {
  asset: AssetDealLink_asset$key
  children?: React.ReactNode
} & Omit<LinkProps, "href">

export const AssetDealLink = ({
  asset: assetKey,
  children,
  ...props
}: Props) => {
  const t = useTranslate("deals")
  const connectedAddress = useConnectedAddress()
  const router = useRouter()
  const [isClicked, setIsClicked] = useState(false)
  const { startWalletModalAuthFlow } = useWalletModal()
  const { showWarningMessage } = useToasts()

  const asset = useFragment<AssetDealLink_asset$key>(
    graphql`
      fragment AssetDealLink_asset on AssetType {
        assetContract {
          address
        }
        tokenId
        chain {
          identifier
        }
        assetOwners(first: 1) {
          edges {
            node {
              owner {
                address
              }
            }
          }
        }
        ...useGetDealAssetDisabledReason_asset
      }
    `,
    assetKey,
  )
  const { assetContract, chain, tokenId } = asset

  const assetOwnerAddress = getNodes(asset.assetOwners)[0]?.owner.address

  const getCommonDisabledReason = useGetDealAssetDisabledReason()
  const disabledReason =
    getCommonDisabledReason(readDisabledReasonDealAsset(asset)) ??
    (() => {
      if (
        assetOwnerAddress &&
        connectedAddress &&
        assetOwnerAddress === connectedAddress
      ) {
        return t(
          "assetDealLink.disabledReason.ownedItem",
          "This item is already owned by you",
        )
      }
      if (!assetOwnerAddress || isNullAddress(assetOwnerAddress)) {
        return t(
          "assetDealLink.disabledReason.invalidOwner",
          "This item's owner is unsupported",
        )
      }
      return null
    })()

  const url = `${getCreateDealUrl(assetOwnerAddress)}/${getChainSlug(
    chain.identifier,
  )}/${assetContract.address}/${tokenId}`

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      setIsClicked(true)
      if (!connectedAddress) {
        e.preventDefault()
        startWalletModalAuthFlow()
      }
    },
    [connectedAddress, startWalletModalAuthFlow],
  )

  // Notify user that they can't make a deal with themselves after they connect
  // their wallet. Otherwise, they'll be redirected to the create deal page.
  useUpdateEffect(() => {
    if (!connectedAddress) {
      return
    }

    // Checks if the user clicked the link to prevent triggering this effect if
    // they switch their wallets without clicking on this link.
    if (!isClicked) {
      return
    }

    if (addressesEqual(connectedAddress, assetOwnerAddress)) {
      showWarningMessage(
        t(
          "assetDealLink.error.cannotCreateDealWithSelf",
          "Cannot create a deal with yourself",
        ),
      )
      return
    }

    router.push(url)
  }, [connectedAddress])

  const disabled = !!disabledReason

  return (
    <Tooltip
      content={disabledReason ?? t("assetDealLink.valid", "Make a deal")}
    >
      <span>
        <Link
          {...props}
          // Not disabled when connectedAddress is undefined to allow the user
          // to click the link and connect their wallet.
          disabled={disabled}
          eventSource="AssetDealLink"
          // Prevent specifying url when there is no connected wallet to allow
          // onClick to be called without navigating incorrectly after
          // connecting wallet.
          href={connectedAddress && !disabled ? url : undefined}
          onClick={handleClick}
        >
          {children}
        </Link>
      </span>
    </Tooltip>
  )
}
