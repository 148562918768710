import React from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { AccountItem } from "@/components/common/AccountItem"
import { LazyLoadList } from "@/components/common/LazyLoadList"
import type { BlockProps } from "@/design-system/Block"
import { List } from "@/design-system/List"
import { useTranslate } from "@/hooks/useTranslate"
import { ItemOwnersList_data$key } from "@/lib/graphql/__generated__/ItemOwnersList_data.graphql"
import { ItemOwnersListLazyQuery } from "@/lib/graphql/__generated__/ItemOwnersListLazyQuery.graphql"
import { ItemOwnersListQuery } from "@/lib/graphql/__generated__/ItemOwnersListQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"

const PAGE_SIZE = 20
const LISTITEM_HEIGHT = 70

type Props = {
  dataKey: ItemOwnersList_data$key
  overrides?: { Root?: { props: BlockProps } }
}

const ItemOwnersList = ({ dataKey, overrides }: Props) => {
  const t = useTranslate("owners")
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    ItemOwnersListQuery,
    ItemOwnersList_data$key
  >(
    graphql`
      fragment ItemOwnersList_data on Query
      @argumentDefinitions(
        assetId: { type: "AssetRelayID!" }
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
      )
      @refetchable(queryName: "ItemOwnersListQuery") {
        asset(asset: $assetId) {
          assetOwners(after: $cursor, first: $count)
            @connection(key: "ItemOwnersList_assetOwners") {
            edges {
              node {
                relayId
                quantity
                asset {
                  decimals
                }
                owner {
                  ...AccountItem_data
                }
              }
            }
          }
        }
      }
    `,
    dataKey,
  )

  const accounts = getNodes(data.asset.assetOwners)
  return (
    <LazyLoadList
      itemHeight={LISTITEM_HEIGHT}
      overrides={overrides}
      pageSize={PAGE_SIZE}
      pagination={{ loadNext, hasNext, isLoadingNext }}
    >
      {accounts.map(account => (
        <AccountItem
          Item={List.Item}
          dataKey={account.owner}
          key={account.relayId}
          side={
            <List.Item.Description>
              {t(
                "owners.itemQuantity",
                {
                  "0": "{{count}} items",
                  one: "{{count}} item",
                  other: "{{count}} items",
                },
                {
                  count: bn(
                    account.quantity,
                    account.asset.decimals,
                  ).toNumber(),
                },
              )}
            </List.Item.Description>
          }
        />
      ))}
    </LazyLoadList>
  )
}

type LazyProps = Omit<Props, "dataKey"> & ItemOwnersListLazyQuery["variables"]

export const ItemsOwnersLazyList = ({ assetId, ...rest }: LazyProps) => {
  const dataKey = useLazyLoadQuery<ItemOwnersListLazyQuery>(
    graphql`
      query ItemOwnersListLazyQuery($assetId: AssetRelayID!) {
        ...ItemOwnersList_data @arguments(assetId: $assetId)
      }
    `,
    { assetId },
  )

  return <ItemOwnersList dataKey={dataKey} {...rest} />
}

type ItemOwnersListSkeletonProps = {
  count?: number
  overrides?: { Root?: { props: BlockProps } }
}

export const ItemOwnersListSkeleton = ({
  count,
  overrides,
}: ItemOwnersListSkeletonProps) => {
  return (
    <LazyLoadList.Skeleton
      count={count}
      overrides={overrides}
      pageSize={PAGE_SIZE}
      renderItem={index => <AccountItem.Skeleton key={index} sideDescription />}
    />
  )
}
