import React, { forwardRef } from "react"
import { Icon, IconProps } from "@opensea/ui-kit"
import styled from "styled-components"
import { interactiveStylesPrimary } from "@/design-system/utils/styles"
import { useTranslate } from "@/hooks/useTranslate"

type FavoriteIconProps = Omit<IconProps, "color" | "value" | "title"> & {
  isFavorite: boolean
  disabled?: boolean
}

export const FavoriteIcon = forwardRef<HTMLDivElement, FavoriteIconProps>(
  function FavoriteIcon({ isFavorite, disabled, ...rest }, ref) {
    const t = useTranslate("components")
    return (
      <StyledIcon
        {...rest}
        aria-label={
          isFavorite
            ? t("favoriteIcon.unfavorite", "Unfavorite")
            : t("favoriteIcon.favorite", "Favorite")
        }
        color={isFavorite ? "red-2" : undefined}
        disabled={disabled}
        fill={isFavorite ? 1 : 0}
        isFavorite={isFavorite}
        ref={ref}
        value="favorite"
      />
    )
  },
)

const StyledIcon = styled(Icon)<FavoriteIconProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray : theme.colors.text.primary};

  ${interactiveStylesPrimary};
`
