/**
 * @generated SignedSource<<908a593f3b573de20252e4b2473e6a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SemiFungibleTradeStation_bestOffers$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TradeStationSellTab_bestOffers">;
  readonly " $fragmentType": "SemiFungibleTradeStation_bestOffers";
};
export type SemiFungibleTradeStation_bestOffers$key = {
  readonly " $data"?: SemiFungibleTradeStation_bestOffers$data;
  readonly " $fragmentSpreads": FragmentRefs<"SemiFungibleTradeStation_bestOffers">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractAddress"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tokenId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SemiFungibleTradeStation_bestOffers",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "chain"
        },
        {
          "kind": "Variable",
          "name": "contractAddress",
          "variableName": "contractAddress"
        },
        {
          "kind": "Variable",
          "name": "tokenId",
          "variableName": "tokenId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "TradeStationSellTab_bestOffers"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "761fa6bb29db6450c215a8c77690b2d4";

export default node;
