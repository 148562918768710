import React, { forwardRef } from "react"
import { Icon, MaterialIcon, MaterialTheme } from "@opensea/ui-kit"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { HUES } from "../../styles/themes"

type Props = {
  icon?: MaterialIcon
  hoverColor?: string
  variant?: MaterialTheme
  imageUrl?: string
  iconSize?: number
  className?: string
} & BlockProps

export const AnnotationImage = forwardRef<HTMLDivElement, Props>(
  function AnnotationImage(
    {
      icon,
      hoverColor = HUES.gray,
      variant = "outlined",
      className,
      iconSize = 18,
      imageUrl,
      ...rest
    },
    ref,
  ) {
    return (
      <DivContainer
        className={className}
        hoverColor={hoverColor}
        ref={ref}
        {...rest}
      >
        {imageUrl ? (
          <Image
            alt="Annotation image"
            height={iconSize}
            src={imageUrl}
            width={iconSize}
          />
        ) : null}
        {icon ? (
          <Icon
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="Annotation--icon"
            size={iconSize}
            value={icon}
            variant={variant}
          />
        ) : null}
      </DivContainer>
    )
  },
)

const DivContainer = styled(Block)<{ hoverColor: string }>`
  width: fit-content;
  display: flex;
  justify-content: space-around;
  margin-right: 6px;

  .Annotation--icon {
    color: ${props => props.theme.colors.gray};
    &:hover {
      color: ${p => p.hoverColor};
    }
  }

  img {
    border-radius: ${props => props.theme.borderRadius.circle};
  }
`
