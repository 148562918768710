/**
 * @generated SignedSource<<59a94e4a1063bb132939b6794871e1d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuyNowButton_orders$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"BulkPurchaseModal_orders">;
  readonly " $fragmentType": "BuyNowButton_orders";
}>;
export type BuyNowButton_orders$key = ReadonlyArray<{
  readonly " $data"?: BuyNowButton_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuyNowButton_orders">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BuyNowButton_orders",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BulkPurchaseModal_orders"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "aa21d97b6dba4a51fc2f338d8afb2325";

export default node;
