import React, { useState } from "react"
import {
  Flex,
  Icon,
  SpaceBetween,
  Text,
  ToggleButtonGroup,
} from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import Frame from "@/components/layout/Frame.react"
import { useTranslate } from "@/hooks/useTranslate"
import { SemiFungibleTradeStation_asset$key } from "@/lib/graphql/__generated__/SemiFungibleTradeStation_asset.graphql"
import { SemiFungibleTradeStation_bestListings$key } from "@/lib/graphql/__generated__/SemiFungibleTradeStation_bestListings.graphql"
import { SemiFungibleTradeStation_bestOffers$key } from "@/lib/graphql/__generated__/SemiFungibleTradeStation_bestOffers.graphql"
import { bn, display } from "@/lib/helpers/numberUtils"
import { TradeStationBuyTab } from "./components/TradeStationBuyTab.react"
import { TradeStationSellTab } from "./components/TradeStationSellTab.react"

const TABS = [
  { label: "Sell", id: "sell" },
  { label: "Buy", id: "buy" },
]

type TabId = (typeof TABS)[number]["id"]

type SemiFungibleTradeStationProps = {
  asset: SemiFungibleTradeStation_asset$key
  bestListings: SemiFungibleTradeStation_bestListings$key
  bestOffers: SemiFungibleTradeStation_bestOffers$key
}

export const SemiFungibleTradeStation = ({
  asset: assetDataKey,
  bestListings: bestListingsDataKey,
  bestOffers: bestOffersDataKey,
}: SemiFungibleTradeStationProps) => {
  const t = useTranslate("components")

  const asset = useFragment(
    graphql`
      fragment SemiFungibleTradeStation_asset on AssetType {
        ownedQuantity(identity: {})
        ...TradeStationBuyTab_asset
        ...TradeStationSellTab_asset
      }
    `,
    assetDataKey,
  )

  const bestListings = useFragment(
    graphql`
      fragment SemiFungibleTradeStation_bestListings on Query
      @argumentDefinitions(
        tokenId: { type: "String!" }
        contractAddress: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      ) {
        ...TradeStationBuyTab_bestListings
          @arguments(
            tokenId: $tokenId
            contractAddress: $contractAddress
            chain: $chain
          )
      }
    `,
    bestListingsDataKey,
  )

  const bestOffers = useFragment(
    graphql`
      fragment SemiFungibleTradeStation_bestOffers on Query
      @argumentDefinitions(
        tokenId: { type: "String!" }
        contractAddress: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      ) {
        ...TradeStationSellTab_bestOffers
          @arguments(
            tokenId: $tokenId
            contractAddress: $contractAddress
            chain: $chain
          )
      }
    `,
    bestOffersDataKey,
  )

  const ownedQuantity = bn(
    asset.ownedQuantity !== null ? asset.ownedQuantity : 0,
  )

  // The viewer owns some of the item
  const viewerCanSell = ownedQuantity.isGreaterThan(0)

  const [tab, setTab] = useState<TabId>(viewerCanSell ? "sell" : "buy")

  return (
    <StyledFrame data-testid="trade-station">
      {viewerCanSell && (
        <SpaceBetween className="mb-1 items-center border-b border-b-level-1 py-4 pl-4 pr-0">
          <ToggleButtonGroup value={tab} onValueChange={setTab}>
            {TABS.map(({ id, label }) => (
              <ToggleButtonGroup.Button key={id} value={id}>
                {label}
              </ToggleButtonGroup.Button>
            ))}
          </ToggleButtonGroup>
          <Flex className="mr-6 mt-0.5 items-center gap-2">
            <Icon size={20} value="person" />
            <Text.Body className="whitespace-pre" size="small">
              {t(
                "trade.semiFungibleTradeStation.ownershipCount",
                "You own {{quantity}}",
                { quantity: display(ownedQuantity) },
              )}
            </Text.Body>
          </Flex>
        </SpaceBetween>
      )}

      {tab === "sell" ? (
        <TradeStationSellTab asset={asset} bestOffers={bestOffers} />
      ) : (
        <TradeStationBuyTab asset={asset} bestListings={bestListings} />
      )}
    </StyledFrame>
  )
}

const StyledFrame = styled(Frame)`
  background-color: ${props =>
    props.theme.type === "light"
      ? props.theme.colors.base2
      : props.theme.colors.components.background.gray1};
`
