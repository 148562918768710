import React from "react"
import { Media, Alert } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { AcceptOfferDisabledAlert_asset$key } from "@/lib/graphql/__generated__/AcceptOfferDisabledAlert_asset.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"
import { useAcceptOfferDisabledReason } from "./useAcceptOfferDisabledReason.react"

type AcceptOfferDisabledAlertProps = {
  asset: AcceptOfferDisabledAlert_asset$key
}

export const AcceptOfferDisabledAlert = ({
  asset: assetDataKey,
}: AcceptOfferDisabledAlertProps) => {
  const asset = useFragment(
    graphql`
      fragment AcceptOfferDisabledAlert_asset on AssetType {
        decimals
        ownedQuantity(identity: {})
        acceptOfferDisabled {
          ...useAcceptOfferDisabledReason_data
        }
      }
    `,
    assetDataKey,
  )

  const disabledReason = useAcceptOfferDisabledReason(asset.acceptOfferDisabled)

  if (!asset.acceptOfferDisabled) {
    return null
  }

  const ownedQuantity = asset.ownedQuantity
    ? bn(asset.ownedQuantity, asset.decimals)
    : bn(0)

  if (ownedQuantity.isZero()) {
    return null
  }

  return (
    <Block className="border-b border-level-2" margin={{ sm: "0 20px 20px" }}>
      <StyledAlert>
        <Alert.Icon color="warning" value="error" />
        <Media lessThan="sm">
          <Alert.Body>{disabledReason}</Alert.Body>
        </Media>
        <Media greaterThanOrEqual="sm">
          <Alert.Title>{disabledReason}</Alert.Title>
        </Media>
      </StyledAlert>
    </Block>
  )
}

const StyledAlert = styled(Alert)`
  text-align: start;

  ${media({
    xs: css`
      border-radius: 0;
      padding: 20px;
    `,
    sm: css`
      border-radius: ${props => props.theme.borderRadius.toast};
      padding: 16px;
    `,
  })}
`
