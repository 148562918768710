import React from "react"
import {
  Flex,
  FlexColumn,
  Media,
  SpaceBetween,
  Text,
  classNames,
  FlexCenter,
} from "@opensea/ui-kit"
import { graphql, useFragment, usePaginationFragment } from "react-relay"
import { AccountLinkV2 } from "@/components/accounts/AccountLinkV2"
import { Block } from "@/design-system/Block"
import { MakeAssetOfferButton } from "@/features/orders/components/MakeAssetOfferButton.react"
import { useTranslate } from "@/hooks/useTranslate"
import { TradeStationBuyTab_asset$key } from "@/lib/graphql/__generated__/TradeStationBuyTab_asset.graphql"
import { TradeStationBuyTab_bestListings$key } from "@/lib/graphql/__generated__/TradeStationBuyTab_bestListings.graphql"
import { TradeStationBuyTabBestListingsPaginationQuery } from "@/lib/graphql/__generated__/TradeStationBuyTabBestListingsPaginationQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { display, bn } from "@/lib/helpers/numberUtils"
import { ORDER_PAGE_SIZE, CTA_BUTTON_PROPS_OVERRIDE } from "../constants"
import { useFulfillSemiFungibleOrders } from "../hooks/useFulfillSemiFungibleOrders"
import { SemiFungibleBulkSaleParams } from "../types"
import {
  trackClickBuyNow,
  trackClickBuyNowAdd,
  trackClickBuyNowRemove,
  trackClickBuyNowSubstituteItems,
} from "./BuyNowButton/analytics"
import { TradeStationOrderPrice } from "./TradeStationOrderPrice.react"
import { TradeStationPrice } from "./TradeStationPrice.react"

type BuyTabProps = {
  asset: TradeStationBuyTab_asset$key
  bestListings: TradeStationBuyTab_bestListings$key
}

export const TradeStationBuyTab = ({
  asset: assetDataKey,
  bestListings: bestListingsDataKey,
}: BuyTabProps) => {
  const t = useTranslate("components")

  const {
    data: bestListingsData,
    loadNext: loadMoreOrders,
    isLoadingNext: isLoadingMoreOrders,
    hasNext: hasMoreOrders,
  } = usePaginationFragment<
    TradeStationBuyTabBestListingsPaginationQuery,
    TradeStationBuyTab_bestListings$key
  >(
    graphql`
      fragment TradeStationBuyTab_bestListings on Query
      @argumentDefinitions(
        pageSize: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        tokenId: { type: "String!" }
        contractAddress: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      )
      @refetchable(queryName: "TradeStationBuyTabBestListingsPaginationQuery") {
        nft(
          tokenId: $tokenId
          contractAddress: $contractAddress
          chain: $chain
        ) {
          ... on AssetType {
            bestListings(first: $pageSize, after: $cursor, forTaker: {})
              @connection(key: "TradeStationBuyTab_bestListings") {
              edges {
                node {
                  ...useFulfillSemiFungibleOrders_orders
                }
              }
            }
          }
        }
      }
    `,
    bestListingsDataKey,
  )
  const bestListings = getNodes(bestListingsData.nft.bestListings)

  const asset = useFragment(
    graphql`
      fragment TradeStationBuyTab_asset on AssetType {
        tradeSummary {
          bestAsk {
            maker {
              ...AccountLinkV2_account
            }
            ...TradeStationOrderPrice_order
            ...OrderPriceChanged_bestAsk
          }
        }
        ...useFulfillSemiFungibleOrders_asset
        ...MakeAssetOfferButton_asset
        # eslint-disable-next-line relay/must-colocate-fragment-spreads - Used for amplitude logging
        ...itemEvents_dataV2
      }
    `,
    assetDataKey,
  )

  const {
    renderFulfillButton: renderBuyNowButton,
    renderQuantitySelector,
    renderSubstituteItems,
    totalPrice,
    totalPriceLimit: maxTotalPrice,
    averagePrice,
    quantity,
    fulfillQuantity,
    isSubstitutionEnabled,
    pricePerItemLimit,
    renderListingFeesSupportsCreator,
  } = useFulfillSemiFungibleOrders({
    orders: bestListings,
    asset,
    side: "BUY",
    hasMoreOrders,
    isLoadingMoreOrders,
    loadMoreOrders: () => loadMoreOrders(ORDER_PAGE_SIZE),
  })

  const analyticsParams: SemiFungibleBulkSaleParams = {
    isSubstitutionEnabled,
    pricePerItemLimit,
    quantity: fulfillQuantity?.toNumber() ?? 1,
  }

  const bestListing = asset.tradeSummary.bestAsk

  const viewerCanBuyImmediately = !!bestListings.length

  const priceLabel = maxTotalPrice
    ? t("trade.station.expectedPrice", "Expected price")
    : totalPrice && fulfillQuantity?.isGreaterThan(1)
    ? t("trade.station.totalPrice", "Total price")
    : bestListing
    ? t("trade.station.currentPrice", "Current price")
    : undefined

  const acceptOfferQuantity = quantity ?? bn(1)

  return (
    <FlexColumn asChild className="w-full gap-2 p-4">
      <form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        {priceLabel && (
          <SpaceBetween>
            <Block marginBottom="8px">
              <Text.Body className="mb-2 block text-secondary" size="small">
                {priceLabel}
              </Text.Body>
              {totalPrice ? (
                <TradeStationPrice
                  maxTotalPrice={maxTotalPrice}
                  totalPrice={totalPrice}
                />
              ) : bestListing ? (
                <TradeStationOrderPrice order={bestListing} />
              ) : null}
              {fulfillQuantity?.isGreaterThan(1) && (
                <Text asChild className="mt-2 text-secondary" size="small">
                  <div>
                    {t(
                      "trade.station.price.average",
                      "Average item price: {{price}} {{symbol}}",
                      {
                        price: averagePrice ? display(averagePrice) : "",
                        symbol: totalPrice?.tokenPricePayment.symbol ?? "",
                      },
                    )}
                  </div>
                </Text>
              )}
            </Block>
            <Media greaterThanOrEqual="lg">
              {renderQuantitySelector({
                onClickAdd: trackClickBuyNowAdd,
                onClickRemove: trackClickBuyNowRemove,
                className: "py-2 max-w-[192px]",
                overrides: {
                  Input: {
                    props: {
                      height: "48px",
                    },
                  },
                },
              })}
            </Media>
          </SpaceBetween>
        )}
        {!isSubstitutionEnabled &&
          priceLabel &&
          bestListing &&
          fulfillQuantity?.isEqualTo(1) && (
            <FlexCenter className="-mt-2 mb-2">
              <Text.Body className="text-secondary" size="small">
                {t("trade.station.listedBy", "Listed by")}&nbsp;
              </Text.Body>
              <AccountLinkV2 account={bestListing.maker}>
                <FlexCenter>
                  <AccountLinkV2.Name size="small" />
                  <AccountLinkV2.Badge variant="small" />
                </FlexCenter>
              </AccountLinkV2>
            </FlexCenter>
          )}

        {viewerCanBuyImmediately &&
          renderSubstituteItems({
            overrides: {
              Checkbox: {
                props: {
                  onCheckedChange: () =>
                    trackClickBuyNowSubstituteItems(asset, analyticsParams),
                },
              },
            },
          })}

        <Media lessThan="lg">
          {renderQuantitySelector({
            className: "w-full",
            overrides: { FirstRowContainer: { className: "w-full" } },
          })}
        </Media>

        <Flex className="flex-wrap gap-x-4 gap-y-2">
          {viewerCanBuyImmediately &&
            renderBuyNowButton({
              onClick: () => trackClickBuyNow(asset, analyticsParams),
              overrides: {
                Button: { props: { className: classNames("h-[60px] w-full") } },
                ButtonContainer: { props: CTA_BUTTON_PROPS_OVERRIDE },
              },
            })}

          <Block {...CTA_BUTTON_PROPS_OVERRIDE} height="60px">
            <MakeAssetOfferButton
              asset={asset}
              disabled={quantity === undefined}
              initialQuantity={acceptOfferQuantity}
              overrides={{
                Button: { props: { className: classNames("h-full") } },
              }}
              source="item page"
            />
          </Block>

          {!priceLabel && (
            <Media greaterThanOrEqual="lg">
              {mediaClassNames =>
                renderQuantitySelector({
                  ...CTA_BUTTON_PROPS_OVERRIDE,
                  className: mediaClassNames,
                })
              }
            </Media>
          )}

          {renderListingFeesSupportsCreator()}
        </Flex>
      </form>
    </FlexColumn>
  )
}
