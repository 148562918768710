/**
 * @generated SignedSource<<41393cd5b43dcd8c529acbdea04e261f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TradeStationSellTab_asset$data = {
  readonly ownedQuantity: string | null;
  readonly tradeSummary: {
    readonly bestBid: {
      readonly " $fragmentSpreads": FragmentRefs<"TradeStationOrderPrice_order">;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AssetListButton_asset" | "itemEvents_dataV2" | "useFulfillSemiFungibleOrders_asset">;
  readonly " $fragmentType": "TradeStationSellTab_asset";
};
export type TradeStationSellTab_asset$key = {
  readonly " $data"?: TradeStationSellTab_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStationSellTab_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeStationSellTab_asset",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "identity",
          "value": {}
        }
      ],
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": "ownedQuantity(identity:{})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeSummaryType",
      "kind": "LinkedField",
      "name": "tradeSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestBid",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TradeStationOrderPrice_order"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFulfillSemiFungibleOrders_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetListButton_asset"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_dataV2",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "type": "ItemType",
          "abstractKey": "__isItemType"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "ab84dc6035ce3409e747fe5076a8b659";

export default node;
