import React from "react"
import { useAssetPageListingEvent } from "@/features/live-updates/hooks/useAssetPageListingEvent/useAssetPageListingEvent"
import { useAssetPageListingUpdatedEvent } from "@/features/live-updates/hooks/useAssetPageListingUpdatedEvent/useAssetPageListingUpdatedEvent"
import { useAssetPageOfferEvent } from "@/features/live-updates/hooks/useAssetPageOfferEvent/useAssetPageOfferEvent"
import { useAssetPageOfferUpdatedEvent } from "@/features/live-updates/hooks/useAssetPageOfferUpdatedEvent/useAssetPageOfferUpdatedEvent"

export type AssetProps = {
  slug: string
  relayId: string
  contractAddress: string
  tokenId: string
  chain: string
}

export const AssetPageListingSubscription = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  useAssetPageListingEvent({ slug, relayId, contractAddress, tokenId, chain })
  return <></>
}

export const AssetPageListingUpdatedSubscription = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  useAssetPageListingUpdatedEvent({
    slug,
    relayId,
    contractAddress,
    tokenId,
    chain,
  })
  return <></>
}

export const AssetPageOfferSubscription = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  useAssetPageOfferEvent({
    slug,
    relayId,
    contractAddress,
    tokenId,
    chain,
  })
  return <></>
}

export const AssetPageOfferUpdatedSubscription = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  useAssetPageOfferUpdatedEvent({
    slug,
    relayId,
    contractAddress,
    tokenId,
    chain,
  })
  return <></>
}
