/* eslint-disable tailwindcss/no-custom-classname */
import React, { forwardRef, useEffect } from "react"
import { range } from "lodash"
import { graphql, useFragment } from "react-relay"
import { Panel } from "@/components/layout/Panel"
import { Orders, OrdersType } from "@/components/orders/Orders.react"
import { Skeleton } from "@opensea/ui-kit"
import { Table } from "@/design-system/Table"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { ListingsPanel_asset$key } from "@/lib/graphql/__generated__/ListingsPanel_asset.graphql"

type ListingsPanelProps = {
  isReviewing: boolean
  toggleReviewingStatus: () => unknown
  asset: ListingsPanel_asset$key
}

export const ListingsPanel = forwardRef<HTMLDivElement, ListingsPanelProps>(
  function ListingsPanel(
    { isReviewing, toggleReviewingStatus, asset: assetDataKey },
    ref,
  ) {
    const t = useTranslate("assets")

    const asset = useFragment(
      graphql`
        fragment ListingsPanel_asset on AssetType {
          tokenId
          isCurrentlyFungible
          ownedQuantity(identity: {})
          chain {
            identifier
          }
          assetContract {
            address
          }
        }
      `,
      assetDataKey,
    )

    const { isOpen, setIsOpen } = useIsOpen(
      asset.isCurrentlyFungible || isReviewing,
    )
    const handleIsOpen = (shouldBeOpen: boolean) => {
      if (isOpen && isReviewing) {
        toggleReviewingStatus()
      } else {
        setIsOpen(shouldBeOpen)
      }
    }

    useEffect(() => {
      setIsOpen(asset.isCurrentlyFungible || isReviewing)
    }, [asset.isCurrentlyFungible, isReviewing, setIsOpen])

    const ownedQuantity = asset.ownedQuantity ? Number(asset.ownedQuantity) : 0

    return (
      <Panel
        className="item--frame item--orders"
        icon="sell"
        id="listings-panel"
        isContentPadded={false}
        mode="controlled"
        open={isOpen}
        ref={ref}
        title={t("listings.title", "Listings")}
        onClick={handleIsOpen}
      >
        <Orders
          mode={
            asset.isCurrentlyFungible ? OrdersType.full : OrdersType.minimal
          }
          ownedQuantity={ownedQuantity}
          renderSkeletonRows={ListingsPanelSkeletonRows}
          side="ask"
          variables={{
            isExpired: false,
            isValid: true,
            makerArchetype: {
              assetContractAddress: asset.assetContract.address,
              tokenId: asset.tokenId,
              chain: asset.chain.identifier,
            },
            takerAssetIsPayment: true,
            sortAscending: true,
            sortBy: "PRICE",
            isSingleAsset: true,
          }}
        />
      </Panel>
    )
  },
)

const ListingsPanelSkeletonRows = () => {
  return range(5).map(index => (
    <Table.Row key={index}>
      <Table.Cell height="72px">
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
    </Table.Row>
  ))
}
