/**
 * @generated SignedSource<<470d886354c16dcf3893347911ddb2f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Toolbar_asset$data = {
  readonly assetContract: {
    readonly address: string;
  };
  readonly collection: {
    readonly externalUrl: string | null;
  };
  readonly externalLink: string | null;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetDealLink_asset" | "asset_url" | "itemEvents_data">;
  readonly " $fragmentType": "Toolbar_asset";
};
export type Toolbar_asset$key = {
  readonly " $data"?: Toolbar_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"Toolbar_asset">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Toolbar_asset",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "asset_url",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetDealLink_asset"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_data",
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalLink",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "type": "AssetType",
  "abstractKey": null
};
})();

(node as any).hash = "54fe2839ebd05718ec32845eca80bcb5";

export default node;
