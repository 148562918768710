import React from "react"
import { graphql, useFragment } from "react-relay"
import {
  BulkPurchaseModal,
  OrderToQuantity,
} from "@/components/trade/BulkPurchaseModal"
import { useTranslate } from "@/hooks/useTranslate"
import { BuyNowButton_orders$key } from "@/lib/graphql/__generated__/BuyNowButton_orders.graphql"
import { BigNumber, display } from "@/lib/helpers/numberUtils"
import { SemiFungibleFulfillButtonProps } from "../../types"

type BuyNowButtonProps = SemiFungibleFulfillButtonProps & {
  orders: BuyNowButton_orders$key
  orderToQuantity: OrderToQuantity
  quantity: BigNumber | undefined
  maxOrdersToFill?: number
}

export const BuyNowButton = ({
  disabled,
  isLoading,
  maxOrdersToFill,
  orders: orderDataKey,
  orderToQuantity,
  overrides,
  quantity,
  onClick,
}: BuyNowButtonProps) => {
  const t = useTranslate("components")

  const orders = useFragment(
    graphql`
      fragment BuyNowButton_orders on OrderV2Type @relay(plural: true) {
        ...BulkPurchaseModal_orders
      }
    `,
    orderDataKey,
  )

  return (
    <BulkPurchaseModal
      fillType="BUY_NOW"
      maxOrdersToFill={maxOrdersToFill}
      orderToQuantity={orderToQuantity}
      orders={orders}
      paymentMethod="crypto"
    >
      <BulkPurchaseModal.Trigger
        disabled={disabled}
        isLoading={isLoading}
        type="submit"
        onClick={onClick}
        {...overrides?.Button?.props}
      >
        {t(
          "trade.buyNow.cta",
          {
            "0": "Buy {{displayCount}} now",
            one: "Buy {{displayCount}} now",
            other: "Buy {{displayCount}} now",
          },
          {
            count: quantity?.toNumber() ?? 1,
            displayCount: display(quantity ?? 1),
          },
        )}
      </BulkPurchaseModal.Trigger>
    </BulkPurchaseModal>
  )
}
