/**
 * @generated SignedSource<<3985d6a3c996300fc6a02344bd553254>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetActivityPanelPagination_data$data = {
  readonly nft: {
    readonly activity: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly eventTimestamp: string;
          readonly " $fragmentSpreads": FragmentRefs<"EventHistory_data">;
        } | null;
      } | null>;
    };
  };
  readonly " $fragmentType": "AssetActivityPanelPagination_data";
};
export type AssetActivityPanelPagination_data$key = {
  readonly " $data"?: AssetActivityPanelPagination_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetActivityPanelPagination_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "nft",
  "activity"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractAddress"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "eventTypes"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tokenId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AssetActivityPanelPaginationQuery.graphql')
    }
  },
  "name": "AssetActivityPanelPagination_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "chain"
        },
        {
          "kind": "Variable",
          "name": "contractAddress",
          "variableName": "contractAddress"
        },
        {
          "kind": "Variable",
          "name": "tokenId",
          "variableName": "tokenId"
        }
      ],
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "alias": "activity",
          "args": [
            {
              "kind": "Variable",
              "name": "eventTypes",
              "variableName": "eventTypes"
            }
          ],
          "concreteType": "AssetEventTypeConnection",
          "kind": "LinkedField",
          "name": "__AssetActivityPanel_activity_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetEventTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetEventType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "showAll",
                          "value": true
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "EventHistory_data"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "eventTimestamp",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "30ae26bba9dab6c9c85e75ef57a6007e";

export default node;
