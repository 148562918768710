/**
 * @generated SignedSource<<aa4d09ea39eebd6e68074aa7b2016257>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetPageRedeemablesSectionQuery$variables = {
  assetId: string;
};
export type AssetPageRedeemablesSectionQuery$data = {
  readonly asset: {
    readonly redeemableCampaigns: ReadonlyArray<{
      readonly campaignId: number;
      readonly relayId: string;
      readonly " $fragmentSpreads": FragmentRefs<"RedeemableItemCard_redeemableCampaign">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"RedeemableItemCard_itemToBurn">;
  };
};
export type AssetPageRedeemablesSectionQuery = {
  response: AssetPageRedeemablesSectionQuery$data;
  variables: AssetPageRedeemablesSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "assetId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "campaignId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetPageRedeemablesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RedeemableCampaignType",
            "kind": "LinkedField",
            "name": "redeemableCampaigns",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RedeemableItemCard_redeemableCampaign"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RedeemableItemCard_itemToBurn"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssetPageRedeemablesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RedeemableCampaignType",
            "kind": "LinkedField",
            "name": "redeemableCampaigns",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageUrls",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredAmountToRedeem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "receivedCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetContractType",
            "kind": "LinkedField",
            "name": "assetContract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tokenId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChainType",
            "kind": "LinkedField",
            "name": "chain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identifier",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0db34b4f74c64614274f922a70911fb4",
    "id": null,
    "metadata": {},
    "name": "AssetPageRedeemablesSectionQuery",
    "operationKind": "query",
    "text": "query AssetPageRedeemablesSectionQuery(\n  $assetId: AssetRelayID!\n) {\n  asset(asset: $assetId) {\n    redeemableCampaigns {\n      relayId\n      campaignId\n      ...RedeemableItemCard_redeemableCampaign\n      id\n    }\n    ...RedeemableItemCard_itemToBurn\n    id\n  }\n}\n\nfragment RedeemableItemCard_itemToBurn on AssetType {\n  collection {\n    name\n    id\n  }\n  ...asset_url\n}\n\nfragment RedeemableItemCard_redeemableCampaign on RedeemableCampaignType {\n  campaignId\n  imageUrls\n  name\n  requiredAmountToRedeem\n  receivedCollection {\n    ...collection_url\n    id\n  }\n  endTime\n}\n\nfragment asset_url on AssetType {\n  assetContract {\n    address\n    id\n  }\n  tokenId\n  chain {\n    identifier\n  }\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "5fc67e7adfc87e42880b5087763e405d";

export default node;
