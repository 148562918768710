import React from "react"
import { Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { Panel } from "@/components/layout/Panel"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { ContentAuthenticity_data$key } from "@/lib/graphql/__generated__/ContentAuthenticity_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { dateFromISO8601, useI18nDateAtTime } from "@/lib/helpers/datetime"

type Props = {
  dataKey: ContentAuthenticity_data$key
}

export const ContentAuthenticity = ({ dataKey }: Props) => {
  const t = useTranslate("adobe")
  const { getBlockExplorerAddressUrl } = useChains()
  const data = useFragment(
    graphql`
      fragment ContentAuthenticity_data on AssetType {
        authenticityMetadata {
          signedOn
          signedBy
          producedWith
          walletAddress
        }
        imageUrl
        creator {
          address
        }
        chain {
          identifier
        }
      }
    `,
    dataKey,
  )

  const signedOnFormatted = useI18nDateAtTime(
    data.authenticityMetadata?.signedOn
      ? dateFromISO8601(data.authenticityMetadata.signedOn)
      : new Date(),
    true,
  )

  if (!data.authenticityMetadata) {
    return null
  }

  const { signedOn, signedBy, producedWith, walletAddress } =
    data.authenticityMetadata

  return (
    <StyledPanel
      bodyClassName="ContentAuthenticity--panel"
      icon="vpn_key"
      id="content-authenticity-panel"
      title={t("adobe.contentCredentialsTitle", "Content credentials")}
    >
      <Block padding="20px">
        {signedBy && (
          <ContentData
            imageUrl="/static/images/logos/adobe.svg"
            label={t("adobe.signedByLabel", "Signed by")}
            tooltip={signedBy}
            value="Adobe"
          />
        )}
        {signedOn && (
          <ContentData
            label={t("adobe.signedOnLabel", "Signed on")}
            value={signedOnFormatted}
          />
        )}
        {producedWith && (
          <ContentData
            imageUrl="/static/images/logos/photoshop.svg"
            label="Produced with"
            tooltip={producedWith}
            value={t("adobe.softwareName", "Adobe Photoshop")}
          />
        )}
        {walletAddress && (
          <ContentData
            label={t("adobe.cryptoWalletLabel", "Crypto wallet")}
            match={walletAddress === data.creator?.address}
            value={
              <Link
                href={getBlockExplorerAddressUrl(
                  data.chain.identifier,
                  walletAddress,
                )}
              >
                {truncateAddress(walletAddress)}
              </Link>
            }
          />
        )}
      </Block>
      <BorderedBlock>
        <Link
          href={`https://verify.contentauthenticity.org/inspect?source=${data.imageUrl}=s0`}
        >
          {t("adobe.viewDetailsCTA", "View details on Verify")}
        </Link>
      </BorderedBlock>
    </StyledPanel>
  )
}

const StyledPanel = styled(Panel)`
  .ContentAuthenticity--panel {
    padding: 0;
  }
`

const BorderedBlock = styled(Flex)`
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};
  justify-content: center;

  padding: 20px 0;
`

const ContentData = ({
  label,
  value,
  imageUrl,
  tooltip,
  match,
}: {
  label: string
  value: React.ReactNode
  imageUrl?: string
  tooltip?: string
  match?: boolean
}) => {
  const t = useTranslate("adobe")
  return (
    <Flex justifyContent="space-between" marginTop="8px">
      <Flex alignItems="center">{label}</Flex>
      <Content>
        {imageUrl ? (
          <Flex marginRight="8px">
            <StyledImage
              alt={`${label} image`}
              height={20}
              src={imageUrl}
              width={20}
            />
          </Flex>
        ) : null}
        <Text
          asChild
          className="mr-1"
          color="secondary"
          size="small"
          weight="semibold"
        >
          <p>{value}</p>
        </Text>
        {match ? (
          <Text
            asChild
            className="ml-1 rounded-default bg-blue-3 p-2"
            color="white"
            size="small"
            weight="semibold"
          >
            <p>{t("adobe.match", "MATCH")}</p>
          </Text>
        ) : null}
        {tooltip ? (
          <InfoIcon
            overrides={{
              Icon: { size: 20 },
            }}
            tooltipContent={tooltip}
          />
        ) : null}
      </Content>
    </Flex>
  )
}

const StyledImage = styled(Image)`
  border-radius: 0;
`

const Content = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: right;
`
