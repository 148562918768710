import React from "react"
import { Flex, SpaceBetween, Text, breakpoints } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { ExternalLink } from "@/components/common/ExternalLink"
import { Link } from "@/components/common/Link"
import { TextCopierButton } from "@/components/common/TextCopierButton.react"
import { TOKEN_STANDARD_TO_NAMES } from "@/constants"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import { useItemFees } from "@/hooks/useItemFees"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetDetails_data$key } from "@/lib/graphql/__generated__/AssetDetails_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { isSolana } from "@/lib/helpers/chainUtils"
import {
  dateFromISO8601,
  useI18nDateAtTime,
  useFromNow,
} from "@/lib/helpers/datetime"
import { truncateText } from "@/lib/helpers/stringUtils"
import { isHttpUrl } from "@/lib/helpers/urls"
import { InfoIcon } from "../common/InfoIcon.react"

type MetadataConfigEntry = {
  tooltipContent: React.ReactNode
  label: React.ReactNode
}

type Props = {
  data: AssetDetails_data$key
  className?: string
}

export const AssetDetails = ({ data: dataKey, className }: Props) => {
  const t = useTranslate("components")
  const { getChainName, getBlockExplorerAddressUrl } = useChains()
  const asset = useFragment(
    graphql`
      fragment AssetDetails_data on AssetType {
        assetContract {
          openseaVersion
          address
          chain
          blockExplorerLink
          tokenStandard
        }
        metadataStatus
        tokenId
        isFrozen
        frozenAt
        tokenMetadata
        lastUpdatedAt
        ...useItemFees_item
      }
    `,
    dataKey,
  )
  const {
    assetContract: {
      address,
      chain,
      openseaVersion,
      blockExplorerLink,
      tokenStandard,
    },

    isFrozen,
    frozenAt,
    tokenId,
    tokenMetadata,
    lastUpdatedAt,
    metadataStatus,
  } = asset

  const lastUpdated = lastUpdatedAt
    ? dateFromISO8601(lastUpdatedAt)
    : new Date()
  const lastUpdatedFormatted = useI18nDateAtTime(lastUpdated)
  const frozenAtText = useI18nDateAtTime(
    frozenAt ? dateFromISO8601(frozenAt) : new Date(),
    true,
  )

  const getMetadataInfo = (): MetadataConfigEntry => {
    switch (metadataStatus) {
      case "FROZEN":
        return {
          tooltipContent: frozenAt
            ? t(
                "assets.details.frozenMetadata.withDate.tooltip",
                "This item's metadata was permanently locked and stored in decentralized file storage on {{frozenAt}}",
                { frozenAt: frozenAtText },
              )
            : t(
                "assets.details.frozenMetadata.tooltip",
                "This item's metadata was permanently locked and stored in decentralized file storage",
              ),
          label: (
            <Link href={tokenMetadata ?? ""}>
              {t("assets.details.frozenMetadata.label", "Frozen")}
            </Link>
          ),
        }
      case "CENTRALIZED":
        return {
          tooltipContent: t(
            "assets.details.centralized.tooltip",
            "This item’s metadata is being hosted on server, but is not editable by the creator",
          ),
          label: t("assets.details.centralized.label", "Centralized"),
        }
      default:
        return { tooltipContent: <></>, label: <></> }
    }
  }

  const metadataInfo = getMetadataInfo()

  const tokenIdText = truncateText(tokenId, 16)
  // If tokenMetadata exists and is a valid url, make token ID a link to tokenMetadata (external metadata).
  // Some token metadata could be a raw JSON string, which we can't display
  // in a new tab as certain browsers (like Chrome) block it.
  const tokenIdValueText =
    tokenMetadata && isHttpUrl(tokenMetadata.toLowerCase()) ? (
      isSolana(chain) ? (
        <ExternalLink href={getBlockExplorerAddressUrl(chain, tokenId)}>
          {tokenIdText}
        </ExternalLink>
      ) : (
        <ExternalLink href={tokenMetadata}>{tokenIdText}</ExternalLink>
      )
    ) : (
      <TextCopierButton placement="right" text={tokenId}>
        {tokenIdText}
      </TextCopierButton>
    )

  const { creatorFeePercentage, creatorRoyaltiesText } = useItemFees(asset)

  const renderFromNow = useFromNow()

  return (
    <Block className={className}>
      <PanelRow>
        {t("assets.details.contractAddress.label", "Contract Address")}
        <ValueText>
          <ExternalLink href={blockExplorerLink}>
            {truncateAddress(address)}
          </ExternalLink>
        </ValueText>
      </PanelRow>
      <PanelRow>
        {t("assets.details.tokenId.label", "Token ID")}
        <ValueText>{tokenIdValueText}</ValueText>
      </PanelRow>
      {tokenStandard && (
        <PanelRow>
          {t("assets.details.tokenStandard.label", "Token Standard")}
          <ValueText>{TOKEN_STANDARD_TO_NAMES[tokenStandard]}</ValueText>
        </PanelRow>
      )}
      <PanelRow>
        {t("assets.details.blockchain.label", "Chain")}
        <ValueText>{getChainName(chain)}</ValueText>
      </PanelRow>
      {(openseaVersion || isFrozen) && (
        <PanelRow>
          {t("assets.details.metadata.label", "Metadata")}
          <Tooltip content={metadataInfo.tooltipContent} placement="right">
            <ValueText>{metadataInfo.label}</ValueText>
          </Tooltip>
        </PanelRow>
      )}
      {lastUpdatedAt && (
        <PanelRow>
          {t("assets.details.lastUpdated.label", "Last Updated")}
          <Tooltip content={lastUpdatedFormatted} placement="right">
            <ValueText>{renderFromNow(lastUpdated)}</ValueText>
          </Tooltip>
        </PanelRow>
      )}
      {creatorFeePercentage && creatorRoyaltiesText && (
        <PanelRow>
          <Flex className="items-center">
            <Block>
              {t("assets.details.creatorEarnings.label", "Creator Earnings")}
            </Block>
            <InfoIcon
              overrides={{
                Button: {
                  style: {
                    marginLeft: "4px",
                    verticalAlign: "middle",
                  },
                },
                Icon: {
                  size: 14,
                },
              }}
              tooltipContent={creatorRoyaltiesText}
            />
          </Flex>
          <ValueText>{creatorFeePercentage}</ValueText>
        </PanelRow>
      )}
    </Block>
  )
}

const PanelRow = ({ children }: { children: React.ReactNode }) => (
  <SpaceBetween className="mt-2">{children}</SpaceBetween>
)

const ValueText = styled(Text).attrs({ size: "small" })`
  margin: 0;
  max-width: 365px;
  text-align: right;
  @media (max-width: ${breakpoints.sm}px) {
    max-width: 200px;
  }
`
