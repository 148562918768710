import React from "react"
import { useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { PriceText } from "@/design-system/PriceText"
import { Property_collection$key } from "@/lib/graphql/__generated__/Property_collection.graphql"
import { Property_trait$key } from "@/lib/graphql/__generated__/Property_trait.graphql"
import { Property_traitWithFloorPrice$key } from "@/lib/graphql/__generated__/Property_traitWithFloorPrice.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getBrowseCollectionAssetsUrl } from "@/lib/helpers/collection"
import { calculatePercentages } from "@/lib/helpers/numberUtils"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import { PropertyCell } from "./PropertyCell.react"

type Props = {
  className?: string
  disablePercentages?: boolean
  collection: Property_collection$key
  trait: Property_trait$key
  traitWithFloorPrice: Property_traitWithFloorPrice$key | null
}

export const Property = ({
  className,
  disablePercentages,
  collection: collectionKey,
  trait: traitKey,
  traitWithFloorPrice: traitWithFloorPriceKey,
}: Props) => {
  // const t = useTranslate("traits")
  const collection = useFragment(
    graphql`
      fragment Property_collection on CollectionType {
        slug
        ...collection_url
        statsV2 {
          totalSupply
        }
      }
    `,
    collectionKey,
  )

  const trait = useFragment(
    graphql`
      fragment Property_trait on TraitType {
        traitCount
        traitType
        value
      }
    `,
    traitKey,
  )

  const traitWithFloorPrice = useFragment<Property_traitWithFloorPrice$key>(
    graphql`
      fragment Property_traitWithFloorPrice on TraitType {
        floorPrice {
          unit
          symbol
        }
      }
    `,
    traitWithFloorPriceKey,
  )

  if (!trait.value) {
    return null
  }

  const { value, traitType, traitCount } = trait
  const {
    statsV2: { totalSupply },
  } = collection

  return (
    <Link
      href={`${getBrowseCollectionAssetsUrl(collection)}${stringifyQueryParams({
        search: {
          stringTraits: [{ name: traitType, values: [value] }],
          sortAscending: true,
          sortBy: "UNIT_PRICE",
        },
      })}`}
      variant="unstyled"
    >
      <PropertyCell
        className={className}
        count={traitCount || undefined}
        disablePercentages={
          disablePercentages ||
          TRAIT_PERCENTAGE_DISABLED_COLLECTIONS.has(collection.slug)
        }
        floorPrice={
          <PriceText
            className="text-secondary"
            size="small"
            symbol={traitWithFloorPrice?.floorPrice?.symbol}
            unit={traitWithFloorPrice?.floorPrice?.unit}
          />
        }
        percentage={
          traitCount ? calculatePercentages(traitCount, totalSupply) : undefined
        }
        type={traitType}
        value={value}
      />
    </Link>
  )
}

const TRAIT_PERCENTAGE_DISABLED_COLLECTIONS = new Set(["physical-1"])
