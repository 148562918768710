import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { AssetProps } from "@/features/live-updates/components/AssetPageSubscriptions/AssetPageSubscriptions.react"
import {
  LISTING_EVENT,
  updateAssetStoreWithListingEventData,
} from "@/features/live-updates/utils"
import { useAssetPageListingEventSubscription } from "@/lib/graphql/__generated__/useAssetPageListingEventSubscription.graphql"

export const useAssetPageListingEvent = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  const config: GraphQLSubscriptionConfig<useAssetPageListingEventSubscription> =
    useMemo(
      () => ({
        variables: { slug, relayId },
        subscription: graphql`
          subscription useAssetPageListingEventSubscription(
            $slug: String!
            $relayId: String!
          ) {
            listingEvent(slug: $slug, itemRelayIds: [$relayId]) {
              __typename
              ... on ListingEvent {
                relayId
                item {
                  orderData {
                    bestAskV2 {
                      closedAt
                      orderType
                      relayId
                      maker {
                        ...wallet_accountKey
                      }
                      ...Orders_orders @arguments(isSingleAsset: true)
                      ...useFulfillSemiFungibleOrders_orders
                      ...BuyNowButton_orders
                      ...TradeStationOrderPrice_order
                      ...TradeStation_bestAsk
                    }
                  }
                }
              }
            }
          }
        `,
        updater: (store, data) => {
          if (data.listingEvent.__typename !== LISTING_EVENT) {
            return
          }
          updateAssetStoreWithListingEventData(
            store,
            data,
            contractAddress,
            chain,
            tokenId,
          )
        },
      }),
      [slug, relayId, contractAddress, chain, tokenId],
    )
  useSubscription<useAssetPageListingEventSubscription>(config)
}
