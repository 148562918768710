import React from "react"
import {
  Icon,
  MaterialIcon,
  UnstyledButton,
  Text,
  CenterAligned,
} from "@opensea/ui-kit"
import BigNumber from "bignumber.js"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { quantityDisplay, shortSymbolDisplay } from "@/lib/helpers/numberUtils"

type CountOptions = {
  unit?: "favorite" | "owner" | "items" | "view" | ""
  prefix?: "You own" | ""
  onClick?: () => void
  pluralize?: boolean
  tooltipPrefix?: string
  "aria-label"?: string
}

type CountProps = {
  icon: MaterialIcon
  count: BigNumber
  options: CountOptions
}

export const Count = ({
  icon,
  count,
  options: {
    unit = "",
    prefix = "",
    onClick,
    pluralize: shouldPluralize = true,
    tooltipPrefix,
    "aria-label": ariaLabel,
  },
}: CountProps) => {
  const t = useTranslate("components")
  if (count.isZero()) {
    return null
  }

  const countAsNumber = count.toNumber()

  const localizedNonPluralUnits: Record<
    Exclude<CountOptions["unit"], undefined | "">,
    string
  > = {
    favorite: t("count.units.favorite.default", "favorite"),
    owner: t("count.units.owner.default", "owner"),
    items: t("count.units.items.default", "items"),
    view: t("count.units.view.default", "view"),
  }
  const localizedPluralUnits: Record<
    Exclude<CountOptions["unit"], undefined | "">,
    string
  > = {
    favorite: t(
      "count.units.favorite.count",
      {
        0: "favorites",
        one: "favorite",
        other: "favorites",
      },
      { count: countAsNumber },
      { forceString: true },
    ),
    owner: t(
      "count.units.owner.count",
      {
        0: "owners",
        one: "owner",
        other: "owners",
      },
      { count: countAsNumber },
      { forceString: true },
    ),
    items: t(
      "count.units.items.count",
      {
        0: "items",
        one: "item",
        other: "items",
      },
      { count: countAsNumber },
      { forceString: true },
    ),
    view: t(
      "count.units.view.count",
      {
        0: "views",
        one: "view",
        other: "views",
      },
      { count: countAsNumber },
      { forceString: true },
    ),
  }

  const suffix: string = !unit
    ? ""
    : shouldPluralize
    ? localizedPluralUnits[unit]
    : localizedNonPluralUnits[unit]

  const tooltipContent = count.isGreaterThanOrEqualTo(1000)
    ? `${tooltipPrefix ?? prefix} ${quantityDisplay(count)} ${suffix}`
    : undefined

  const content = `${prefix} ${shortSymbolDisplay(
    count.toNumber(),
  )} ${suffix}`.trim()

  return (
    <Tooltip content={tooltipContent}>
      <StyledBlock
        alignItems="center"
        aria-label={ariaLabel}
        as={onClick ? UnstyledButton : undefined}
        display="flex"
        marginRight="24px"
        onClick={onClick}
      >
        <CenterAligned className="mr-1.5">
          <Icon value={icon} />
        </CenterAligned>
        <Text size="small">{content}</Text>
      </StyledBlock>
    </Tooltip>
  )
}

const StyledBlock = styled(Block)`
  ${props => props.onClick && interactiveStylesPrimary}
`
