import React from "react"
import { Flex, Icon, IconProps } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { IS_SERVER } from "@/constants/environment"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Dropdown } from "@/design-system/Dropdown"
import { IconButton } from "@/design-system/IconButton"
import { Tooltip } from "@/design-system/Tooltip"
import { AssetDealLink } from "@/features/deals/components/AssetDealLink"
import { ReportModal } from "@/features/report/components/ReportModal"
import {
  trackOpenReportModal,
  trackSubmitAssetReport,
} from "@/features/report/utils/analytics"
import { COMPROMISED_ACCOUNT_ACTION_DISABLED } from "@/features/settings/constants/messages"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackOpenShareDropdown,
  trackRefreshMetadata,
} from "@/lib/analytics/events/itemEvents"
import { Toolbar_asset$key } from "@/lib/graphql/__generated__/Toolbar_asset.graphql"
import { ToolbarAssetRefreshMutation } from "@/lib/graphql/__generated__/ToolbarAssetRefreshMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { getAssetUrl } from "@/lib/helpers/asset"
import { Share } from "./Share.react"

type Props = {
  asset: Toolbar_asset$key
  hideCreateDeal?: boolean
  hideTransfer?: boolean
}

export const Toolbar = ({
  asset: assetKey,
  hideCreateDeal,
  hideTransfer,
}: Props) => {
  const t = useTranslate("components")
  const asset = useFragment(
    graphql`
      fragment Toolbar_asset on AssetType {
        ...asset_url
        ...AssetDealLink_asset
        ...itemEvents_data
        assetContract {
          address
        }
        collection {
          externalUrl
        }
        externalLink
        relayId
      }
    `,
    assetKey,
  )

  const { attempt, showSuccessMessage } = useToasts()
  const { mutate } = useGraphQL()
  const {
    isOpen: reportModalOpen,
    close: closeReportModal,
    open: openReportModal,
  } = useIsOpen()
  const activeAccount = useActiveAccount()

  const isCompromised = activeAccount?.isCompromised ?? false
  const isIcecap =
    asset.assetContract.address === "0x3fa2bc8c8aa9e2706c3b5c17359a104aa412e3cc"
  let externalURL = asset.externalLink || asset.collection.externalUrl
  // (prod-43) To handle url without protocol.
  if (externalURL) {
    try {
      new URL(externalURL)
    } catch (e) {
      externalURL = `https://${externalURL}`
    }
  }
  const externalLinkText =
    isIcecap && asset.externalLink
      ? t("toolbar.viewCertificate", "View GIA Certificate")
      : t("toolbar.viewWebsite", "View website")

  const transferButtonContent = isCompromised
    ? COMPROMISED_ACCOUNT_ACTION_DISABLED
    : t("toolbar.transfer", "Transfer")

  const refreshMetadata = async () => {
    trackRefreshMetadata(asset)
    await attempt(async () => {
      await mutate<ToolbarAssetRefreshMutation>(
        graphql`
          mutation ToolbarAssetRefreshMutation($asset: AssetRelayID!) {
            assets {
              refresh(asset: $asset)
            }
          }
        `,
        { asset: asset.relayId },
      )
      showSuccessMessage(
        t(
          "toolbar.queued",
          "We've queued this item for an update! Check back in a minute...",
        ),
      )
    })
  }

  const renderReportModal = () => {
    return (
      <ReportModal
        isOpen={reportModalOpen}
        subject={{ asset: asset.relayId }}
        onClose={closeReportModal}
        onSubmit={({ additionalComments, originalCreatorUrl, reason }) => {
          trackSubmitAssetReport(asset, {
            additionalComments,
            originalCreatorUrl,
            reason,
          })
        }}
      />
    )
  }

  const renderShareOptionsDropdown = () => {
    return (
      <Share
        object="item"
        url={getAssetUrl(asset)}
        onTrigger={() => trackOpenShareDropdown(asset)}
      >
        <Tooltip content={t("share.share", "Share")}>
          <IconButton>
            <StyledIcon size={20} value="ios_share" />
          </IconButton>
        </Tooltip>
      </Share>
    )
  }

  const renderMoreOptionsDropdown = () => {
    return (
      <Dropdown
        appendTo={IS_SERVER ? undefined : document.body}
        content={({ close, List, Item }) => (
          <List>
            <Item
              onClick={() => {
                refreshMetadata()
                close()
              }}
            >
              <Item.Avatar icon="cached" />
              <Item.Content>
                <Item.Title>
                  {t("toolbar.refreshMetadata", "Refresh metadata")}
                </Item.Title>
              </Item.Content>
            </Item>
            {externalURL && (
              <Item href={externalURL} onClick={() => close()}>
                <Item.Avatar icon="language" />
                <Item.Content>
                  <Item.Title>{externalLinkText}</Item.Title>
                </Item.Content>
              </Item>
            )}
            <Item
              onClick={() => {
                openReportModal()
                close()
                trackOpenReportModal(asset)
              }}
            >
              <Item.Avatar icon="flag" />
              <Item.Content>
                <Item.Title>{t("toolbar.report", "Report")}</Item.Title>
              </Item.Content>
            </Item>
          </List>
        )}
        placement="bottom-end"
      >
        <Tooltip content={t("toolbar.more", "More")}>
          <IconButton aria-label={t("toolbar.more", "More")}>
            <StyledIcon value="more_horiz" />
          </IconButton>
        </Tooltip>
      </Dropdown>
    )
  }

  const renderCreateDealButton = () => {
    if (hideCreateDeal) {
      return null
    }
    return (
      <AssetDealLink asset={asset}>
        <IconButton>
          <StyledIcon value="handshake" />
        </IconButton>
      </AssetDealLink>
    )
  }

  const renderTransferButton = () => {
    if (hideTransfer) {
      return null
    }
    return (
      <Tooltip content={transferButtonContent} touch={false}>
        <IconButton
          aria-label="Transfer"
          disabled={isCompromised}
          href={getAssetUrl(asset, "transfer")}
        >
          <StyledIcon value="send" />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Flex>
      {renderCreateDealButton()}
      {renderTransferButton()}
      {renderShareOptionsDropdown()}
      {renderMoreOptionsDropdown()}
      {renderReportModal()}
    </Flex>
  )
}

const StyledIcon = styled(Icon).attrs<IconProps>({
  color: "primary",
})`
  margin-right: 0px;
  font-weight: 600;
`
