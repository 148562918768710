import React from "react"
import {
  Flex,
  FlexColumn,
  Media,
  SpaceBetween,
  Text,
  classNames,
} from "@opensea/ui-kit"
import { graphql, useFragment, usePaginationFragment } from "react-relay"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { TradeStationSellTab_asset$key } from "@/lib/graphql/__generated__/TradeStationSellTab_asset.graphql"
import { TradeStationSellTab_bestOffers$key } from "@/lib/graphql/__generated__/TradeStationSellTab_bestOffers.graphql"
import { TradeStationSellTabBestOffersPaginationQuery } from "@/lib/graphql/__generated__/TradeStationSellTabBestOffersPaginationQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn, display } from "@/lib/helpers/numberUtils"
import { CTA_BUTTON_PROPS_OVERRIDE, ORDER_PAGE_SIZE } from "../constants"
import { useFulfillSemiFungibleOrders } from "../hooks/useFulfillSemiFungibleOrders"
import { SemiFungibleBulkSaleParams } from "../types"
import {
  trackClickAcceptOffers,
  trackClickAcceptOffersAdd,
  trackClickAcceptOffersRemove,
  trackClickAcceptOffersSubstituteItems,
} from "./AcceptOffersButton/analytics"
import { AssetListButton } from "./AssetListButton.react"
import { TradeStationOrderPrice } from "./TradeStationOrderPrice.react"
import { TradeStationPrice } from "./TradeStationPrice.react"

type SellTabProps = {
  asset: TradeStationSellTab_asset$key
  bestOffers: TradeStationSellTab_bestOffers$key
}

export const TradeStationSellTab = ({
  asset: assetDataKey,
  bestOffers: bestOffersDataKey,
}: SellTabProps) => {
  const t = useTranslate("components")

  const asset = useFragment(
    graphql`
      fragment TradeStationSellTab_asset on AssetType {
        ownedQuantity(identity: {})
        tradeSummary {
          bestBid {
            ...TradeStationOrderPrice_order
          }
        }
        ...useFulfillSemiFungibleOrders_asset
        ...AssetListButton_asset
        # eslint-disable-next-line relay/must-colocate-fragment-spreads - Used for amplitude logging
        ...itemEvents_dataV2
      }
    `,
    assetDataKey,
  )

  const {
    data: bestOffersData,
    loadNext: loadMoreOrders,
    isLoadingNext: isLoadingMoreOrders,
    hasNext: hasMoreOrders,
  } = usePaginationFragment<
    TradeStationSellTabBestOffersPaginationQuery,
    TradeStationSellTab_bestOffers$key
  >(
    graphql`
      fragment TradeStationSellTab_bestOffers on Query
      @argumentDefinitions(
        pageSize: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        tokenId: { type: "String!" }
        contractAddress: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      )
      @refetchable(queryName: "TradeStationSellTabBestOffersPaginationQuery") {
        nft(
          tokenId: $tokenId
          contractAddress: $contractAddress
          chain: $chain
        ) {
          ... on AssetType {
            bestOffers(first: $pageSize, after: $cursor, forTaker: {})
              @connection(key: "TradeStationSellTab_bestOffers") {
              edges {
                node {
                  ...useFulfillSemiFungibleOrders_orders
                }
              }
            }
          }
        }
      }
    `,
    bestOffersDataKey,
  )

  const bestOffers = getNodes(bestOffersData.nft.bestOffers)

  const {
    renderFulfillButton: renderAcceptOffersButton,
    renderSubstituteItems,
    renderQuantitySelector,
    totalPrice,
    totalPriceLimit: maxTotalPrice,
    averagePrice,
    quantity,
    fulfillQuantity,
    isSubstitutionEnabled,
    pricePerItemLimit,
  } = useFulfillSemiFungibleOrders({
    orders: bestOffers,
    asset,
    side: "SELL",
    hasMoreOrders,
    isLoadingMoreOrders,
    loadMoreOrders: () => loadMoreOrders(ORDER_PAGE_SIZE),
  })

  const analyticsParams: SemiFungibleBulkSaleParams = {
    isSubstitutionEnabled,
    pricePerItemLimit,
    quantity: fulfillQuantity?.toNumber() ?? 1,
  }

  const bestOffer = asset.tradeSummary.bestBid
  const ownedQuantity = bn(asset.ownedQuantity ?? 0)

  const viewerCanSellImmediately =
    ownedQuantity.isGreaterThan(0) && !!bestOffers.length

  const priceLabel = maxTotalPrice
    ? t("trade.station.minOfferValue", "Min offer value")
    : fulfillQuantity?.isGreaterThan(1)
    ? t("trade.station.totalOfferValue", "Total offer value")
    : bestOffer
    ? t("trade.station.bestOfferValue", "Best offer value")
    : undefined

  return (
    <FlexColumn asChild className="w-full gap-2 p-4">
      <form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        {priceLabel && (
          <SpaceBetween>
            <Block marginBottom="8px">
              <Text.Body className="mb-2 block text-secondary" size="small">
                {priceLabel}
              </Text.Body>
              {totalPrice ? (
                <TradeStationPrice
                  maxTotalPrice={maxTotalPrice}
                  totalPrice={totalPrice}
                />
              ) : bestOffer ? (
                <TradeStationOrderPrice order={bestOffer} />
              ) : null}
              {quantity?.isGreaterThan(1) && (
                <Text asChild className="mt-2 text-secondary" size="small">
                  <div>
                    {t(
                      "trade.station.offerValue.average",
                      "Average offer value: {{value}} {{symbol}}",
                      {
                        value: averagePrice ? display(averagePrice) : "",
                        symbol: totalPrice?.tokenPricePayment.symbol ?? "",
                      },
                    )}
                  </div>
                </Text>
              )}
            </Block>
            <Media greaterThanOrEqual="lg">
              {renderQuantitySelector({
                onClickAdd: trackClickAcceptOffersAdd,
                onClickRemove: trackClickAcceptOffersRemove,
                className: "my-2 max-w-[192px]",
                overrides: {
                  Input: {
                    props: {
                      height: "48px",
                    },
                  },
                },
              })}
            </Media>
          </SpaceBetween>
        )}

        {viewerCanSellImmediately &&
          renderSubstituteItems({
            overrides: {
              Checkbox: {
                props: {
                  onCheckedChange: () =>
                    trackClickAcceptOffersSubstituteItems(
                      asset,
                      analyticsParams,
                    ),
                },
              },
            },
          })}

        <Media lessThan="lg">
          {renderQuantitySelector({
            className: "w-full",
            overrides: { FirstRowContainer: { className: "w-full" } },
          })}
        </Media>

        <Flex className="flex-wrap gap-x-4 gap-y-2">
          {viewerCanSellImmediately &&
            renderAcceptOffersButton({
              onClick: () => trackClickAcceptOffers(asset, analyticsParams),
              overrides: {
                Button: { props: { className: classNames("w-full") } },
                ButtonContainer: { props: CTA_BUTTON_PROPS_OVERRIDE },
              },
            })}

          <AssetListButton
            {...CTA_BUTTON_PROPS_OVERRIDE}
            asset={asset}
            className={CTA_BUTTON_PROPS_OVERRIDE.className}
            disabled={quantity === undefined}
            quantity={quantity}
            variant={viewerCanSellImmediately ? "secondary" : "primary"}
          >
            {t(
              "trade.station.listItems",
              {
                "0": "List {{displayCount}} items",
                one: "List {{displayCount}} item",
                other: "List {{displayCount}} items",
              },
              {
                count: quantity?.toNumber() ?? 1,
                displayCount: display(quantity ?? 1),
              },
            )}
          </AssetListButton>

          {!priceLabel && (
            <Media greaterThanOrEqual="lg">
              {mediaClassNames =>
                renderQuantitySelector({
                  ...CTA_BUTTON_PROPS_OVERRIDE,
                  className: mediaClassNames,
                })
              }
            </Media>
          )}
        </Flex>
      </form>
    </FlexColumn>
  )
}
