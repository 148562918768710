import React, { Suspense } from "react"
import type { BlockProps } from "@/design-system/Block"
import { Modal, UncontrolledModalProps } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import type { ItemOwnersListLazyQuery } from "@/lib/graphql/__generated__/ItemOwnersListLazyQuery.graphql"
import {
  ItemOwnersListSkeleton,
  ItemsOwnersLazyList,
} from "./ItemOwnersList.react"

type ItemOwnersModalContentProps = ItemOwnersListLazyQuery["variables"] & {
  numOwners?: number
}

const modalBodyProps: BlockProps = {
  height: "50vh",
  maxHeight: 600,
  padding: 0,
  as: Modal.Body,
}

const ItemOwnersModalContent = ({
  numOwners,
  ...rest
}: ItemOwnersModalContentProps) => {
  const t = useTranslate("owners")
  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>{t("owners.title", "Owned by")}</Modal.Header.Title>
      </Modal.Header>

      <Suspense
        fallback={
          <ItemOwnersListSkeleton
            count={numOwners}
            overrides={{ Root: { props: modalBodyProps } }}
          />
        }
      >
        <ItemsOwnersLazyList
          {...rest}
          overrides={{ Root: { props: modalBodyProps } }}
        />
      </Suspense>
    </>
  )
}

export type ItemOwnersModalProps = ItemOwnersModalContentProps &
  Pick<UncontrolledModalProps, "trigger">

export const ItemOwnersModal = ({ trigger, ...rest }: ItemOwnersModalProps) => {
  return (
    <Modal trigger={trigger}>
      <ItemOwnersModalContent {...rest} />
    </Modal>
  )
}
