import React from "react"
import { graphql, useFragment } from "react-relay"
import { OrderToQuantity } from "@/components/trade/BulkPurchaseModal"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { BulkAcceptOffersActionModal } from "@/features/bulk-accept-offer/components/BulkAcceptOffersActionModal"
import { BulkAcceptOffersModal } from "@/features/bulk-accept-offer/components/BulkAcceptOffersModal"
import { readOptionalCreatorFees } from "@/features/orders/components/AcceptOfferModalContent/readOptionalCreatorFees"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { TotalPricePerSymbol } from "@/features/shopping-cart/hooks/useTotalPrice"
import { useTranslate } from "@/hooks/useTranslate"
import { AcceptOffersButton_asset$key } from "@/lib/graphql/__generated__/AcceptOffersButton_asset.graphql"
import {
  AcceptOffersButton_orders$data,
  AcceptOffersButton_orders$key,
} from "@/lib/graphql/__generated__/AcceptOffersButton_orders.graphql"
import { OfferToAcceptInputType } from "@/lib/graphql/__generated__/BulkAcceptOffersActionModalQuery.graphql"
import { display } from "@/lib/helpers/numberUtils"
import { SemiFungibleFulfillButtonProps } from "../../types"
import {
  CreatorFeeInputModalContent,
  CreatorFeeInputModalContentProps,
} from "./CreatorFeeInputModalContent.react"

const getOffersToAccept = ({
  orders,
  orderToQuantity,
  criteriaAsset,
  optionalCreatorFeeBasisPoints,
  maxBasisPoints,
}: {
  orderToQuantity: OrderToQuantity
  orders: AcceptOffersButton_orders$data
  criteriaAsset: string
  optionalCreatorFeeBasisPoints?: number
  maxBasisPoints: number
}): OfferToAcceptInputType[] => {
  return orders.flatMap(order => {
    const { getCorrectedOptionalFeeBasisPointsForAcceptingOrder } =
      readOrderFees(order)

    const correctedOptionalCreatorFeeBasisPoints = optionalCreatorFeeBasisPoints
      ? getCorrectedOptionalFeeBasisPointsForAcceptingOrder({
          suppliedBasisPoints: optionalCreatorFeeBasisPoints,
          maxBasisPoints,
        })
      : undefined

    if (orderToQuantity[order.relayId]) {
      return {
        order: order.relayId,
        itemFillAmount: orderToQuantity[order.relayId].toString(),
        criteriaAsset,
        optionalCreatorFeeBasisPoints:
          correctedOptionalCreatorFeeBasisPoints?.toString(),
      }
    }
    return []
  })
}

type AcceptOffersButtonProps = SemiFungibleFulfillButtonProps &
  Omit<
    CreatorFeeInputModalContentProps,
    "orders" | "asset" | "onSubmit" | "renderNext" | "totalPrice"
  > & {
    orders: AcceptOffersButton_orders$key
    asset: AcceptOffersButton_asset$key
    orderToQuantity: OrderToQuantity
    totalPrice: TotalPricePerSymbol[string]
  }

export const AcceptOffersButton = ({
  disabled,
  isLoading,
  orders: ordersDataKey,
  asset: assetDataKey,
  orderToQuantity,
  overrides,
  quantity,
  totalPrice,
  minTotalPrice,
  onClick,
}: AcceptOffersButtonProps) => {
  const t = useTranslate("components")

  const orders = useFragment(
    graphql`
      fragment AcceptOffersButton_orders on OrderV2Type @relay(plural: true) {
        relayId
        ...readOrderFees_order
        ...CreatorFeeInputModalContent_orders
      }
    `,
    ordersDataKey,
  )

  const asset = useFragment(
    graphql`
      fragment AcceptOffersButton_asset on AssetType {
        relayId
        ...readOptionalCreatorFees_item
        ...CreatorFeeInputModalContent_asset
      }
    `,
    assetDataKey,
  )

  const isSubstitutionEnabled = minTotalPrice !== undefined

  const { getShowOptionalCreatorFee, maxBasisPoints } =
    readOptionalCreatorFees(asset)

  const canApplyOptionalFees = orders.reduce((acc, order) => {
    const { getCanApplyOptionalFees } = readOrderFees(order)
    return acc || getCanApplyOptionalFees(maxBasisPoints)
  }, false)

  const showOptionalCreatorFee = getShowOptionalCreatorFee(canApplyOptionalFees)

  const renderBulkAcceptOfferModal = (close: () => void) => {
    const offersToAccept = getOffersToAccept({
      orders,
      orderToQuantity,
      criteriaAsset: asset.relayId,
      maxBasisPoints,
    })

    return (
      <BulkAcceptOffersActionModal
        maxQuantityToFill={
          isSubstitutionEnabled ? quantity.toString() : undefined
        }
        offersToAccept={offersToAccept}
        onClose={close}
      />
    )
  }

  const renderCreatorFeeInputModal = (close: () => void) =>
    totalPrice && (
      <CreatorFeeInputModalContent
        asset={asset}
        minTotalPrice={minTotalPrice}
        orders={orders}
        quantity={quantity}
        renderNext={({ optionalCreatorFeeBasisPoints }) => {
          const offersToAccept = getOffersToAccept({
            orders,
            orderToQuantity,
            criteriaAsset: asset.relayId,
            optionalCreatorFeeBasisPoints,
            maxBasisPoints,
          })

          return (
            <BulkAcceptOffersActionModal
              maxQuantityToFill={
                isSubstitutionEnabled ? quantity.toString() : undefined
              }
              offersToAccept={offersToAccept}
              onClose={close}
            />
          )
        }}
        totalPrice={totalPrice}
      />
    )

  return (
    <MultiStepModal
      trigger={open => (
        <BulkAcceptOffersModal.Trigger
          disabled={disabled}
          isLoading={isLoading}
          type="submit"
          onClick={() => {
            open()
            onClick?.()
          }}
          {...overrides?.Button?.props}
        >
          {t(
            "trade.acceptOffers.cta",
            {
              "0": "Accept {{displayCount}} offers",
              one: "Accept {{displayCount}} offer",
              other: "Accept {{displayCount}} offers",
            },
            {
              count: quantity.toNumber(),
              displayCount: display(quantity),
            },
          )}
        </BulkAcceptOffersModal.Trigger>
      )}
    >
      {showOptionalCreatorFee
        ? renderCreatorFeeInputModal
        : renderBulkAcceptOfferModal}
    </MultiStepModal>
  )
}
