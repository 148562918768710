/**
 * @generated SignedSource<<d93c56ec5007947bf16c98dba60bd234>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcceptOfferDisabledAlert_asset$data = {
  readonly acceptOfferDisabled: {
    readonly " $fragmentSpreads": FragmentRefs<"useAcceptOfferDisabledReason_data">;
  } | null;
  readonly decimals: number | null;
  readonly ownedQuantity: string | null;
  readonly " $fragmentType": "AcceptOfferDisabledAlert_asset";
};
export type AcceptOfferDisabledAlert_asset$key = {
  readonly " $data"?: AcceptOfferDisabledAlert_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferDisabledAlert_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptOfferDisabledAlert_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "identity",
          "value": {}
        }
      ],
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": "ownedQuantity(identity:{})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AcceptOfferDisabledType",
      "kind": "LinkedField",
      "name": "acceptOfferDisabled",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useAcceptOfferDisabledReason_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "7b30f9b8096b709ab9f6177368b85255";

export default node;
