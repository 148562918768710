/**
 * @generated SignedSource<<ff757eb7e83179054f43c30c81743ab4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFulfillSemiFungibleOrders_orders$data = ReadonlyArray<{
  readonly payment: {
    readonly symbol: string;
  };
  readonly perUnitPriceType: {
    readonly unit: string;
  };
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOffersButton_orders" | "BuyNowButton_orders" | "ListingFeesSupportsCreator_orders" | "useOrdersWithValidMakerOwnedQuantity_order" | "useTotalPrice_orders">;
  readonly " $fragmentType": "useFulfillSemiFungibleOrders_orders";
}>;
export type useFulfillSemiFungibleOrders_orders$key = ReadonlyArray<{
  readonly " $data"?: useFulfillSemiFungibleOrders_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFulfillSemiFungibleOrders_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceType",
  "kind": "LinkedField",
  "name": "perUnitPriceType",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingQuantityType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useFulfillSemiFungibleOrders_orders",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useOrdersWithValidMakerOwnedQuantity_order",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "makerOwnedQuantity",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountType",
          "kind": "LinkedField",
          "name": "maker",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTotalPrice_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BuyNowButton_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptOffersButton_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ListingFeesSupportsCreator_orders"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "612d695a73fc1009e98168153bb3ab7c";

export default node;
