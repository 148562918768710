/**
 * @generated SignedSource<<d0a7378422089276570c4d164b82df54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Property_trait$data = {
  readonly traitCount: number | null;
  readonly traitType: string;
  readonly value: string | null;
  readonly " $fragmentType": "Property_trait";
};
export type Property_trait$key = {
  readonly " $data"?: Property_trait$data;
  readonly " $fragmentSpreads": FragmentRefs<"Property_trait">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Property_trait",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traitType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "TraitType",
  "abstractKey": null
};

(node as any).hash = "210fc27397505a581df74b93b8c9c46e";

export default node;
