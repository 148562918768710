/**
 * @generated SignedSource<<9768588740ce3d6da8b569bcb51ba95b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RedeemableItemCard_redeemableCampaign$data = {
  readonly campaignId: number;
  readonly endTime: string;
  readonly imageUrls: ReadonlyArray<string>;
  readonly name: string | null;
  readonly receivedCollection: {
    readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  } | null;
  readonly requiredAmountToRedeem: string;
  readonly " $fragmentType": "RedeemableItemCard_redeemableCampaign";
};
export type RedeemableItemCard_redeemableCampaign$key = {
  readonly " $data"?: RedeemableItemCard_redeemableCampaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"RedeemableItemCard_redeemableCampaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RedeemableItemCard_redeemableCampaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "campaignId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrls",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredAmountToRedeem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "receivedCollection",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "collection_url",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCategory",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    }
  ],
  "type": "RedeemableCampaignType",
  "abstractKey": null
};

(node as any).hash = "7834f9e18ca501b0054cf24f5b00a9b1";

export default node;
