/**
 * @generated SignedSource<<1aeba8d91c5ad28003938edc665177bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TradeStationOrderPrice_order$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrderPrice" | "OrderUsdPrice">;
  readonly " $fragmentType": "TradeStationOrderPrice_order";
};
export type TradeStationOrderPrice_order$key = {
  readonly " $data"?: TradeStationOrderPrice_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStationOrderPrice_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeStationOrderPrice_order",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderUsdPrice"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "3a206bbe8b5d33a749e7a519a8b57f64";

export default node;
