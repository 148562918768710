/**
 * @generated SignedSource<<60122fcd08affb97f332b0f54d4be282>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OrderV2Side = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useOrdersWithValidMakerOwnedQuantity_order$data = {
  readonly maker: {
    readonly relayId: string;
  };
  readonly makerOwnedQuantity: string;
  readonly perUnitPriceType: {
    readonly unit: string;
  };
  readonly remainingQuantityType: string;
  readonly side: OrderV2Side | null;
  readonly " $fragmentType": "useOrdersWithValidMakerOwnedQuantity_order";
};
export type useOrdersWithValidMakerOwnedQuantity_order$key = {
  readonly " $data"?: useOrdersWithValidMakerOwnedQuantity_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOrdersWithValidMakerOwnedQuantity_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useOrdersWithValidMakerOwnedQuantity_order"
};

(node as any).hash = "6f41bdb05e56332fb8ce16be3504aeea";

export default node;
