import React, { memo } from "react"
import { Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { Item as ItemComponent } from "@/design-system/Item"
import { ItemSkeleton } from "@/design-system/ItemSkeleton"
import { List } from "@/design-system/List"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountItem_data$key } from "@/lib/graphql/__generated__/AccountItem_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getAccountUrl } from "@/lib/helpers/accounts"
import { truncateAddress } from "@/lib/helpers/address"
import AccountBadge from "../AccountBadge.react"

type Props = {
  Item?: typeof ItemComponent | typeof List.Item
  side?: React.ReactNode
  dataKey: AccountItem_data$key
}

const AccountItemBase = memo(function AccountItem({
  Item = ItemComponent,
  dataKey,
  side,
}: Props) {
  const t = useTranslate("components")
  const account = useFragment(
    graphql`
      fragment AccountItem_data on AccountType {
        ...accounts_url
        isCompromised
        imageUrl
        displayName
        config
        address
      }
    `,
    dataKey,
  )

  const { displayName, address, imageUrl, config, isCompromised } = account

  return (
    <Item href={getAccountUrl(account)}>
      {imageUrl ? (
        <Item.Avatar rounded src={imageUrl} />
      ) : (
        <Item.Avatar icon="account_circle" />
      )}
      <Item.Content>
        <Item.Title>
          <Flex className="items-center">
            {displayName || t("common.accountItem.defaultName", "Unnamed")}
            <AccountBadge config={config} isCompromised={isCompromised} />
          </Flex>
        </Item.Title>
        <Item.Description>{truncateAddress(address)}</Item.Description>
      </Item.Content>
      {side && <Item.Side>{side}</Item.Side>}
    </Item>
  )
})

type AccountItemSkeletonProps = {
  sideTitle?: boolean
  sideDescription?: boolean
}

const AccountItemSkeleton = memo(function AccountItemSkeleton({
  sideTitle,
  sideDescription,
}: AccountItemSkeletonProps) {
  return (
    <ItemSkeleton>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
        <ItemSkeleton.Description />
      </ItemSkeleton.Content>
      {(sideTitle || sideDescription) && (
        <ItemSkeleton.Side>
          {sideTitle && <ItemSkeleton.Title />}
          {sideDescription && <ItemSkeleton.Description />}
        </ItemSkeleton.Side>
      )}
    </ItemSkeleton>
  )
})

export const AccountItem = Object.assign(AccountItemBase, {
  Skeleton: AccountItemSkeleton,
})
