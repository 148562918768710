import React from "react"
import { range } from "lodash"
import { graphql, useFragment } from "react-relay"
import { Panel } from "@/components/layout/Panel"
import { Orders, OrdersType } from "@/components/orders/Orders.react"
import { Skeleton } from "@opensea/ui-kit"
import { Table } from "@/design-system/Table"
import { useTranslate } from "@/hooks/useTranslate"
import { OffersPanel_asset$key } from "@/lib/graphql/__generated__/OffersPanel_asset.graphql"
import { AcceptOfferDisabledAlert } from "./AcceptOfferDisabledAlert.react"

type OffersPanelProps = {
  asset: OffersPanel_asset$key
  hasOffers: boolean
}

export const OffersPanel = ({
  asset: assetDataKey,
  hasOffers,
}: OffersPanelProps) => {
  const t = useTranslate("assets")

  const asset = useFragment(
    graphql`
      fragment OffersPanel_asset on AssetType {
        relayId
        tokenId
        isCurrentlyFungible
        ownedQuantity(identity: {})
        chain {
          identifier
        }
        assetContract {
          address
        }
        ...AcceptOfferDisabledAlert_asset
      }
    `,
    assetDataKey,
  )

  const ownedQuantity = asset.ownedQuantity ? Number(asset.ownedQuantity) : 0

  return (
    <Panel
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="item--frame item--orders"
      icon="toc"
      id="offers-panel"
      isContentPadded={false}
      mode="start-open"
      subtitle={hasOffers && <AcceptOfferDisabledAlert asset={asset} />}
      title={t("offers.title", "Offers")}
    >
      <Orders
        mode={asset.isCurrentlyFungible ? OrdersType.full : OrdersType.minimal}
        ownedQuantity={ownedQuantity}
        renderSkeletonRows={OffersPanelSkeletonRows}
        side="bid"
        variables={{
          isExpired: false,
          isValid: true,
          takerArchetype: {
            assetContractAddress: asset.assetContract.address,
            tokenId: asset.tokenId,
            chain: asset.chain.identifier,
          },
          makerAssetIsPayment: true,
          sortBy: "PRICE",
          isBid: true,
          filterByOrderRules: true,
          includeCriteriaOrders: true,
          criteriaTakerAssetId: asset.relayId,
          includeCriteriaTakerAsset: true,
          isSingleAsset: true,
        }}
      />
    </Panel>
  )
}

const OffersPanelSkeletonRows = () => {
  return range(6).map(index => (
    <Table.Row key={index}>
      <Table.Cell height="72px">
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Line />
      </Table.Cell>
    </Table.Row>
  ))
}
