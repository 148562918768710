/**
 * @generated SignedSource<<ed66c6ef5c7d8bcaedeec007b1117ec0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NumericTrait_trait$data = {
  readonly floatValue: number | null;
  readonly intValue: string | null;
  readonly maxValue: number | null;
  readonly traitType: string;
  readonly " $fragmentType": "NumericTrait_trait";
};
export type NumericTrait_trait$key = {
  readonly " $data"?: NumericTrait_trait$data;
  readonly " $fragmentSpreads": FragmentRefs<"NumericTrait_trait">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NumericTrait_trait",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floatValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "intValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traitType",
      "storageKey": null
    }
  ],
  "type": "TraitType",
  "abstractKey": null
};

(node as any).hash = "8183b5d51780ad49aca36c96bb1cf8c7";

export default node;
