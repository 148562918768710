/**
 * @generated SignedSource<<143ec684be11595928117eef9bf03515>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetPageMediaHeader__accountInfo$data = {
  readonly animationUrl: string | null;
  readonly collection: {
    readonly isVerified: boolean;
  };
  readonly isFavorite: boolean;
  readonly ownedQuantity?: string | null;
  readonly " $fragmentType": "AssetPageMediaHeader__accountInfo";
};
export type AssetPageMediaHeader__accountInfo$key = {
  readonly " $data"?: AssetPageMediaHeader__accountInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetPageMediaHeader__accountInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showQuantity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetPageMediaHeader__accountInfo",
  "selections": [
    {
      "condition": "showQuantity",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "identity",
              "variableName": "identity"
            }
          ],
          "kind": "ScalarField",
          "name": "ownedQuantity",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "animationUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVerified",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "3fbc71a9c9ac234be43ddd76a1f927b4";

export default node;
