/**
 * @generated SignedSource<<e82889e17de78a2c680218c8f1c3c3c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AssetTokenStandard = "AUCTION_HOUSE" | "BITKEEP" | "BLUR" | "CONSIDERATION" | "CRYPTOKITTIES" | "CRYPTOPUNKS" | "CUSTOM" | "DECENTRALAND" | "ELEMENT" | "ENJIN_LEGACY" | "ERC1155" | "ERC20" | "ERC721" | "ETH_BRIDGE" | "JOEPEGS" | "KNOWNORIGIN" | "LOOKSRARE" | "MAKERSPLACE" | "METAPLEX" | "SEADROP" | "SOLANA" | "SPL" | "SUPERRARE" | "TOFU" | "TRANSFER_HELPER" | "TROVE" | "UNKNOWN" | "WYVERN" | "X2Y2" | "ZERO_EX" | "%future added value";
export type CollectionSafelistRequestStatus = "APPROVED" | "DISABLED_TOP_TRENDING" | "NOT_REQUESTED" | "REQUESTED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useGetDealAssetDisabledReason_asset$data = {
  readonly chain: {
    readonly isTradingEnabled: boolean;
  };
  readonly collection: {
    readonly safelistRequestStatus: CollectionSafelistRequestStatus;
  };
  readonly isCompromised: boolean;
  readonly isCurrentlyFungible: boolean;
  readonly isErc721cFeeEnforced: boolean;
  readonly isListable: boolean;
  readonly tokenStandard: AssetTokenStandard | null;
  readonly " $fragmentType": "useGetDealAssetDisabledReason_asset";
};
export type useGetDealAssetDisabledReason_asset$key = {
  readonly " $data"?: useGetDealAssetDisabledReason_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGetDealAssetDisabledReason_asset">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useGetDealAssetDisabledReason_asset"
};

(node as any).hash = "e1ee62d43dd71dee218a0d05002ffca4";

export default node;
