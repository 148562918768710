/**
 * @generated SignedSource<<2d11b7d528972285a2934c37f4fc08f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TradeStationBuyTab_bestListings$data = {
  readonly nft: {
    readonly bestListings: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"useFulfillSemiFungibleOrders_orders">;
        } | null;
      } | null>;
    };
  };
  readonly " $fragmentType": "TradeStationBuyTab_bestListings";
};
export type TradeStationBuyTab_bestListings$key = {
  readonly " $data"?: TradeStationBuyTab_bestListings$data;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStationBuyTab_bestListings">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "nft",
  "bestListings"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractAddress"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tokenId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./TradeStationBuyTabBestListingsPaginationQuery.graphql')
    }
  },
  "name": "TradeStationBuyTab_bestListings",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "chain"
        },
        {
          "kind": "Variable",
          "name": "contractAddress",
          "variableName": "contractAddress"
        },
        {
          "kind": "Variable",
          "name": "tokenId",
          "variableName": "tokenId"
        }
      ],
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "alias": "bestListings",
          "args": [
            {
              "kind": "Literal",
              "name": "forTaker",
              "value": {}
            }
          ],
          "concreteType": "OrderV2TypeConnection",
          "kind": "LinkedField",
          "name": "__TradeStationBuyTab_bestListings_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderV2Type",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "useFulfillSemiFungibleOrders_orders"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "__TradeStationBuyTab_bestListings_connection(forTaker:{})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1d3c66ca4002bf7e9d33a1ee22f1e133";

export default node;
