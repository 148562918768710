/**
 * @generated SignedSource<<791fb59d711bf7189c98050190d4791a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BucketSize = "DAY" | "HOUR" | "MINUTE" | "MONTH" | "WEEK" | "%future added value";
export type ArchetypeInputType = {
  assetContractAddress: string;
  chain?: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value" | null;
  tokenId: string;
};
export type ItemPriceHistoryChartQuery$variables = {
  archetype: ArchetypeInputType;
  bucketSize?: BucketSize | null;
  startDate?: string | null;
};
export type ItemPriceHistoryChartQuery$data = {
  readonly tradeHistory: {
    readonly " $fragmentSpreads": FragmentRefs<"PriceHistoryChart_data">;
  };
};
export type ItemPriceHistoryChartQuery = {
  response: ItemPriceHistoryChartQuery$data;
  variables: ItemPriceHistoryChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archetype"
},
v1 = {
  "defaultValue": "DAY",
  "kind": "LocalArgument",
  "name": "bucketSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = [
  {
    "kind": "Variable",
    "name": "archetype",
    "variableName": "archetype"
  },
  {
    "kind": "Variable",
    "name": "bucketSize",
    "variableName": "bucketSize"
  },
  {
    "kind": "Variable",
    "name": "minTime",
    "variableName": "startDate"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemPriceHistoryChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "TradeHistoryType",
        "kind": "LinkedField",
        "name": "tradeHistory",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PriceHistoryChart_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ItemPriceHistoryChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "TradeHistoryType",
        "kind": "LinkedField",
        "name": "tradeHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeHistoryDataPoint",
            "kind": "LinkedField",
            "name": "results",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bucketStart",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetQuantityType",
                "kind": "LinkedField",
                "name": "volume",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetType",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "decimals",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e66bbde41c8b1f90a89a4ce2aff3c94f",
    "id": null,
    "metadata": {},
    "name": "ItemPriceHistoryChartQuery",
    "operationKind": "query",
    "text": "query ItemPriceHistoryChartQuery(\n  $archetype: ArchetypeInputType!\n  $startDate: DateTime\n  $bucketSize: BucketSize = DAY\n) {\n  tradeHistory(minTime: $startDate, archetype: $archetype, bucketSize: $bucketSize) {\n    ...PriceHistoryChart_data\n  }\n}\n\nfragment PriceHistoryChart_data on TradeHistoryType {\n  results {\n    bucketStart\n    quantity\n    volume {\n      asset {\n        decimals\n        id\n      }\n      quantity\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ecafa9d8c7d12db0cdb2e5656563fe6";

export default node;
