import React, { ComponentProps } from "react"
import { Text, classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { Markdown } from "@/design-system/Markdown"

type Components = NonNullable<ComponentProps<typeof Markdown>["components"]>

export const getMarkdownComponents = () => {
  const components: Components = {
    span: ({ className, children }) => (
      <StyledText asChild>
        <p className={classNames(className, "whitespace-pre-wrap")}>
          {children}
        </p>
      </StyledText>
    ),
    p: ({ className, children }) => (
      <StyledText asChild>
        <p className={classNames(className, "whitespace-pre-wrap")}>
          {children}
        </p>
      </StyledText>
    ),
    li: ({ children }) => <StyledListItem>{children}</StyledListItem>,
    ul: ({ children }) => <StyledList>{children}</StyledList>,
  }
  ;(
    ["h1", "h2", "h3", "h4", "h5", "h6"] as (
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
    )[]
  ).forEach(tag => {
    components[tag] = ({ children }) => (
      <Text.Heading asChild className="relative pb-4" size="medium">
        <h2>{children}</h2>
      </Text.Heading>
    )
  })
  return components
}

const StyledText = styled(Text.Body).attrs({
  className: "text-inherit",
  size: "medium",
})`
  & + & {
    margin-top: 16px;
  }
`

const StyledList = styled.ul`
  list-style-position: outside;
  padding-left: 16px;
  list-style-type: disc;
  display: inline-block;
`

const StyledListItem = styled(Text.Body).attrs({
  as: "li",
  size: "medium",
})`
  list-style-type: disc;
  display: list-item;
`
