import { SemiFungibleBulkSaleParams } from "@/components/trade/SemiFungibleTradeStation/types"
import { getTrackingFn } from "@/lib/analytics"
import { getItemTrackingFnWithExtraParamsV2 } from "@/lib/analytics/events/itemEvents"

export const trackClickAcceptOffersAdd = getTrackingFn(
  "click accept offers add",
)
export const trackClickAcceptOffersRemove = getTrackingFn(
  "click accept offers remove",
)
export const trackClickAcceptOffers =
  getItemTrackingFnWithExtraParamsV2<SemiFungibleBulkSaleParams>(
    "click accept offers",
  )
export const trackClickAcceptOffersSubstituteItems =
  getItemTrackingFnWithExtraParamsV2<SemiFungibleBulkSaleParams>(
    "click accept offers substitute items",
  )
