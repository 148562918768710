import React, { useState } from "react"
import { noop } from "lodash"
import {
  LoadMoreFn,
  RefetchFnDynamic,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay"
import { OperationType } from "relay-runtime"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { EventHistoryBase } from "@/components/events/EventHistory.react"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { usePollingQuery } from "@/hooks/usePollingQuery"
import {
  AssetActivityPanelPagination_data$data,
  AssetActivityPanelPagination_data$key,
} from "@/lib/graphql/__generated__/AssetActivityPanelPagination_data.graphql"
import { AssetActivityPanelPollingQuery } from "@/lib/graphql/__generated__/AssetActivityPanelPollingQuery.graphql"
import {
  AssetActivityPanelQuery,
  EventType,
} from "@/lib/graphql/__generated__/AssetActivityPanelQuery.graphql"
import { getNodes, graphql, Node } from "@/lib/graphql/graphql"

const ASSET_EVENT_HISTORY_POLLING_INTERVAL = 10_000 // 10 seconds

type EventNode = Node<AssetActivityPanelPagination_data$data["nft"]["activity"]>

type Props = {
  tokenId: string
  contractAddress: string
  chain: ChainIdentifier
  mode: "fungible" | "nonfungible"
  defaultEventTypeFilters: EventType[]
}

type PaginationProps = Omit<Props, "defaultEventTypeFilters"> & {
  data: AssetActivityPanelPagination_data$key | null
  eventTypeFilters: EventType[]
  setEventTypeFilters: React.Dispatch<React.SetStateAction<EventType[]>>
}

const LIST_QUERY = graphql`
  query AssetActivityPanelQuery(
    $count: Int!
    $cursor: String
    $tokenId: String!
    $contractAddress: AddressScalar!
    $chain: ChainScalar!
    $eventTypes: [EventType!]!
  ) {
    ...AssetActivityPanelPagination_data
      @arguments(
        count: $count
        cursor: $cursor
        tokenId: $tokenId
        contractAddress: $contractAddress
        chain: $chain
        eventTypes: $eventTypes
      )
  }
`

const PAGINATION_FRAGMENT = graphql`
  fragment AssetActivityPanelPagination_data on Query
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    tokenId: { type: "String!" }
    contractAddress: { type: "AddressScalar!" }
    chain: { type: "ChainScalar!" }
    eventTypes: { type: "[EventType!]" }
  )
  @refetchable(queryName: "AssetActivityPanelPaginationQuery") {
    nft(tokenId: $tokenId, contractAddress: $contractAddress, chain: $chain) {
      activity(after: $cursor, first: $count, eventTypes: $eventTypes)
        @connection(key: "AssetActivityPanel_activity") {
        edges {
          node {
            ...EventHistory_data @arguments(showAll: true)
            eventTimestamp
          }
        }
      }
    }
  }
`

const POLLING_QUERY = graphql`
  query AssetActivityPanelPollingQuery(
    $count: Int!
    $cursor: String
    $tokenId: String!
    $contractAddress: AddressScalar!
    $chain: ChainScalar!
    $eventTimestamp_Gt: DateTime
    $eventTypes: [EventType!]!
  ) {
    nft(tokenId: $tokenId, contractAddress: $contractAddress, chain: $chain) {
      activity(
        after: $cursor
        first: $count
        eventTypes: $eventTypes
        eventTimestamp_Gt: $eventTimestamp_Gt
      ) {
        edges {
          node {
            ...EventHistory_data @arguments(showAll: true)
            eventTimestamp
          }
        }
      }
    }
  }
`

const LazyAssetActivityPanel = (props: Omit<PaginationProps, "data">) => {
  const data = useLazyLoadQuery<AssetActivityPanelQuery>(LIST_QUERY, {
    tokenId: props.tokenId,
    contractAddress: props.contractAddress,
    chain: props.chain,
    count: GLOBAL_MAX_PAGE_SIZE,
    eventTypes: props.eventTypeFilters,
  })
  return <AssetActivityPanelPagination data={data} {...props} />
}

const AssetActivityPanelPagination = ({
  data: dataKey,
  tokenId,
  contractAddress,
  chain,
  mode,
  eventTypeFilters,
  setEventTypeFilters,
}: PaginationProps) => {
  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment<
      AssetActivityPanelQuery,
      AssetActivityPanelPagination_data$key
    >(PAGINATION_FRAGMENT, dataKey)

  const initialEvents = getNodes(data?.nft.activity)

  const [polledEvents, setPolledEvents] = useState<EventNode[]>([])

  // Events are sorted by eventTimestamp in descending order
  const latestEventTimestamp =
    polledEvents[0]?.eventTimestamp ?? initialEvents[0]?.eventTimestamp

  usePollingQuery<AssetActivityPanelPollingQuery>(
    POLLING_QUERY,
    {
      tokenId,
      contractAddress,
      chain,
      count: GLOBAL_MAX_PAGE_SIZE,
      eventTimestamp_Gt: latestEventTimestamp,
      eventTypes: eventTypeFilters,
    },
    {
      delay: ASSET_EVENT_HISTORY_POLLING_INTERVAL,
      skipOnHidden: true,
      onPoll: newData => {
        const newNodes = getNodes(newData.nft.activity)
        setPolledEvents(prev => [...newNodes, ...prev])
      },
    },
  )

  return (
    <EventHistoryBase
      data={[...polledEvents, ...initialEvents]}
      excludedFilters={["COLLECTION_OFFER"]}
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      mode={mode}
      panelProps={{
        eventTypeFilters,
        setEventTypeFilters,
        refetch,
      }}
      showFilters
    />
  )
}

const AssetActivityPanelSkeleton = ({
  mode,
  eventTypeFilters,
}: Pick<PaginationProps, "mode" | "eventTypeFilters">) => {
  return (
    <EventHistoryBase
      data={[]}
      excludedFilters={["COLLECTION_OFFER"]}
      hasNext={false}
      isLoading
      isLoadingNext={false}
      loadNext={noop as LoadMoreFn<OperationType>}
      mode={mode}
      panelProps={{
        eventTypeFilters,
        setEventTypeFilters: noop,
        refetch: noop as RefetchFnDynamic<OperationType, null>,
      }}
      showFilters
    />
  )
}

export const AssetActivityPanel = ({
  defaultEventTypeFilters,
  ...restProps
}: Props) => {
  const [eventTypeFilters, setEventTypeFilters] = useState<EventType[]>(
    defaultEventTypeFilters,
  )

  return (
    <SsrSuspense
      fallback={
        <AssetActivityPanelSkeleton
          eventTypeFilters={eventTypeFilters}
          mode={restProps.mode}
        />
      }
    >
      <LazyAssetActivityPanel
        {...restProps}
        eventTypeFilters={eventTypeFilters}
        setEventTypeFilters={setEventTypeFilters}
      />
    </SsrSuspense>
  )
}
