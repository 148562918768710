import React, { memo } from "react"
import {
  Flex,
  Icon,
  SpaceBetween,
  SpaceBetweenProps,
  Text,
  classNames,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { AnnotationImage } from "@/components/common/AnnotationImage.react"
import { ChainLogoWithTooltip } from "@/components/common/ChainLogo"
import { Link } from "@/components/common/Link"
import { FavoriteItem } from "@/components/favorites/FavoriteItem.react"
import { useAssetFavorite } from "@/components/favorites/useAssetFavorite"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useTheme } from "@/design-system/Context"
import { Skeleton } from "@opensea/ui-kit"
import { Tooltip } from "@/design-system/Tooltip"
import { useFlag } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetPageMediaHeader__accountInfo$key } from "@/lib/graphql/__generated__/AssetPageMediaHeader__accountInfo.graphql"
import { AssetPageMediaHeader_item$key } from "@/lib/graphql/__generated__/AssetPageMediaHeader_item.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn, quantityDisplay } from "@/lib/helpers/numberUtils"
import { getOriginalMedia } from "@/lib/helpers/urls"
import { HUES } from "@/styles/themes"

type AssetPageMediaHeaderProps = {
  iconSize?: number
  item: AssetPageMediaHeader_item$key | null
  accountInfo: AssetPageMediaHeader__accountInfo$key | null
}

const AssetPageMediaHeaderBase = ({
  item: itemKey,
  accountInfo: accountInfoKey,
  iconSize = 20,
}: AssetPageMediaHeaderProps) => {
  const t = useTranslate("components")
  const assetFavoritingDisabled = useFlag("disable_asset_favoriting")
  const theme = useTheme()
  const { handleUnconnectedWallet } = useWalletModal()

  const item = useFragment(
    graphql`
      fragment AssetPageMediaHeader_item on ItemType {
        relayId
        __typename
        ... on AssetType {
          chain {
            identifier
          }
          decimals
          favoritesCount
          isDelisted
          isFrozen
          hasUnlockableContent
        }
        ... on AssetBundleType {
          chain {
            identifier
          }
          assetCount
        }
      }
    `,
    itemKey,
  )

  const accountInfo = useFragment(
    graphql`
      fragment AssetPageMediaHeader__accountInfo on AssetType
      @argumentDefinitions(
        identity: { type: "IdentityInputType", defaultValue: {} }
        showQuantity: { type: "Boolean", defaultValue: false }
      ) {
        ownedQuantity(identity: $identity) @include(if: $showQuantity)
        isFavorite
        animationUrl
        collection {
          isVerified
        }
      }
    `,
    accountInfoKey,
  )

  let originalMedia: string | null = null

  if (accountInfo) {
    const { animationUrl, collection } = accountInfo

    if (animationUrl && collection.isVerified) {
      originalMedia = getOriginalMedia(animationUrl)
    }
  }

  const { toggleIsFavorite, isFavorite, favoritesCount } = useAssetFavorite({
    assetId: item?.relayId ?? "",
    favoriteCountInitial: item?.favoritesCount ?? 0,
    isFavoriteInitial: accountInfo?.isFavorite ?? false,
  })

  const chain = item?.chain?.identifier

  if (item?.isDelisted) {
    return <StyledContainer />
  }

  const quantity = accountInfo?.ownedQuantity
  const quantityDisplayed =
    quantity && item ? bn(quantity, item.decimals || 0) : undefined

  const handleFavoriteClick = async (
    event: React.MouseEvent<HTMLElement>,
  ): Promise<void> => {
    event.preventDefault()

    handleUnconnectedWallet(() => toggleIsFavorite(event))
  }

  const annotationIcons = (
    <Flex className="items-center">
      {chain && (
        <Flex className="mr-1">
          <ChainLogoWithTooltip
            chain={chain}
            color={theme.theme === "light" ? "black" : "white"}
          />
        </Flex>
      )}
      {item?.hasUnlockableContent && (
        <Tooltip
          content={t(
            "assets.card.unlockableContent.tooltip",
            "Includes unlockable content",
          )}
        >
          <AnnotationImage
            hoverColor={HUES.seaBlue}
            icon={quantityDisplayed ? "lock_open" : "lock"}
            marginRight="4px"
          />
        </Tooltip>
      )}
      {item?.isFrozen && (
        <Tooltip
          content={t("assets.card.frozenMetadata.tooltip", "Metadata: Frozen")}
        >
          <AnnotationImage
            hoverColor={HUES.aqua}
            icon="ac_unit"
            marginRight="4px"
          />
        </Tooltip>
      )}
      {item?.assetCount && (
        <Tooltip
          content={t(
            "assets.card.bundle.tooltip",
            {
              0: "Bundle: {{count}} items",
              one: "Bundle: {{count}} item",
              other: "Bundle: {{count}} items",
            },
            { count: item.assetCount },
            { forceString: true },
          )}
        >
          <AnnotationImage
            icon="filter"
            iconSize={17}
            marginRight="4px"
            variant="rounded"
          />
        </Tooltip>
      )}
      {quantityDisplayed && bn(quantityDisplayed).isGreaterThan(bn(1)) && (
        <Tooltip
          content={t(
            "assets.card.copiesOwned.tooltip",
            "{{quantity}} copies owned",
            { quantity: quantityDisplay(quantityDisplayed) },
          )}
        >
          <Text
            className="ml-px mr-1"
            color="interactive-secondary-styles"
            size="small"
          >
            {t("assets.card.copiesOwned.quantityDisplay", "x{{quantity}}", {
              quantity: quantityDisplay(quantityDisplayed),
            })}
          </Text>
        </Tooltip>
      )}
    </Flex>
  )

  return (
    <StyledContainer asChild>
      <header>
        <Flex className="items-center">{annotationIcons}</Flex>
        {originalMedia && (
          <Tooltip content={t("toolbar.expand", "View original media")}>
            <Flex className="ml-auto mr-2 items-center">
              <Link className="flex" href={originalMedia}>
                <StyledIcon size={20} value="open_in_new" />
              </Link>
            </Flex>
          </Tooltip>
        )}

        {item && (
          <FavoriteItem
            disabled={assetFavoritingDisabled}
            favoritesCount={favoritesCount}
            iconSize={iconSize}
            isFavorite={isFavorite}
            toggleIsFavorite={handleFavoriteClick}
          />
        )}
      </header>
    </StyledContainer>
  )
}

const AssetPageMediaHeaderSkeleton = memo(
  function AssetPageMediaHeaderSkeleton() {
    return (
      <StyledSkeletonRow>
        <Skeleton.Line className="h-4 w-[135px]" />
      </StyledSkeletonRow>
    )
  },
)

const StyledSkeletonRow = styled(Skeleton.Row)`
  && {
    margin-top: 4px;
  }
`

const StyledContainer = ({ className, ...props }: SpaceBetweenProps) => (
  <SpaceBetween
    className={classNames(
      "h-10 w-full items-center bg-elevation-1 p-3 font-medium",
      className,
    )}
    {...props}
  />
)

const StyledIcon = styled(Icon)`
  margin-right: 0px;
  font-weight: 600;
  color: inherit;
`

export const AssetPageMediaHeader = Object.assign(AssetPageMediaHeaderBase, {
  Skeleton: AssetPageMediaHeaderSkeleton,
  Container: StyledContainer,
})
