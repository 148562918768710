import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { AssetProps } from "@/features/live-updates/components/AssetPageSubscriptions/AssetPageSubscriptions.react"
import { updateAssetStoreWithListingUpdatedEventData } from "@/features/live-updates/utils"
import { useAssetPageListingUpdatedEventSubscription } from "@/lib/graphql/__generated__/useAssetPageListingUpdatedEventSubscription.graphql"

export const useAssetPageListingUpdatedEvent = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  const config: GraphQLSubscriptionConfig<useAssetPageListingUpdatedEventSubscription> =
    useMemo(
      () => ({
        variables: { slug, relayId },
        subscription: graphql`
          subscription useAssetPageListingUpdatedEventSubscription(
            $slug: String!
            $relayId: String!
          ) {
            listingUpdatedEvent(slug: $slug, itemRelayIds: [$relayId]) {
              __typename
              itemRelayId
              orderRelayId
              order {
                remainingQuantityType
              }
              newBestOrderRelayId
              newBestOrder {
                maker {
                  ...wallet_accountKey
                }
                ...Orders_orders @arguments(isSingleAsset: true)
                ...useFulfillSemiFungibleOrders_orders
                ...BuyNowButton_orders
                ...TradeStationOrderPrice_order
                ...TradeStation_bestAsk
              }
            }
          }
        `,
        updater: (store, data) => {
          updateAssetStoreWithListingUpdatedEventData(
            store,
            data,
            contractAddress,
            chain,
            tokenId,
          )
        },
      }),
      [slug, relayId, contractAddress, chain, tokenId],
    )
  useSubscription<useAssetPageListingUpdatedEventSubscription>(config)
}
