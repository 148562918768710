import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { AssetProps } from "@/features/live-updates/components/AssetPageSubscriptions/AssetPageSubscriptions.react"
import { updateAssetStoreWithOfferUpdatedEventData } from "@/features/live-updates/utils"
import { useAssetPageOfferUpdatedEventSubscription } from "@/lib/graphql/__generated__/useAssetPageOfferUpdatedEventSubscription.graphql"

export const useAssetPageOfferUpdatedEvent = ({
  slug,
  relayId,
  contractAddress,
  tokenId,
  chain,
}: AssetProps) => {
  const config: GraphQLSubscriptionConfig<useAssetPageOfferUpdatedEventSubscription> =
    useMemo(
      () => ({
        variables: { slug, relayId },
        subscription: graphql`
          subscription useAssetPageOfferUpdatedEventSubscription(
            $slug: String!
            $relayId: String!
          ) {
            offerUpdatedEvent(slug: $slug, itemRelayIds: [$relayId]) {
              itemRelayId
              orderRelayId
              order {
                remainingQuantityType
              }
              newBestOrderRelayId
              newBestOrder {
                relayId
                payment {
                  relayId
                }
                priceType {
                  unit
                }
                item {
                  ...itemEvents_dataV2
                }
                perUnitPriceType {
                  unit
                  symbol
                  usd
                }
                maker {
                  ...wallet_accountKey
                }
                ...AcceptOfferButton_order @arguments(skipOwnedQuantity: true)
                ...Orders_orders
                  @arguments(
                    isBid: true
                    includeCriteriaTakerAsset: true
                    isSingleAsset: true
                  )
                ...useFulfillSemiFungibleOrders_orders
                ...TradeStationOrderPrice_order
                ...BuyNowButton_orders
                ...OrderPrice
                ...OrderUsdPrice
                ...TradeStation_bestBid
                relayId
                closedAt
                payment {
                  relayId
                }
              }
            }
          }
        `,
        updater: (store, data) => {
          updateAssetStoreWithOfferUpdatedEventData(
            store,
            data,
            contractAddress,
            chain,
            tokenId,
          )
        },
      }),
      [slug, relayId, contractAddress, chain, tokenId],
    )
  useSubscription<useAssetPageOfferUpdatedEventSubscription>(config)
}
