import React from "react"

export const RedeemablesIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 28 24"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_785_9625)">
      <path
        d="M20.2776 2.25H16.5407V5.70469C16.5407 5.86875 16.41 6 16.2465 6C16.1951 6 16.1437 5.98594 16.1017 5.9625L13.9249 4.71562C13.6961 4.58437 13.4111 4.58437 13.1823 4.71562L11.0055 5.9625C10.9588 5.98594 10.9121 6 10.8607 6C10.6973 6 10.5665 5.86875 10.5665 5.70469V2.25H6.82495V9.75H20.2776V2.25ZM20.2776 0C21.5154 0 22.5197 1.00781 22.5197 2.25V9.75C22.5197 10.9922 21.5154 12 20.2776 12H6.82495C5.58712 12 4.58284 10.9922 4.58284 9.75V2.25C4.58284 1.00781 5.58712 0 6.82495 0H10.5618H16.5407H20.2776ZM8.85219 16.5C8.48317 16.5 8.1235 16.6078 7.81521 16.8141L4.08304 19.3125C3.90087 19.4344 3.68133 19.5 3.46179 19.5H1.21969C0.598435 19.5 0.0986328 18.9984 0.0986328 18.375C0.0986328 17.7516 0.598435 17.25 1.21969 17.25H3.1208L6.56804 14.9437C7.24534 14.4891 8.03942 14.25 8.85219 14.25H16.1671C17.8159 14.25 19.1565 15.5953 19.1565 17.25C19.1565 17.2828 19.1565 17.3109 19.1565 17.3438L22.188 15.4688C22.6878 15.1594 23.2577 15 23.8463 15H24.1919C25.7474 15 27.0086 16.2656 27.0086 17.8266C27.0086 18.7828 26.5228 19.6781 25.724 20.1984L21.2585 23.1C20.3523 23.6906 19.292 24.0047 18.213 24.0047H1.21969C0.598435 24.0047 0.0986328 23.5031 0.0986328 22.8797C0.0986328 22.2563 0.598435 21.7547 1.21969 21.7547H18.2083C18.8576 21.7547 19.4928 21.5672 20.0347 21.2109L24.5002 18.3094C24.6637 18.2016 24.7618 18.0234 24.7618 17.8266C24.7618 17.5078 24.5049 17.25 24.1873 17.25H23.8416C23.6734 17.25 23.5053 17.2969 23.3605 17.3859L18.993 20.0812C18.8155 20.1891 18.6147 20.25 18.4045 20.25H16.1671H15.046H12.0565C11.4353 20.25 10.9355 19.7484 10.9355 19.125C10.9355 18.5016 11.4353 18 12.0565 18H15.046H16.1671C16.5781 18 16.9144 17.6625 16.9144 17.25C16.9144 16.8375 16.5781 16.5 16.1671 16.5H8.85219Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_785_9625">
        <rect
          fill="white"
          height="24"
          transform="translate(0.0986328)"
          width="26.9053"
        />
      </clipPath>
    </defs>
  </svg>
)
