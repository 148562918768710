import React from "react"
import { Media } from "@opensea/ui-kit"
import { differenceInMonths } from "date-fns"
import { graphql, useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { Item } from "@/design-system/Item"
import { useIsRedeemModuleEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { RedeemableItemCard_itemToBurn$key } from "@/lib/graphql/__generated__/RedeemableItemCard_itemToBurn.graphql"
import { RedeemableItemCard_redeemableCampaign$key } from "@/lib/graphql/__generated__/RedeemableItemCard_redeemableCampaign.graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import { getCollectionUrl } from "@/lib/helpers/collection"
import {
  dateFromISO8601,
  useFromNowDurationShort,
} from "@/lib/helpers/datetime"
import { borderRadius } from "@/styles/themes"
import { RedeemPageMedia } from "../RedeemPage/components/RedeemPageMedia.react"

type Props = {
  itemToBurn: RedeemableItemCard_itemToBurn$key
  redeemableCampaign: RedeemableItemCard_redeemableCampaign$key
}

export const RedeemableItemCard = ({
  itemToBurn: itemToBurnKey,
  redeemableCampaign: redeemableCampaignKey,
}: Props) => {
  const t = useTranslate("redeem")
  const itemToBurn = useFragment(
    graphql`
      fragment RedeemableItemCard_itemToBurn on AssetType {
        collection {
          name
        }
        ...asset_url
      }
    `,
    itemToBurnKey,
  )

  const redeemableCampaign = useFragment(
    graphql`
      fragment RedeemableItemCard_redeemableCampaign on RedeemableCampaignType {
        campaignId
        imageUrls
        name
        requiredAmountToRedeem
        receivedCollection {
          ...collection_url
        }
        endTime
      }
    `,
    redeemableCampaignKey,
  )

  const endsInMoreThanThreeMonths =
    differenceInMonths(
      new Date(),
      dateFromISO8601(redeemableCampaign.endTime),
    ) >= 3

  const endTime = useFromNowDurationShort(
    dateFromISO8601(redeemableCampaign.endTime),
  )

  const burnText = `${t("card.burn", "Burn")} ${
    redeemableCampaign.requiredAmountToRedeem
  } ${itemToBurn.collection.name}`

  const endsInText = t("campaign.endTime", "Ends {{endTime}}", { endTime })

  const isRedeemModuleEnabled = useIsRedeemModuleEnabled()

  return (
    <StyledItem
      className="border-0"
      href={
        isRedeemModuleEnabled && redeemableCampaign.receivedCollection
          ? `${getCollectionUrl(
              redeemableCampaign.receivedCollection,
            )}/overview`
          : `${getAssetUrl(itemToBurn)}/redeem/${redeemableCampaign.campaignId}`
      }
    >
      <Item.Avatar size={72}>
        {redeemableCampaign.imageUrls[0] && (
          <RedeemPageMedia
            height={72}
            mediaUrl={redeemableCampaign.imageUrls[0]}
            style={{
              borderRadius: borderRadius.xlarge,
              objectFit: "cover",
            }}
            width={72}
          />
        )}
      </Item.Avatar>

      <Media lessThan="sm">
        {() => (
          <Item.Content>
            {redeemableCampaign.name && (
              <Item.Title>{redeemableCampaign.name}</Item.Title>
            )}
            {endsInMoreThanThreeMonths && (
              <Item.Description>{endsInText}</Item.Description>
            )}
          </Item.Content>
        )}
      </Media>
      <Media greaterThanOrEqual="sm">
        {() => (
          <>
            <Item.Content>
              {redeemableCampaign.name && (
                <Item.Title>{redeemableCampaign.name}</Item.Title>
              )}
              {itemToBurn.collection.name && (
                <Item.Description>{burnText}</Item.Description>
              )}
            </Item.Content>
            <Item.Side>
              <Block
                backgroundColor="components.background.gray1"
                className="rounded-xl"
                padding="6px 12px"
              >
                {endsInText}
              </Block>
            </Item.Side>
          </>
        )}
      </Media>
    </StyledItem>
  )
}

const StyledItem = styled(Item)`
  border-radius: ${props => props.theme.borderRadius.xlarge};
  background: ${props => props.theme.colors.components.background.gray1};
  transition: transform 0.25s ease;

  ${props =>
    props.href &&
    css`
      && {
        :hover {
          transform: translate(0, -4px);
        }
      }
    `}
`
