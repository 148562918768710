/**
 * @generated SignedSource<<a084794bed9738820196249092a332a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcceptOffersButton_asset$data = {
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorFeeInputModalContent_asset" | "readOptionalCreatorFees_item">;
  readonly " $fragmentType": "AcceptOffersButton_asset";
};
export type AcceptOffersButton_asset$key = {
  readonly " $data"?: AcceptOffersButton_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOffersButton_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptOffersButton_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOptionalCreatorFees_item",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionType",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCreatorFeesEnforced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCreatorFeeBasisPoints",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "type": "ItemType",
          "abstractKey": "__isItemType"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorFeeInputModalContent_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "4dc186c233aaa8928e282e81be16d815";

export default node;
