/**
 * @generated SignedSource<<156cc6cb23628637f28b07772b30fcd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAssetFavorite__data$data = {
  readonly favoritesCount: number;
  readonly isFavorite: boolean;
  readonly " $fragmentType": "useAssetFavorite__data";
};
export type useAssetFavorite__data$key = {
  readonly " $data"?: useAssetFavorite__data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAssetFavorite__data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAssetFavorite__data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoritesCount",
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "82a101f6ee94e9283f37c5efc4403595";

export default node;
