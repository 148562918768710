import { useMemo } from "react"
import { _FragmentRefs, graphql } from "relay-runtime"
import { useTranslate } from "@/hooks/useTranslate"
import { useGetDealAssetDisabledReason_asset$data } from "@/lib/graphql/__generated__/useGetDealAssetDisabledReason_asset.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

type GetDealAssetDisabledReason = (
  asset:
    | useGetDealAssetDisabledReason_asset$data
    | _FragmentRefs<"useGetDealAssetDisabledReason_asset">,
) => string | null

export const readDisabledReasonDealAsset =
  inlineFragmentize<useGetDealAssetDisabledReason_asset$data>(
    graphql`
      fragment useGetDealAssetDisabledReason_asset on AssetType @inline {
        isCompromised
        isCurrentlyFungible
        isListable
        tokenStandard
        isErc721cFeeEnforced
        chain {
          isTradingEnabled
        }
        collection {
          safelistRequestStatus
        }
      }
    `,
    identifiers => identifiers,
  )

const ALLOWED_SAFELIST_REQUEST_STATUSES = ["VERIFIED", "APPROVED"]

export const useGetDealAssetDisabledReason = (): GetDealAssetDisabledReason => {
  const t = useTranslate("deals")
  return useMemo(
    () =>
      (
        asset:
          | useGetDealAssetDisabledReason_asset$data
          | _FragmentRefs<"useGetDealAssetDisabledReason_asset">,
      ) => {
        const {
          isCompromised,
          isCurrentlyFungible,
          isListable,
          tokenStandard,
          isErc721cFeeEnforced,
          chain,
          collection,
        } = readDisabledReasonDealAsset(asset)

        if (isCompromised) {
          return t(
            "dealAsset.disabledReason.compromised",
            "This item can't be included in a deal due to suspicious activity",
          )
        }
        if (isCurrentlyFungible) {
          return t(
            "dealAsset.disabledReason.semiFungible",
            "Multi-quantity items are not currently supported",
          )
        }
        if (tokenStandard === "CRYPTOPUNKS") {
          return t(
            "dealAsset.disabledReason.cryptopunks",
            "Only Wrapped CryptoPunks are supported",
          )
        }
        if (!isListable) {
          return t(
            "dealAsset.disabledReason.notListable",
            "This item can't be included in a deal",
          )
        }
        if (
          !ALLOWED_SAFELIST_REQUEST_STATUSES.includes(
            collection.safelistRequestStatus,
          )
        ) {
          return t(
            "dealAsset.disabledReason.notSafelistedCollection",
            "This item can't be selected",
          )
        }
        if (!chain.isTradingEnabled) {
          return t(
            "dealAsset.disabledReason.isTradingDisabled",
            "Deals is not available for this chain",
          )
        }
        if (isErc721cFeeEnforced) {
          return t(
            "dealAsset.disabledReason.erc721cFeeEnforced",
            "This item can't be included in a deal due to creator earnings enforcement.",
          )
        }
        return null
      },
    [t],
  )
}
