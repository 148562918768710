import React from "react"
import { isNumber } from "lodash"
import { Image } from "@/design-system/Image"
import { isVideoUrl } from "@/lib/helpers/urls"

type Props = {
  mediaUrl: string
  className?: string
  alt?: string
  style?: React.CSSProperties
  width?: number
  height?: number
}

export const RedeemPageMedia = ({
  alt = "",
  className,
  mediaUrl,
  style,
  height,
  width,
}: Props) => {
  return isVideoUrl(mediaUrl) ? (
    <video
      autoPlay
      className={className}
      controls={false}
      height={height}
      loop
      muted
      playsInline
      src={mediaUrl}
      style={{ height: "100%", width: "100%", ...style }}
      width={width}
    />
  ) : (
    <Image.Next13
      alt={alt}
      className={className}
      height={height ?? (isNumber(style?.height) ? style?.height : undefined)}
      src={mediaUrl}
      style={{
        objectFit: "cover",
        ...style,
      }}
      width={width ?? (isNumber(style?.width) ? style?.width : undefined)}
      {...(height === undefined && style?.height === undefined
        ? { fill: true }
        : {})}
    />
  )
}
